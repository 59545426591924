import Login from '../com/pages/Login';
import Dashboard from '../com/pages/Dashboard';
import Reports from '../com/pages/Reports';
import ClientReport from '../com/pages/ClientReport';
import RegionalReport from '../com/pages/RegionalReport';
import CaseReport from '../com/pages/CaseReport';
import Top10Report from '../com/pages/Top10Report';
import OwedAssociatesReport from '../com/pages/OwedAssociatesReport';
import AssociateAssignment from '../com/pages/AssociateAssignment';
import Register from '../com/pages/Register';
import RegisterThankyou from '../com/pages/RegisterThankyou';
import ForgotPassword from '../com/pages/ForgotPassword';
import ForgotPasswordMessage from '../com/pages/ForgotPasswordMessage';
import ChangePassword from '../com/pages/ChangePassword';
import ChangePasswordMessage from '../com/pages/ChangePasswordMessage';
import ConfirmAccount from '../com/pages/ConfirmAccount';
import UserManagementList from '../com/pages/UserManagementList';
import UserAdd from 'com/pages/UserAdd';
import UserDetails, {
	UserDetailsMain,
	UserDetailsExpertiseRegions,
	UserDetailsRates,
	UserDetailsPoints,
	UserDetailsPreferredAssociates,
	UserDetailsBlacklistedAssociates,
	UserDetailsCommunicationPreferences,
} from '../com/pages/UserDetails';
import FirmAdd from 'com/pages/FirmAdd';
import FirmDetails, {
	FirmDetailsMain,
	FirmDetailsPoints,
	FirmDetailsMembers,
	FirmDetailsExpertiseRegions,
	FirmDetailsRates,
	FirmDetailsAccounts,
	FirmDetailsEstimateRules,
	FirmDetailsReciprocityRules,
} from 'com/pages/FirmDetails';
import FirmManagementList from '../com/pages/FirmManagementList';
import AgentsList from '../com/pages/AgentsList';
import Tools from '../com/pages/Tools';
import AssignmentOverrideRequests, { AssignmentOverrideRequestsMain, AssignmentOverrideRequestsForm } from '../com/pages/AssignmentOverrideRequests';
import TranslationRequests, { TranslationRequestsMain, TranslationRequestsForm } from '../com/pages/TranslationRequests';
import SystemSettings from '../com/pages/SystemSettings';
import SystemSettingsReciprocity, { SystemSettingsReciprocityRules, SystemSettingsReciprocityRatios, SystemSettingsReciprocityGroups } from '../com/pages/SystemSettingsReciprocity';
import SystemSettingsTechnologies from '../com/pages/SystemSettingsTechnologies';
import SystemSettingsPoas, { SystemSettingsPoasMain, SystemSettingsPoasRegionalSetting } from '../com/pages/SystemSettingsPoas';
import SystemSettingsEstimatorRules, { SystemSettingsEstimatorRulesMain, SystemSettingsEstimatorRulesDetails } from '../com/pages/SystemSettingsEstimatorRules';
import SystemSettingsFirmFamilies from '../com/pages/SystemSettingsFirmFamilies';
import SystemSettingsIntegrations from 'com/pages/SystemSettingsIntegrations';
import PointsAdjustment from 'com/widgets/PointsAdjustment';
import ReciprocityOverrides from 'com/widgets/ReciprocityOverrides';
import ReciprocityOverrideModal from 'com/widgets/ReciprocityOverrideModal';
import SystemSettingsPricingLevels, { SystemSettingsPricingLevelsMain } from '../com/pages/SystemSettingsPricingLevels';
import PricingLevelForm from 'com/widgets/PricingLevelForm';
import QuotesDetails from 'com/pages/QuotesDetails';
import QuoteDetailsView, { QuoteDetailsViewEstimatesRules, QuoteDetailsViewQuoteRevisions, QuoteDetailsViewQuoteEstimate } from 'com/pages/QuoteDetailsView';
import QuotesList from 'com/pages/QuotesList';
import SystemSettingsExchangeRates from '../com/pages/SystemSettingsExchangeRates';
import SystemSettingsBanners from 'com/pages/SystemSettingsBanners';
import SystemSettingsBannerList from 'com/pages/SystemSettingsBanners/BannerList';
import SystemSettingsBannerCreate from 'com/pages/SystemSettingsBanners/BannerCreate';
import SystemSettingsEmail from 'com/pages/SystemSettingsEmail';
import SystemSettingsEmailTemplates, { SystemSettingsEmailTemplatesMain, SystemSettingsEmailTemplatesDetails } from '../com/pages/SystemSettingsEmailTemplates';
import SystemSettingsRegionalTemplates, { SystemSettingsRegionalTemplatesMain, SystemSettingsRegionalTemplatesDetails } from '../com/pages/SystemSettingsRegionalTemplates';
import SystemSettingsFeaturesVisible from 'com/pages/SystemSettingsFeaturesVisible';
import CaseDetails, { CaseDetailsInstructed, CaseDetailsUnInstructed, CaseDetailsSendEmail } from 'com/pages/CaseDetails';
import CasesList from 'com/pages/CasesList';
import CaseSettings from 'com/widgets/CaseSettings';
import DocumentsTable from 'com/widgets/DocumentsTable';
import InstructModal from 'com/widgets/InstructModal';
import SystemSettingsReciprocityFeeThresholds from 'com/pages/SystemSettingsReciprocityFeeThresholds';
import DuplicateManagement, { DuplicateManagementMain } from 'com/pages/DuplicateManagement';
import LegalInfo from 'com/pages/LegaInfo/LegalInfo';
import LegalInfoTermsAndConditions from 'com/pages/LegalInfoTermsAndConditions';
import LegalInfoEngagementLetter from 'com/pages/LegalInfoEngagementLetter';
import RenewalsValidationRules from 'com/pages/RenewalsValidationRules';
import RenewalsTransformationRules from 'com/pages/RenewalsTransformationRules';
import NewQuote from 'com/pages/QuotesDetails/NewQuote';
import ClientOnboarding from 'com/pages/ClientOnboarding';

import { INTERNAL_ROLES, EXTERNAL_ROLES } from './constants';
import { compose } from 'redux';

export default {
	ROOT: {
		path: '/',
		name: '',
		acl: ['*'],
		component: Login,
	},
	REGISTER: {
		path: '/register',
		name: '',
		acl: ['*'],
		component: Register,
	},
	REGISTER_THANK_YOU: {
		path: '/register/thank-you',
		name: '',
		acl: ['*'],
		component: RegisterThankyou,
	},
	FORGOT_PASSWORD: {
		path: '/forgot-password',
		name: '',
		acl: ['*'],
		component: ForgotPassword,
	},
	FORGOT_PASSWORD_MESSAGE: {
		path: '/forgot-password/message',
		name: '',
		acl: ['*'],
		component: ForgotPasswordMessage,
	},
	CHANGE_PASSWORD: {
		path: '/change-password/:id',
		name: '',
		acl: ['*'],
		component: ChangePassword,
	},
	CHANGE_PASSWORD_MESSAGE: {
		path: '/change-password/message',
		name: '',
		acl: ['*'],
		component: ChangePasswordMessage,
	},
	CONFIRM_ACCOUNT: {
		path: '/confirm-account/:id',
		name: '',
		acl: ['*'],
		component: ConfirmAccount,
	},
	DASHBOARD: {
		path: '/',
		name: '',
		acl: ['*'],
		component: Dashboard,
	},
	REPORTS: {
		path: '/reports',
		name: '',
		acl: INTERNAL_ROLES,
		component: Reports,
	},
	REPORTS_CLIENT: {
		path: '/reports/member',
		name: '',
		acl: INTERNAL_ROLES,
		component: ClientReport,
	},
	REPORTS_REGIONAL: {
		path: '/reports/regional',
		name: '',
		acl: ['sales', 'case_manager_manager', 'admin', 'case_manager', 'estimates', 'finance', 'sales_manager'],
		component: RegionalReport,
	},
	REPORTS_CASE: {
		path: '/reports/case',
		name: '',
		acl: ['sales', 'case_manager_manager', 'admin', 'case_manager', 'estimates', 'finance', 'sales_manager'],
		component: CaseReport,
	},
	REPORTS_CASE_ADJUSTMENT: {
		path: '/reports/case/firm/:id/adjustment',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: PointsAdjustment,
	},
	REPORTS_TOP_10: {
		path: '/reports/top-10',
		name: '',
		acl: ['sales', 'case_manager_manager', 'admin', 'case_manager', 'estimates', 'finance', 'sales_manager'],
		component: Top10Report,
	},
	REPORTS_OWED_ASSOCIATES: {
		path: '/reports/owed-associates',
		name: '',
		acl: ['sales', 'case_manager_manager', 'admin', 'case_manager', 'estimates', 'finance', 'sales_manager'],
		component: OwedAssociatesReport,
	},
	ASSOCIATE_ASSIGNMENT: {
		path: '/associate-assignment',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager', 'user:dev', 'member_assistant:dev', 'member:dev'],
		component: AssociateAssignment,
	},
	USER_MANAGEMENT_LIST: {
		path: '/user-management',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: UserManagementList,
	},
	USER_ADD: {
		path: '/user-management/add',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: UserAdd,
	},
	USER_DETAILS: {
		path: '/user-management/:id',
		name: '',
		acl: ['*'],
		component: UserDetails,
	},
	USER_DETAILS_MAIN: {
		path: '/user-management/:id',
		name: '',
		acl: ['*'],
		component: UserDetailsMain,
	},
	USER_DETAILS_EXPERTISE_REGIONS: {
		path: '/user-management/:id/expertise-regions',
		name: '',
		acl: ['*'],
		component: UserDetailsExpertiseRegions,
	},
	USER_DETAILS_RATES: {
		path: '/user-management/:id/rates',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager', 'user', 'member', 'member_assistant'],
		component: UserDetailsRates,
	},
	USER_DETAILS_POINTS: {
		path: '/user-management/:id/points',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: UserDetailsPoints,
	},
	USER_DETAILS_POINTS_ADJUSTMENT: {
		path: '/user-management/:id/points/adjustment',
		name: '',
		acl: ['admin'],
		component: PointsAdjustment,
	},
	USER_DETAILS_PREFERRED_ASSOCIATES: {
		path: '/user-management/:id/preferred-associates',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: UserDetailsPreferredAssociates,
	},
	USER_DETAILS_BLACKLISTED_ASSOCIATES: {
		path: '/user-management/:id/blacklisted-associates',
		name: '',
		acl: ['admin', 'sales', 'sales_manager', 'estimates', 'case_manager', 'case_manager_manager', 'user'],
		component: UserDetailsBlacklistedAssociates,
	},
	USER_DETAILS_COMMUNICATION_PREFERENCES: {
		path: '/user-management/:id/communication-preferences',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager', 'user', 'member', 'member_assistant'],
		component: UserDetailsCommunicationPreferences,
	},
	FIRM_MANAGEMENT_LIST: {
		path: '/firm-management',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: FirmManagementList,
	},
	FIRM_ADD: {
		path: '/firm-management/add',
		name: '',
		acl: ['*'],
		component: FirmAdd,
	},
	FIRM_DETAILS: {
		path: '/firm-management/:id',
		name: '',
		acl: ['*'],
		component: FirmDetails,
	},
	FIRM_DETAILS_MAIN: {
		path: '/firm-management/:id',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager', '$firm', '$agentOfFirm', '$clientOfFirm'],
		component: FirmDetailsMain,
	},
	FIRM_DETAILS_POINTS: {
		path: '/firm-management/:id/points',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: FirmDetailsPoints,
	},
	FIRM_DETAILS_POINTS_ADJUSTMENT: {
		path: '/firm-management/:id/points/adjustment',
		name: '',
		acl: ['admin'],
		component: PointsAdjustment,
	},
	FIRM_DETAILS_MEMBERS: {
		path: '/firm-management/:id/members',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager', '$firm'],
		component: FirmDetailsMembers,
	},
	FIRM_DETAILS_EXPERTISE_REGIONS: {
		path: '/firm-management/:id/expertise-regions',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager', '$firm', '$clientOfFirm'],
		component: FirmDetailsExpertiseRegions,
	},
	FIRM_DETAILS_RATES: {
		path: '/firm-management/:id/rates',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager', '$firm', '$clientOfFirm'],
		component: FirmDetailsRates,
	},
	FIRM_DETAILS_ACCOUNTS: {
		path: '/firm-management/:id/accounts',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: FirmDetailsAccounts,
	},
	FIRM_DETAILS_ESTIMATE_RULES: {
		path: '/firm-management/:id/estimate-rules',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: FirmDetailsEstimateRules,
	},
	FIRM_DETAILS_ACCOUNTS_RULES_ADD: {
		path: '/firm-management/:id/accounts/rule',
		name: '',
		acl: ['*'],
		component: ReciprocityOverrideModal,
	},
	FIRM_DETAILS_RECIPROCITY_RULES: {
		path: '/firm-management/:id/reciprocity-rules',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: ReciprocityOverrides,
	},
	FIRM_DETAILS_RECIPROCITY_RULES_CREATE: {
		path: '/firm-management/:id/reciprocity-rules/:rid',
		name: '',
		acl: ['*'],
		component: ReciprocityOverrideModal,
	},
	AGENTS: {
		path: '/agents',
		name: '',
		acl: ['admin', 'saas_user'],
		component: AgentsList,
	},
	TOOLS: {
		path: '/tools',
		name: '',
		acl: ['sales', 'case_manager_manager', 'admin', 'case_manager', 'estimates', 'finance', 'sales_manager', 'saas_user'],
		component: Tools,
	},
	ASSIGNMENT_OVERRIDE_REQUESTS: {
		path: '/tools/assignment-override-requests',
		name: '',
		acl: ['*'],
		component: AssignmentOverrideRequests,
	},
	ASSIGNMENT_OVERRIDE_REQUESTS_MAIN: {
		path: '/tools/assignment-override-requests',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: AssignmentOverrideRequestsMain,
	},
	ASSIGNMENT_OVERRIDE_REQUESTS_ADD: {
		path: '/tools/assignment-override-requests/add',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: AssignmentOverrideRequestsForm,
	},
	ASSIGNMENT_OVERRIDE_REQUESTS_EDIT: {
		path: '/tools/assignment-override-requests/:id',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: AssignmentOverrideRequestsForm,
	},
	TRANSLATION_REQUESTS: {
		path: '/tools/translation-requests',
		name: '',
		acl: ['*'],
		component: TranslationRequests,
	},
	TRANSLATION_REQUESTS_MAIN: {
		path: '/tools/translation-requests',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: TranslationRequestsMain,
	},
	TRANSLATION_REQUESTS_ADD: {
		path: '/tools/translation-requests/add',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: TranslationRequestsForm,
	},
	TRANSLATION_REQUESTS_EDIT: {
		path: '/tools/translation-requests/:id',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: TranslationRequestsForm,
	},
	RENEWALS_VALIDATION_RULES: {
		path: '/tools/renewals/validation-rules',
		name: '',
		acl: ['admin'],
		component: RenewalsValidationRules,
	},
	RENEWALS_TRANSFORMATION_RULES: {
		path: '/tools/renewals/transformation-rules',
		name: '',
		acl: ['admin'],
		component: RenewalsTransformationRules,
	},
	DUPLICATE_MANAGEMENT: {
		path: '/tools/duplicate-management',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: DuplicateManagement,
	},
	DUPLICATE_MANAGEMENT_APPLICANTS: {
		path: '/tools/duplicate-management',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: DuplicateManagementMain,
	},
	DUPLICATE_MANAGEMENT_INVENTORS: {
		path: '/tools/duplicate-management/inventors',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: DuplicateManagementMain,
	},
	DUPLICATE_MANAGEMENT_AGENTS: {
		path: '/tools/duplicate-management/agents',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: DuplicateManagementMain,
	},
	CLIENT_ONBOARDING: {
		path: '/tools/client-onboarding',
		name: '',
		acl: ['*'],
		component: ClientOnboarding,
	},
	SYSTEM_SETTINGS: {
		path: '/system-settings',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettings,
	},
	SYSTEM_SETTINGS_RECIPROCITY_FEE_THRESHOLDS: {
		path: '/system-settings/reciprocity-fee-thresholds',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsReciprocityFeeThresholds,
	},
	SYSTEM_SETTINGS_RECIPROCITY: {
		path: '/system-settings/reciprocity',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsReciprocity,
	},
	SYSTEM_SETTINGS_RECIPROCITY_RATIOS: {
		path: '/system-settings/reciprocity',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsReciprocityRatios,
	},
	SYSTEM_SETTINGS_RECIPROCITY_RULES: {
		path: '/system-settings/reciprocity/rules',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: ReciprocityOverrides,
	},
	SYSTEM_SETTINGS_RECIPROCITY_RULES_CREATE: {
		path: '/system-settings/reciprocity/rules/:rid',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: ReciprocityOverrideModal,
	},
	SYSTEM_SETTINGS_RECIPROCITY_GROUPS: {
		path: '/system-settings/reciprocity/groups',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsReciprocityGroups,
	},
	SYSTEM_SETTINGS_POAS: {
		path: '/system-settings/poa-management',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsPoas,
	},
	SYSTEM_SETTINGS_POAS_MAIN: {
		path: '/system-settings/poa-management',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsPoasMain,
	},
	SYSTEM_SETTINGS_POAS_ADD: {
		path: '/system-settings/poa-management/add',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsPoasRegionalSetting,
	},
	SYSTEM_SETTINGS_POAS_EDIT: {
		path: '/system-settings/poa-management/:id/edit',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsPoasRegionalSetting,
	},
	SYSTEM_SETTINGS_TECHNOLOGIES: {
		path: '/system-settings/technologies',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsTechnologies,
	},
	SYSTEM_SETTINGS_FIRM_FAMILIES: {
		path: '/system-settings/firm-families',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsFirmFamilies,
	},
	SYSTEM_SETTINGS_ESTIMATOR_RULES: {
		path: '/system-settings/estimator-rules',
		name: '',
		acl: ['admin'],
		component: SystemSettingsEstimatorRules,
	},
	SYSTEM_SETTINGS_ESTIMATOR_RULES_MAIN: {
		path: '/system-settings/estimator-rules',
		name: '',
		acl: ['admin'],
		component: SystemSettingsEstimatorRulesMain,
	},
	SYSTEM_SETTINGS_ESTIMATOR_RULES_ADD: {
		path: '/system-settings/estimator-rules/add',
		name: '',
		acl: ['admin'],
		component: SystemSettingsEstimatorRulesDetails,
	},
	SYSTEM_SETTINGS_ESTIMATOR_RULES_EDIT: {
		path: '/system-settings/estimator-rules/:id',
		name: '',
		acl: ['admin'],
		component: SystemSettingsEstimatorRulesDetails,
	},
	SYSTEM_SETTINGS_PRICING_LEVELS: {
		path: '/system-settings/pricing-levels',
		name: '',
		acl: ['admin', 'sales', 'sales_manager'],
		component: SystemSettingsPricingLevels,
	},
	SYSTEM_SETTINGS_PRICING_LEVELS_MAIN: {
		path: '/system-settings/pricing-levels',
		name: '',
		acl: ['admin'],
		component: SystemSettingsPricingLevelsMain,
	},
	SYSTEM_SETTINGS_PRICING_LEVELS_ADD: {
		path: '/system-settings/pricing-levels/add',
		name: '',
		acl: ['admin'],
		component: PricingLevelForm,
	},
	SYSTEM_SETTINGS_PRICING_LEVELS_EDIT: {
		path: '/system-settings/pricing-levels/:id/edit',
		name: '',
		acl: ['admin'],
		component: PricingLevelForm,
	},
	SYSTEM_SETTINGS_EXCHANGE_RATES: {
		path: '/system-settings/exchange-rates',
		name: '',
		acl: ['admin'],
		component: SystemSettingsExchangeRates,
	},
	SYSTEM_SETTINGS_BANNERS: {
		path: '/system-settings/banners',
		name: '',
		acl: ['admin'],
		component: SystemSettingsBanners,
	},
	SYSTEM_SETTINGS_BANNER_LIST: {
		path: '/system-settings/banners',
		name: '',
		acl: ['admin'],
		component: SystemSettingsBannerList,
	},
	SYSTEM_SETTINGS_BANNER_CREATE: {
		path: '/system-settings/banners/create',
		name: '',
		acl: ['admin'],
		component: SystemSettingsBannerCreate,
	},
	SYSTEM_SETTINGS_BANNER_UPDATE: {
		path: '/system-settings/banners/update/:id',
		name: '',
		acl: ['admin'],
		component: SystemSettingsBannerCreate,
	},
	SYSTEM_SETTINGS_EMAIL: {
		path: '/system-settings/email',
		name: '',
		acl: ['admin'],
		component: SystemSettingsEmail,
	},
	SYSTEM_SETTINGS_INTEGRATIONS: {
		path: '/system-settings/integrations',
		name: '',
		acl: ['admin'],
		component: SystemSettingsIntegrations,
	},
	SYSTEM_SETTINGS_EMAIL_TEMPLATES: {
		path: '/system-settings/email-templates',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsEmailTemplates,
	},
	SYSTEM_SETTINGS_EMAIL_TEMPLATES_MAIN: {
		path: '/system-settings/email-templates',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsEmailTemplatesMain,
	},
	SYSTEM_SETTINGS_EMAIL_TEMPLATES_ADD: {
		path: '/system-settings/email-templates/add',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsEmailTemplatesDetails,
	},
	SYSTEM_SETTINGS_EMAIL_TEMPLATES_EDIT: {
		path: '/system-settings/email-templates/:id/edit',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsEmailTemplatesDetails,
	},
	SYSTEM_SETTINGS_EMAIL_TEMPLATES_DUPLICATE: {
		path: '/system-settings/email-templates/:id/duplicate',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsEmailTemplatesDetails,
	},
	SYSTEM_SETTINGS_REGIONAL_TEMPLATES: {
		path: '/system-settings/regional-templates',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsRegionalTemplates,
	},
	SYSTEM_SETTINGS_REGIONAL_TEMPLATES_MAIN: {
		path: '/system-settings/regional-templates',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsRegionalTemplatesMain,
	},
	SYSTEM_SETTINGS_REGIONAL_TEMPLATES_ADD: {
		path: '/system-settings/regional-templates/add',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsRegionalTemplatesDetails,
	},
	SYSTEM_SETTINGS_REGIONAL_TEMPLATES_EDIT: {
		path: '/system-settings/regional-templates/:id/edit',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsRegionalTemplatesDetails,
	},
	SYSTEM_SETTINGS_REGIONAL_TEMPLATES_DUPLICATE: {
		path: '/system-settings/regional-templates/:id/duplicate',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsRegionalTemplatesDetails,
	},
	SYSTEM_SETTINGS_FEATURES_VISIBLE: {
		path: '/system-settings/feature-flags',
		name: '',
		acl: ['admin', 'case_manager_manager', 'case_manager'],
		component: SystemSettingsFeaturesVisible,
	},
	QUOTES_LIST: {
		path: '/quotes',
		name: '',
		acl: ['*'],
		component: QuotesList,
	},
	QUOTES_DETAILS: {
		path: '/quotes/:id',
		name: '',
		acl: ['*'],
		component: QuoteDetailsView,
	},
	QUOTES_INSTRUCT: {
		path: '/quotes/:id/revisions/instruct',
		name: '',
		acl: ['*'],
		component: InstructModal,
	},
	QUOTES_INSTRUCT_EXTERNAL: {
		path: '/quotes/:id/estimate/instruct',
		name: '',
		acl: EXTERNAL_ROLES,
		component: InstructModal,
	},
	QUOTE_DETAILS_ESTIMATES_RULES: {
		path: '/quotes/:id/estimates-rules',
		name: '',
		acl: ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'],
		component: QuoteDetailsViewEstimatesRules,
	},
	QUOTE_DETAILS_QUOTE_REVISIONS: {
		path: '/quotes/:id/revisions',
		name: '',
		acl: INTERNAL_ROLES,
		component: QuoteDetailsViewQuoteRevisions,
	},
	QUOTE_DETAILS_QUOTE_ESTIMATE: {
		path: '/quotes/:id/estimate',
		name: '',
		acl: ['*'],
		component: QuoteDetailsViewQuoteEstimate,
	},
	QUOTES_ADD: {
		path: '/quotes/add',
		name: '',
		acl: ['*'],
		component: NewQuote,
	},
	QUOTES_FINISH_DRAFT_QUOTE: {
		path: '/quotes/add/:id/draft',
		name: '',
		acl: ['*'],
		component: NewQuote,
	},
	QUOTES_FINISH_DRAFT_INSTRUCT_WITHOUT_PRE_EXISTING: {
		path: '/quotes/instruct-without-pre-existing/:id/draft',
		name: '',
		acl: ['*'],
		component: NewQuote,
	},
	QUOTES_INSTRUCT_WITHOUT_PRE_EXISTING: {
		path: '/quotes/instruct-without-pre-existing',
		name: '',
		acl: [...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')],
		component: NewQuote,
	},
	QUOTES_EDIT: {
		path: '/quotes/:id/edit',
		name: '',
		acl: ['*'],
		component: QuotesDetails,
	},
	QUOTE_DOCUMENTS: {
		path: '/quotes/:id/documents',
		name: '',
		acl: ['*'],
		component: DocumentsTable,
	},
	CASE_DETAILS: {
		path: '/case-details/:id',
		name: '',
		acl: ['*'],
		component: CaseDetails,
	},
	CASE_DETAILS_INSTRUCTED: {
		path: '/case-details/:id',
		name: '',
		acl: ['*'],
		component: CaseDetailsInstructed,
	},
	CASE_DETAILS_UNINSTRUCTED: {
		path: '/case-details/:id/uninstructed',
		name: '',
		acl: ['*'],
		component: CaseDetailsUnInstructed,
	},
	CASE_DETAILS_SEND_EMAIL: {
		path: '/case-details/:id/email',
		name: '',
		acl: ['*'],
		component: CaseDetailsSendEmail,
	},
	CASES: {
		path: '/cases',
		name: '',
		acl: ['*'],
		component: CasesList,
	},
	CASE_SETTINGS: {
		path: '/cases/:id/settings',
		name: '',
		acl: ['*'],
		component: CaseSettings,
	},
	CASE_DOCUMENTS: {
		path: '/case-details/:id/documents',
		name: '',
		acl: ['*'],
		component: DocumentsTable,
	},
	CASE_INSTRUCT: {
		path: '/case-details/:id/uninstructed/instruct',
		name: '',
		acl: ['*'],
		component: InstructModal,
	},
	LEGAL_INFO: {
		path: '/legal-info',
		name: '',
		acl: ['*'],
		component: LegalInfo,
	},
	LEGAL_INFO_TERMS_AND_CONDITIONS: {
		path: '/legal-info/terms-and-conditions',
		name: '',
		acl: ['*'],
		component: LegalInfoTermsAndConditions,
	},
	LEGAL_INFO_ENGAGEMENT_LETTER: {
		path: '/legal-info/engagement-letter',
		name: '',
		acl: ['*'],
		component: LegalInfoEngagementLetter,
	},
	WILDCARD: {
		path: '*',
		name: '',
		acl: ['*'],
		component: Login,
	},
	PRIVATE_WILDCARD: {
		path: '*',
		name: '',
		acl: ['*'],
		component: Dashboard,
	},
};
