import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Modal, Input, Select, LoaderOverlay } from 'sazzui/lib/main';

import Settings from 'services/rest/settings';
import Reciprocity from 'services/rest/reciprocity';

import useNotification from 'services/hooks/use_notification';

import { RECIPROCITY_OVERRIDE_DIRECTION, RECIPROCITY_OVERRIDE_TYPE } from 'data/constants';
import { isoStringToMySQLDate, toENCADateString } from 'services/dates';

import './style.css';

const ReciprocityOverrideModal = (props) => {
	const history = useHistory();
	const params = useParams();

	const initRegions = [];
	const initServices = [];
	const directions = Object.keys(RECIPROCITY_OVERRIDE_DIRECTION).map((el) => ({ value: el, label: el }));
	const types = props.prepareReciprocityTypeOptions();
	const accounts = props.firmAccounts ? props.firmAccounts.map((el) => ({ value: el.id, label: el.name })) : [];
	const inputDataInit = { start_date: '', end_date: '', region_id: '', service_id: '', type: types[0].value, account: accounts[0]?.value, direction: directions[0].value, amount: '' };
	const errorsInit = { date: '', amount: '' };
	const errorMessages = {
		required: 'Required',
		startDateRequired: 'Start Date is Mandatory',
		invalidStartDate: 'Start Date Must Be Current Date or in Future',
		invalidEndDate: 'End Date Must Be in Future',
		invalidDateRange: 'Invalid Date Range',
		datesOverlapping: 'This Rule Overlaps With Another Rule',
	};

	const [inputData, setInputData] = useState(inputDataInit);
	const [editingOverride, setEditingOverride] = useState(inputDataInit);
	const [showAccounts, setShowAccounts] = useState(false);
	const [regions, setRegionsData] = useState(initRegions);
	const [services, setServicesData] = useState(initServices);
	const [showLoader, setShowLoader] = useState(false);
	const [errors, setErrors] = useState(errorsInit);
	const sendNotification = useNotification();

	useEffect(() => {
		(async () => {
			try {
				setShowLoader(true);
				let promises = [Settings.GetRegions(), Settings.GetServices()];
				if (isEditMode()) promises.push(fetchReciprocityOverride());
				let pRes = await Promise.all(promises);
				setRegionsData([{ label: 'All', value: '' }, ...pRes[0].map((r) => ({ value: r.id, label: r.name }))]);
				setServicesData([{ label: 'Select Service', value: '' }, ...pRes[1].map((s) => ({ value: s.id, label: s.name }))]);
				setShowLoader(false);
			} catch (err) {
				setShowLoader(false);
			}
		})();
	}, []);

	const isEditMode = () => {
		return params && params.rid && params.rid !== 'add';
	};

	const fetchReciprocityOverride = async () => {
		let ro = await Reciprocity.GetOneReciprocityOverride(params.rid);
		let data = { ...inputData };
		Object.keys(data).forEach((key) => {
			switch (key) {
				case 'start_date':
				case 'end_date':
					data[key] = ro[key] ? toENCADateString(ro[key]) : '';
					break;
				case 'type':
					data['type'] = ro['object_type'];
					setShowAccounts(ro['object_type'] === RECIPROCITY_OVERRIDE_TYPE.ACCOUNT ? true : false);
					break;
				case 'account':
					data['account'] = ro['object_type'] === RECIPROCITY_OVERRIDE_TYPE.ACCOUNT ? ro['object_id'] : data['account'];
					break;
				default:
					data[key] = ro[key];
					break;
			}
		});
		setInputData(data);
		setEditingOverride(ro);
	};

	const close = () => {
		history.goBack();
	};

	const validate = () => {
		let isValid = true;
		setErrors(errorsInit);
		// start date is mandatory
		if (inputData.start_date === '') {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, date: errorMessages.startDateRequired }));
		}
		// start date must be current date or in future
		if (new Date(inputData.start_date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, date: errorMessages.invalidStartDate }));
		}
		// end date must be in future
		if (inputData.end_date !== '' && new Date(inputData.end_date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, date: errorMessages.invalidEndDate }));
		}
		// invalid date range
		if (inputData.start_date && inputData.end_date && !(new Date(inputData.start_date) <= new Date(inputData.end_date))) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, date: errorMessages.invalidDateRange }));
		}
		if (checkOverlappingOverrides()) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, date: errorMessages.datesOverlapping }));
		}
		if (inputData.service_id === '') {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, service_id: errorMessages.required }));
		}
		if (inputData.amount === '') {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, amount: errorMessages.required }));
		}
		return isValid;
	};

	const checkOverlappingOverrides = () => {
		let matchingOverrides = props.reciprocityOverrides.filter(
			(ro) =>
				(ro.region_id === '' || ro.region_id === inputData.region_id) &&
				ro.service_id === inputData.service_id &&
				((inputData.type === RECIPROCITY_OVERRIDE_TYPE.SYSTEM && ro.object_type === RECIPROCITY_OVERRIDE_TYPE.SYSTEM) ||
					(inputData.type === RECIPROCITY_OVERRIDE_TYPE.FIRM && ro.object_type === RECIPROCITY_OVERRIDE_TYPE.FIRM) ||
					(inputData.type === RECIPROCITY_OVERRIDE_TYPE.ACCOUNT && ro.object_type === RECIPROCITY_OVERRIDE_TYPE.ACCOUNT && ro.object_id === inputData.account)) &&
				ro.direction === inputData.direction,
		);
		// if in edit mode remove the editing override from the list
		if (isEditMode()) {
			matchingOverrides = matchingOverrides.filter((mo) => mo.id !== params.rid);
		}
		if (!matchingOverrides.length) return false;
		let newStart = inputData.start_date ? new Date(inputData.start_date).setHours(0, 0, 0, 0) : null;
		let newEnd = inputData.end_date ? new Date(inputData.end_date).setHours(0, 0, 0, 0) : null;
		for (let mo of matchingOverrides) {
			let existingStart = mo.start_date ? new Date(mo.start_date).setHours(0, 0, 0, 0) : null;
			let existingEnd = mo.end_date ? new Date(mo.end_date).setHours(0, 0, 0, 0) : null;
			if (newEnd && existingEnd) {
				if (newStart <= existingEnd && newEnd >= existingStart) {
					return true;
				}
			}
			if (!newEnd && !existingEnd) {
				if (newStart === existingStart) {
					return true;
				}
			}
			if (!newEnd && existingEnd) {
				if (newStart >= existingStart && newStart <= existingEnd) {
					return true;
				}
			}
			if (newEnd && !existingEnd) {
				if (newStart === existingStart || newEnd === existingStart) {
					return true;
				}
			}
		}
		return false;
	};

	const save = async () => {
		if (!validate()) return;
		if (isEditMode()) {
			await edit();
		} else {
			await create();
		}
	};

	const getObjectIdByObjectType = () => {
		switch (inputData.type) {
			case RECIPROCITY_OVERRIDE_TYPE.SYSTEM:
				return '';
			case RECIPROCITY_OVERRIDE_TYPE.FIRM:
				return params.id;
			case RECIPROCITY_OVERRIDE_TYPE.ACCOUNT:
				return inputData.account;
			default:
				throw Error(`Unknown reciprocity rule type: ${inputData.type}`);
		}
	};

	const create = async () => {
		try {
			let data = {
				object_type: inputData.type,
				object_id: getObjectIdByObjectType(),
				start_date: inputData.start_date,
				end_date: inputData.end_date,
				region_id: inputData.region_id !== '' ? inputData.region_id : null,
				service_id: inputData.service_id,
				direction: inputData.direction,
				amount: inputData.amount,
			};
			await Reciprocity.CreateReciprocityOverride(data);
			props.afterSave();
			close();
			sendNotification({ type: 'success', title: 'Reciprocity rule successfully created' });
		} catch (e) {
			console.log(e);
			sendNotification({ type: 'error', title: 'Reciprocity rule creation failed' });
		}
	};

	const edit = async () => {
		try {
			let data = { ...editingOverride };
			Object.keys(inputData).forEach((key) => {
				switch (key) {
					case 'type':
						data['object_type'] = inputData[key];
						break;
					case 'account':
						data['object_id'] = getObjectIdByObjectType();
						break;
					default:
						data[key] = inputData[key];
						break;
				}
			});
			await Reciprocity.UpdateReciprocityOverride(params.rid, data);
			props.afterSave();
			close();
			sendNotification({ type: 'success', title: 'Reciprocity rule successfully saved' });
		} catch (e) {
			sendNotification({ type: 'error', title: 'Reciprocity rule saving failed' });
		}
	};

	const handleChange = (e) => {
		let field = e.target.name;
		let val = e.target.value;

		if (['start_date', 'end_date'].includes(field) && val !== '') {
			val = isoStringToMySQLDate(val);
		}
		if (field == 'amount') {
			// Make a negative number if direction is incoming and a positive number if direction is outgoing
			if ((inputData.direction == RECIPROCITY_OVERRIDE_DIRECTION.INCOMING && Number(val) > 0) || (inputData.direction == RECIPROCITY_OVERRIDE_DIRECTION.OUTGOING && Number(val) < 0)) {
				val = Number(val) * -1;
			}
		}
		if (field == 'type') {
			setShowAccounts(val === RECIPROCITY_OVERRIDE_TYPE.ACCOUNT ? true : false);
		}

		setInputData({
			...inputData,
			[field]: val,
		});
	};

	const handleDirectionChange = (e) => {
		let val = e.target.value;
		let changes = { direction: val };
		// Make a negative number if direction is incoming and a positive number if direction is outgoing
		if ((val == RECIPROCITY_OVERRIDE_DIRECTION.INCOMING && Number(inputData.amount) > 0) || (val == RECIPROCITY_OVERRIDE_DIRECTION.OUTGOING && Number(inputData.amount) < 0)) {
			changes['amount'] = Number(inputData.amount) * -1;
		}
		setInputData({
			...inputData,
			...changes,
		});
	};

	const modalActions = [
		{ primary: false, label: 'Cancel', action: close, theme: 'azami-ghost' },
		{ primary: true, label: 'Apply Rule', action: save, theme: 'azami-blue' },
	];

	return (
		<Modal title="Points Override Rule" closeHandler={close} footerActions={modalActions}>
			<LoaderOverlay showLoader={showLoader} />
			<div className="reciprocity-override-modal__hgroup column">
				<Input label="Date" type="date" onChange={handleChange} name="start_date" value={inputData.start_date} error={errors.date} ariaLabel="Date Input" />
				<Input type="date" onChange={handleChange} name="end_date" value={inputData.end_date} ariaLabel="EndDate Input" />
			</div>
			<div className="reciprocity-override-modal__hgroup">
				<Select options={regions} label="Regions" onChange={handleChange} name="region_id" value={inputData.region_id} ariaLabel="Regions Select" />
				<Select options={services} label="Services" onChange={handleChange} name="service_id" value={inputData.service_id} error={errors.service_id} ariaLabel="Services Select" />
			</div>
			<div className="reciprocity-override-modal__hgroup">
				<Select options={types} label="For" onChange={handleChange} name="type" value={inputData.type} ariaLabel="For Select" />
				{showAccounts ?
					<Select options={accounts} label="Account" onChange={handleChange} name="account" value={inputData.account} ariaLabel="Account Select" />
				:	null}
			</div>
			<div className="reciprocity-override-modal__hgroup">
				<Select options={directions} label="Case Direction" onChange={handleDirectionChange} name="direction" value={inputData.direction} ariaLabel="Case direction Select" />
				<Input label="Amount" type="number" onChange={handleChange} name="amount" value={inputData.amount} error={errors.amount} ariaLabel="Amount Input" />
			</div>
		</Modal>
	);
};

export default ReciprocityOverrideModal;
