//System Imports
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
//Component Imports
import { Modal, Select } from 'sazzui/lib/main';
//Service Imports
import Settings from 'services/rest/settings';
import { getPlatform } from 'services/strings';
import { useDispatch } from 'react-redux';
import { setUserData } from 'redux/ducks/auth';
import useKeyboardShortcut from 'services/hooks/useKeyboardShortcut';
//Constant Imports
import { USER_ROLES, KEYBOARD_SHORTCUTS, API_ROLES } from 'data/constants';

const RolesModal = ({ forRoles = [] }) => {
	let [roles, setRoles] = useState([]);
	let [roleId, setRoleId] = useState('');
	let [modalOpen, setModalOpen] = useState(false);
	const dispatch = useDispatch();
	let user = useSelector((state) => state.auth.user);
	const platform = getPlatform();

	const getData = useCallback(async () => {
		try {
			const userRoles = await Settings.GetUserRoles({ exclude_names: [API_ROLES.APP, API_ROLES.SYSTEM, USER_ROLES.SAAS_USER] });

			setRoles([{ label: 'Select Role', value: '' }, ...userRoles.map((r) => ({ label: r.title, value: r.id, name: r.name, type: r.type }))]);
			if (user) {
				const uRole = userRoles.find((r) => r.name === user.role[0]);
				if (uRole) return setRoleId(uRole.id);
			}
		} catch (error) {
			console.log(error);
		}
	}, [user]);

	useEffect(() => {
		getData();
	}, [getData]);

	const handleOpenModal = () => {
		setModalOpen(true);
	};
	const handleCloseModal = () => {
		setModalOpen(false);
	};

	useKeyboardShortcut(KEYBOARD_SHORTCUTS.OPEN_ROLES_MODAL[platform], handleOpenModal);
	useKeyboardShortcut(KEYBOARD_SHORTCUTS.CLOSE_MODAL[platform], handleCloseModal);

	const handleOnChange = (e) => {
		setRoleId(e.target.value);
	};

	const handleSave = () => {
		if (!roleId) return;
		const selectedRole = roles.find((r) => r.value === roleId);
		if (selectedRole) {
			dispatch(setUserData({ ...user, role: [selectedRole.name], roleType: selectedRole.type }));
			return setModalOpen(false);
		}
	};

	const footerActions = [
		{ primary: false, label: 'Close', action: handleCloseModal, theme: 'primary' },
		{ primary: true, label: 'Apply', action: handleSave, theme: 'primary' },
	];

	if (!forRoles.includes('*') && !forRoles.includes(user.role[0])) return null;

	return (
		<>
			{modalOpen ?
				<Modal title="Select Role" closeHandler={handleCloseModal} footerActions={footerActions}>
					<Select options={roles} onChange={handleOnChange} value={roleId} name="role_id" />
				</Modal>
			:	null}
		</>
	);
};

export default RolesModal;
