import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RatesList from 'com/widgets/RatesList';
// services
import Settings from 'services/rest/settings';
import FirmAgents from 'services/rest/firm_agents';

const FirmDetailsRates = (props) => {
	const user = useSelector((state) => state.auth.user);
	const [clientsRaw, setClientsRaw] = useState([]);
	const [regionServiceMapping, setRegionServiceMapping] = useState(new Map());
	const [services, setServices] = useState([]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await fetchRegionServiceMapping();
			if (user.role.includes('saas_user')) {
				fetchClients();
			}
		})();
	}, [props.firmData.regions]);

	const fetchData = async () => {
		let dataServices = await Settings.GetServices();
		setServices(dataServices);
	};

	const fetchRegionServiceMapping = async () => {
		let rIDs = props.firmData.regions.map((rid) => rid.region_id);
		let servicesToShow = await Settings.GetRegionServiceMapping({ region_ids: rIDs });
		const rsm = new Map();
		for (let serviceToShow of servicesToShow) {
			const regionID = serviceToShow.region_id;
			const regionValue = rsm.get(regionID);
			if (regionValue) {
				regionValue.push(serviceToShow.service_id);
			} else {
				rsm.set(regionID, [serviceToShow.service_id]);
			}
		}
		setRegionServiceMapping(rsm);
	};

	const fetchClients = async () => {
		try {
			let clients = await FirmAgents.GetAllByAssociateID(props.firmData.id);
			setClientsRaw(clients);
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<div className="firm-details-rates">
			<RatesList
				data={props.firmData}
				onChange={props.onChangeRate}
				onChangeFxRate={props.onChangeFxRate}
				disabledCurrency={props.disabledCurrency}
				clients={clientsRaw}
				firmCurrency={props.firmData.currency_id}
				regionServiceMapping={regionServiceMapping}
				services={services}
			/>
		</div>
	);
};

export default FirmDetailsRates;
