import React, { useState, useEffect } from 'react';

import { Button } from 'sazzui/lib/main';
import { Input } from 'sazzui/lib/main';

import useNotification from 'services/hooks/use_notification';
import Preferences from 'services/rest/preferences';
import { PREFERENCES_TYPE, PREFERENCES_GROUP } from 'data/constants';

import './style.css';

const SystemSettingsEmail = () => {
	const errorMessages = {
		required: 'Required',
	};

	const [spinner, setSpinner] = useState(false);
	const [formData, setFormData] = useState([]);
	const [errors, setErrors] = useState({});
	const sendNotification = useNotification();

	const fetchData = async () => {
		try {
			let res = await Preferences.GetSystemRecordsByGroup(PREFERENCES_GROUP.EMAIL_SETTINGS);
			setFormData(res);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fieldChange = (e) => {
		const { name, value } = e.target;

		setFormData(
			formData.map((row) => {
				if (row.preference_key === name) {
					row.preference_value = value;
				}
				return row;
			}),
		);
	};

	const validate = () => {
		let isValid = true;
		setErrors({});

		for (let row of formData) {
			if (!row.preference_value) {
				isValid = false;
				setErrors((prevState) => ({
					...prevState,
					[row.preference_key]: errorMessages.required,
				}));
			}
		}

		return isValid;
	};

	const submitForm = async () => {
		try {
			if (!validate()) {
				return false;
			}

			setSpinner(true);
			Preferences.Update(formData);
			sendNotification({ type: 'success', title: 'Successfully saved Email settings' });
			setSpinner(false);
		} catch (err) {
			console.log(err);
			sendNotification({ type: 'error', title: 'Unsuccessfully saved Email settings' });
		}
	};

	return (
		<div className="main-screen-section white full-height system-details-content">
			<div className="system-settings__preferences__header">
				<span className="system-settings__preferences__header__title">Email</span>
			</div>
			<div className="system-settings__preferences__body system-settings-table-wrapper">
				<form>
					{formData.map((row) => {
						let key = row.preference_key;
						let label = row.preference_key.replace(/_/g, ' ').toLowerCase();
						return <Input type="text" name={key} value={row.preference_value} onChange={fieldChange} label={label} error={errors[key]} />;
					})}
				</form>
				<Button theme="special" onClick={submitForm} showSpinner={spinner}>
					Save
				</Button>
			</div>
		</div>
	);
};

export default SystemSettingsEmail;
