import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppTemplate from 'com/templates/ApplicationTemplate';
import { DataTable, Paging } from 'sazzui/lib/widgets/DataTable';
import { Input } from 'sazzui/lib/ui/Input';
import { TagSelect } from 'sazzui/lib/ui/TagSelect';
import { Button } from 'sazzui/lib/ui/Button';
import { LoaderOverlay } from 'sazzui/lib/ui/LoaderOverlay';
import CaseDirectionFilter from 'com/widgets/CaseDirectionFilter';

import { RoleBox } from 'com/util/RoleBox';
import { CASE_DIRECTIONS, PAGE_TITLE, ROLE_TYPES, EXTERNAL_ROLES, INTERNAL_ROLES } from 'data/constants';
import AppLink from 'com/util/AppLink';
import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import Settings from 'services/rest/settings';
import Cases from 'services/rest/cases';
import EventLoggerEvents from 'services/rest/event_logger';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';
import useQuery from 'services/hooks/useQuery';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_ARROW_UP_ORANGE from 'assets/images/icons/ico_arrow_up_orange.svg';
import ICON_ARROW_DOWN_BLUE from 'assets/images/icons/ico_arrow_down_blue.svg';
import ICON_ASSOCIATE_AZAMI from 'assets/images/icons/ico_associate_azami.svg';
import ICON_CLIENT_AZAMI from 'assets/images/icons/ico_client_azami.svg';
import ICON_SEPARATOR from 'assets/images/icons/ico_separator.svg';

const CasesList = () => {
	useTitle(PAGE_TITLE.CASES);
	const user = useSelector((state) => state.auth.user);
	const isUserInternal = user.roleType === ROLE_TYPES.INTERNAL;
	const query = useQuery();
	const clientFirmName = query.get('client-firm-name');

	const tableHeadersInit = [
		{ title: isUserInternal ? 'CASE NO.' : 'REF. NUM.', field: 'case_number', type: 'string', sort: 'none', sortable: false },
		{ title: 'CLIENT FIRM & CLIENT', field: 'client_name', type: 'string', sort: 'none', sortable: false },
		{ title: 'ASSOC. FIRM & ASSOCIATE', field: 'associate_name', type: 'string', sort: 'none', sortable: false },
		{ title: 'REF. NO.', field: 'reference_number', type: 'string', sort: 'none', sortable: false },
		{ title: 'REGION', field: 'region_name', type: 'string', sort: 'none', sortable: false },
		{ title: 'SERVICE', field: 'service', type: 'string', sort: 'none', sortable: false },
		{ title: 'APP. NO./APPLICANT', field: 'application_number', type: 'string', sort: 'none', sortable: false },
		{ title: 'LAST UPDATED', field: '_modified', type: 'string', sort: 'down', sortable: true },
		{ title: 'DEADLINE', field: 'due_date', type: 'date', sort: 'none', sortable: true },
	];

	const filterInit = {
		case_number: '',
		client: clientFirmName ? clientFirmName : '',
		associate: '',
		application_number: '',
		deadline: '',
		service: [],
		region: [],
		orderBy: [{ field: '_modified', direction: 'down' }],
		direction: 'ALL',
		reference_number: '',
		deadline_date_from: '',
		deadline_date_to: '',
		updated_on: '',
	};

	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [showLoader, setShowLoader] = useState(false);
	const [services, setServices] = useState([]);
	const [regions, setRegions] = useState([]);
	const [cases, setCases] = useState([]);
	const [paging, setPaging] = useState({ total_rows: 0, page: 1, limit: Paging.PAGE_SIZE[0].value });
	const [pageChange, setPageChange] = useState(1);
	const [filterData, setFilterData] = useState(filterInit);
	const history = useHistory();
	const { getFilterValue, setFilterValue } = useFilterStorage();

	const fetchData = async () => {
		try {
			const [se, rs] = await Promise.all([Settings.GetServices(), Settings.GetRegions()]);
			setServices([
				{ label: 'Select service', value: '' },
				...se.map((s) => {
					return { label: s.name, value: s.id };
				}),
			]);
			setRegions([
				{ label: 'Select region', value: '' },
				...rs.map((r) => {
					return { label: r.name, value: r.id };
				}),
			]);
		} catch (error) {
			console.log(error);
		}
	};

	const getCases = async () => {
		try {
			let f = getFilterValue();
			if (f) setFilterData(f);
			setShowLoader(true);
			const filter = {
				...(f ? f : filterData),
				orderBy: filterData.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
				page: paging.page,
				limit: paging.limit,
			};
			const casesData = await Cases.GetAllByUser(filter);
			setCases(casesData.data);
			setPaging({ ...paging, total_rows: casesData.total_rows });
			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
			console.log(error);
		}
	};

	const filterChange = (e) => {
		const { name, value } = e.target;
		setFilterData({ ...filterData, [name]: value });
	};

	const filterSubmit = () => {
		setPaging({ ...paging, total_rows: 0, page: 1 });
		setPageChange(pageChange + 1);
		EventLoggerEvents.EventLogger({ event: `Search Cases`, data: { user: user } });
	};

	const filterButtonHandler = () => {
		filterSubmit();
		setFilterValue(filterData);
	};

	const clearForm = () => {
		setFilterData(filterInit);
		filterSubmit();
		setFilterValue(null);
	};

	const directionChange = (e) => {
		const value = e.target.parentNode.dataset.name;
		setFilterData({ ...filterData, direction: value });
		setFilterValue({ ...filterData, direction: value });
		filterSubmit();
	};

	const pagingChange = (p, l) => {
		setPaging({ ...paging, page: p, limit: l });
		setPageChange(pageChange + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const columnSort = (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.orderBy.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							orderBy: [{ field: col, direction: h.sort }, ...filterData.orderBy],
						});
					} else {
						setFilterData({
							...filterData,
							orderBy: [{ field: col, direction: h.sort }, ...filterData.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const goToDetails = (e) => {
		let caseID = e.target.closest('tr').dataset.cid;
		EventLoggerEvents.EventLogger({ event: `Case Details Popup`, data: { case_id: caseID, user: user } });
		history.push(`/case-details/${caseID}`);
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		getCases();
	}, [pageChange]);

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			setFilterValue(filterData);
			filterSubmit();
		}
	};

	return (
		<AppTemplate title="Cases" headerIcon="cases">
			<AppTemplate.Sidebar>
				<div className="cases-sidebar">
					<h4 className="cases-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						Filters
					</h4>
					<Input
						label={isUserInternal ? 'Case Number' : 'Reference Number'}
						name="case_number"
						theme="dark"
						placeholder={isUserInternal ? 'Enter case number' : 'Enter reference number'}
						value={filterData.case_number}
						onChange={filterChange}
						onKeyDown={handleKeyDown}
					/>
					<Input label="Client" name="client" theme="dark" placeholder="Enter Client Name" value={filterData.client} onChange={filterChange} onKeyDown={handleKeyDown} />
					<Input label="Associate" name="associate" theme="dark" placeholder="Enter Associate Name" value={filterData.associate} onChange={filterChange} onKeyDown={handleKeyDown} />
					<Input
						label="Application Number"
						name="application_number"
						theme="dark"
						placeholder="Enter application number"
						value={filterData.application_number}
						onChange={filterChange}
						onKeyDown={handleKeyDown}
					/>
					<RoleBox roles={EXTERNAL_ROLES}>
						<Input
							label="My Reference Number"
							name="reference_number"
							theme="dark"
							placeholder="Enter reference number"
							value={filterData.reference_number}
							onChange={filterChange}
							onKeyDown={handleKeyDown}
						/>
					</RoleBox>
					<div className="cases-sidebar__deadline">
						<span className="cases-sidebar__deadline-label">Deadline</span>
						<div className="cases-sidebar__deadline-range">
							<Input subtitle="From" name="deadline_date_from" theme="dark" type="date" value={filterData.deadline_date_from} onChange={filterChange} />
							<img src={ICON_SEPARATOR} alt="" className="deadline-range__icon" />
							<Input subtitle="To" name="deadline_date_to" theme="dark" type="date" value={filterData.deadline_date_to} onChange={filterChange} />
						</div>
					</div>
					<TagSelect label="Service" name="service" theme="dark" options={services} values={filterData.service} onChange={filterChange} />
					<Input label="Last Updated" name="updated_on" theme="dark" type="date" value={filterData.updated_on} onChange={filterChange} />
					<TagSelect label="Region" name="region" theme="dark" options={regions} values={filterData.region} onChange={filterChange} />
					<div className="cases-sidebar_actions sidebar_actions">
						<Button theme="ghost-primary" onClick={clearForm}>
							Clear
						</Button>
						<Button onClick={filterButtonHandler} theme="primary">
							Apply Filters
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Header>
				<RoleBox roles={EXTERNAL_ROLES}>
					<CaseDirectionFilter onClick={directionChange} selectedDirection={filterData.direction} />
				</RoleBox>
				<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')]}>
					<AppLink
						title="Instruct a new case for which there is not already a pre-existing quote. If a pre-existing quote exists, please instruct from the Quotes list instead."
						className="form-button primary medium"
						to="/quotes/instruct-without-pre-existing"
					>
						Instruct New Case
					</AppLink>
				</RoleBox>
			</AppTemplate.Header>
			<AppTemplate.Content>
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="cases-list__data-table">
						{cases.length > 0 &&
							cases.map((c) => {
								let directionBadge =
									!!c.direction ?
										c.direction == CASE_DIRECTIONS.SENT ?
											ICON_ARROW_UP_ORANGE
										:	ICON_ARROW_DOWN_BLUE
									:	'';
								let refNoBadge =
									!!c.client_ref_number ? ICON_CLIENT_AZAMI
									: !!c.associate_ref_number ? ICON_ASSOCIATE_AZAMI
									: ICON_CLIENT_AZAMI;

								return (
									<tr key={c.id} onClick={goToDetails} data-cid={c.case_id}>
										<DataTable.CellWithBadge badge={directionBadge}>{c.case_number}</DataTable.CellWithBadge>
										<DataTable.PersonCell subtext={c.client_name} showIco={false}>
											{c.client_firm_name}
										</DataTable.PersonCell>
										<DataTable.PersonCell subtext={c.associate_name} showIco={false}>
											{c.associate_firm_name}
										</DataTable.PersonCell>
										<DataTable.CellWithBadge badge={refNoBadge}>{c.client_ref_number || c.associate_ref_number || c.case_client_ref_number}</DataTable.CellWithBadge>
										<DataTable.CountryCell code={c.region_code}>{c.region_name}</DataTable.CountryCell>
										<DataTable.Cell>{c.service_name}</DataTable.Cell>
										<DataTable.PersonCell subtext={c.applicant_name} showIco={false}>
											{c.application_number}
										</DataTable.PersonCell>
										<DataTable.DateCell date={c._modified} />
										<DataTable.DateCell>{c.due_date}</DataTable.DateCell>
									</tr>
								);
							})}
					</DataTable>
					<Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
				</div>
				<AppRoute route={Routes.CASE_SETTINGS} exact />
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default CasesList;
