import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//System Imports
import { useState, useRef, useEffect } from 'react';
//Component Imports
import { Checkbox } from '../Checkbox';
//Style Imports
import './style.css';
export const MultiSelect = ({ customClassName = '', label = '', theme = 'primary', options = [], values = [], onChange = () => { }, selectAllEnabled = false, name = '', error = [], tabIndex = 0, }) => {
    const ref = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [listedValues, setListedValues] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const checkBoxTheme = theme === 'primary' || theme === 'secondary' ? theme : 'primary';
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            const target = e.target;
            if (showDropdown && ref.current && !ref.current.contains(target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('mousedown', function (e) {
            checkIfClickedOutside(e);
        });
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [showDropdown]);
    useEffect(() => {
        if (options.length) {
            const vals = values.length <= 5 ? values : values.slice(0, 5);
            setListedValues(vals.map((v) => options.filter((o) => o.value === v)[0].label));
        }
    }, [values, options]);
    const handleChange = (e) => {
        if (e.target.checked) {
            if (e.target.name === 'SELECT_ALL') {
                onChange({ target: { name: name, value: options.map((o) => o.value) } });
                setSelectAll(true);
            }
            else {
                onChange({ target: { name: name, value: [...values, e.target.name] } });
            }
        }
        else {
            if (e.target.name === 'SELECT_ALL') {
                onChange({ target: { name: name, value: [] } });
                setSelectAll(false);
            }
            else {
                onChange({ target: { name: name, value: values.filter((r) => r !== e.target.name) } });
            }
        }
    };
    return (_jsxs("div", { className: `form-multiselect ${theme} ${error.length > 0 ? 'error' : ''} ${customClassName} `, ref: ref, children: [_jsx("span", { className: `label`, children: label }), _jsxs("div", { className: `input`, children: [_jsx("div", { className: `togglable`, onClick: toggleDropdown, tabIndex: tabIndex, children: _jsxs("span", { className: "title", children: [values.length ? values.length : 'None', " selected"] }) }), showDropdown && (_jsxs("div", { className: `dropdown`, onBlur: toggleDropdown, children: [_jsxs("div", { className: "selected", children: [listedValues.map((v, i) => {
                                        return (_jsx("span", { className: "item", title: v, children: v }, i));
                                    }), values.length > 5 ? _jsxs("span", { className: "rest", children: ["+ ", values.length - 5, " more"] }) : null] }), _jsxs("ul", { className: "select-list", children: [selectAllEnabled ? (_jsx("li", { children: _jsx(Checkbox, { name: "SELECT_ALL", label: "Select All", onChange: handleChange, checked: selectAll, theme: checkBoxTheme }) }, "SELECT_ALL")) : null, options.map((o, i) => {
                                        return (_jsx("li", { children: _jsx(Checkbox, { name: o.value, label: o.label, img: o.img, onChange: handleChange, checked: values.includes(o.value), theme: checkBoxTheme }) }, i));
                                    })] })] }))] }), error && _jsx("span", { className: "error-label", children: error })] }));
};
