//constants
const FEATURES_VISIBLE_ADD = 'FEATURES_VISIBLE_ADD';
const FEATURES_VISIBLE_UPDATE = 'FEATURES_VISIBLE_UPDATE';

const initialState = [];

//actions
export const featuresVisibleAdd = (data) => {
	return {
		type: FEATURES_VISIBLE_ADD,
		payload: data,
	};
};
export const featuresVisibleUpdate = (data) => {
	return {
		type: FEATURES_VISIBLE_UPDATE,
		payload: data,
	};
};

//reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FEATURES_VISIBLE_ADD:
			return [
				...state,
				{
					...action.payload,
				},
			];
		case FEATURES_VISIBLE_UPDATE:
			return state.map((s) => {
				if (s.label === action.payload.label) {
					s.value = action.payload.value;
				}
				return s;
			});
		default:
			return state;
	}
};

export default reducer;
