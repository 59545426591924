import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal } from '../Modal';
import ICON_AVATAR from '../../../src/assets/images/icons/ico_avatar_placeholder.svg';
import ICON_GEAR from '../../../src/assets/images/icons/ico_gear.svg';
import ICON_EXIT from '../../../src/assets/images/icons/ico_exit.svg';
import ICON_FIRM from '../../../src/assets/images/icons/ico_firm.svg';
import ICON_PAYMENT from '../../../src/assets/images/icons/ico_payment.svg';
import './style.css';
export const UserMenu = ({ AppLink, userMenuRef, menuVisibility = false, checkRedirectUserDetails, checkRedirectFromUserDetails, checkRedirectFromFirmProfile, checkRedirectFirmProfile, enablePayment, userFirmID, showModalLogout, image, onClickLogout, onCancelLogout, onConfirmLogout, onClickUserMenu, }) => {
    const logOutNavigationActions = [
        { primary: false, label: 'Cancel', action: onCancelLogout },
        { primary: true, label: 'Confirm', action: onConfirmLogout },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs("button", { className: "user-menu", ref: userMenuRef, children: [menuVisibility ? (_jsx("div", { children: _jsxs("div", { className: "items-container", children: [_jsx("span", { className: "arrow" }), _jsxs("ul", { className: "items", children: [_jsx(AppLink, { to: "/user-management/me", checkRedirect: checkRedirectUserDetails, checkRedirectFrom: checkRedirectFromUserDetails, className: "link", children: _jsxs("li", { children: [_jsx("img", { src: ICON_GEAR }), "User Details"] }) }), _jsx(AppLink, { to: `/firm-management/${userFirmID}`, checkRedirect: checkRedirectFirmProfile, checkRedirectFrom: checkRedirectFromFirmProfile, className: "link", children: _jsxs("li", { children: [_jsx("img", { src: ICON_FIRM }), "Firm Profile"] }) }), !!enablePayment ?
                                            _jsx(AppLink, { to: "/payments/plan-and-billing", checkRedirect: checkRedirectFirmProfile, checkRedirectFrom: checkRedirectFromFirmProfile, className: "link", children: _jsxs("li", { children: [_jsx("img", { src: ICON_PAYMENT }), "Plan & Billing"] }) })
                                            : null, _jsxs("li", { onMouseDown: (e) => e.preventDefault(), onClick: onClickLogout, children: [_jsx("img", { src: ICON_EXIT }), "Log out"] })] })] }) })) : null, _jsx("img", { src: image ? image : ICON_AVATAR, className: "avatar", alt: "avatar img", onClick: onClickUserMenu })] }), showModalLogout ? (_jsx(Modal, { closeHandler: onCancelLogout, title: "Navigation confirmation", footerActions: logOutNavigationActions, theme: "primary", children: "Are you sure you want to discard all unsaved edits?" })) : ('')] }));
};
