import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { featuresVisibleUpdate } from 'redux/ducks/feature-visible';
import useNotification from 'services/hooks/use_notification';
import Preferences from 'services/rest/preferences';
import { PREFERENCES_GROUP } from 'data/constants';
// sazzui
import { Switch, Button } from 'sazzui/lib/main';

import './style.css';

const SystemSettingsFeaturesVisible = () => {
	const dispatch = useDispatch();
	const errorMessages = {
		required: 'Required',
	};
	const [spinner, setSpinner] = useState(false);
	const [formData, setFormData] = useState([]);
	const [errors, setErrors] = useState({});
	const sendNotification = useNotification();

	const fetchData = async () => {
		try {
			let res = await Preferences.GetSystemRecordsByGroup(PREFERENCES_GROUP.FEATURES_VISIBLE);
			setFormData(res);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const switchChange = (e) => {
		const { name, checked } = e.target;

		setFormData(
			formData.map((row) => {
				if (row.preference_key === name) {
					row.preference_value = checked == true ? 1 : 0;
				}
				return row;
			}),
		);
	};

	const submitForm = async () => {
		try {
			setSpinner(true);
			Preferences.Update(formData);
			sendNotification({ type: 'success', title: 'Successfully saved Features Visible settings' });

			formData.map((row) => {
				let label = row.preference_key.replace(/_/g, ' ').toLowerCase();
				let value = row.preference_value_type == 'BOOLEAN' ? Number(row.preference_value) : row.preference_value;
				dispatch(featuresVisibleUpdate({ label, value }));
			});

			setSpinner(false);
		} catch (err) {
			console.log(err);
			sendNotification({ type: 'error', title: 'Unsuccessfully saved Features Visible settings' });
		}
	};

	return (
		<div className="main-screen-section white full-height system-details-content">
			<div className="system-settings__preferences__header">
				<span className="system-settings__preferences__header__title">Features Visible</span>
			</div>
			<div className="system-settings__preferences__body system-settings-table-wrapper">
				<form>
					{formData.map((row) => {
						let key = row.preference_key;
						let label = row.preference_key.replace(/_/g, ' ').toLowerCase();
						let value = row.preference_value_type == 'BOOLEAN' ? Number(row.preference_value) : row.preference_value;
						return <Switch label={label} customClassName="switch-list" name={key} value={value} onChange={switchChange} theme={'blurple'} error={errors[key]} />;
					})}
				</form>
				<Button onClick={submitForm} showSpinner={spinner}>
					Save
				</Button>
			</div>
		</div>
	);
};

export default SystemSettingsFeaturesVisible;
