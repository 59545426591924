import { useEffect, useCallback, useRef } from 'react';

const useKeyboardShortcut = (shortcutKeys = [], callback = () => {}, options = { disableForInput: true }) => {
	let count = useRef(0);
	const isTextField = (target) => {
		if (target) return target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement || target.isContentEditable;
	};

	const handler = useCallback(
		(e) => {
			// disable shortcut if target is text field
			const isTargetTextField = isTextField(e.target);
			if (options.disableForInput && isTargetTextField) return;

			// check if one key is pressed and being held down
			if (e.repeat) return null;

			// single key press
			if (shortcutKeys.length === 1) {
				if (shortcutKeys[0] === e.key) callback();
				return;
			}

			// multiple keys shortcut press
			if (shortcutKeys[count.current] !== e.key) return (count.current = 0);
			count.current++;
			if (shortcutKeys.length === count.current) {
				callback();
				count.current = 0;
			}
		},
		[callback, options.disableForInput, shortcutKeys],
	);

	useEffect(() => {
		window.addEventListener('keydown', handler);
		return () => window.removeEventListener('keydown', handler);
	}, [handler]);
};

export default useKeyboardShortcut;
