import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setQuoteData } from 'redux/ducks/quote';
import { setIsUpdated, REDIRECT_FROM_QUOTE_MSG } from 'redux/ducks/redirect';

import { Switch, Input, Select } from 'sazzui/lib/main';

import ChinaExtensionModal from 'com/widgets/QuoteModals/ChinaExtensionModal';

import { requiredQuoteDetailsField, calculateChinaExtensionSwitch } from 'services/validators/quotes';
import { SERVICE_KEYS, ROLE_TYPES, QUOTES_ENTITY_SIZE, WIPO_IMAGES_SIZE_THRESHOLD, QUOTES_ENTITY_SIZE_VALUES } from 'data/constants';
import Lists from 'com/pages/SystemSettingsEstimatorRules/lists';
import { checkFieldForService, checkFieldForRegion, publicationNumberLabel } from './QuoteDetailsCustomFieldsLists';

import ICON_DESCRIPTION from 'assets/images/icons/ico_image_description.svg';
import ICON_SYNC_GREEN from 'assets/images/icons/ico-sync-green.svg';
import ICON_SYNC_RED from 'assets/images/icons/ico-sync-red.svg';

import './style.css';

const Details = (props) => {
	const user = useSelector((state) => state.auth.user);
	const quote = useSelector((state) => state.quote);
	const wipo = useSelector((state) => state.wipo);
	const dispatch = useDispatch();
	const [syncIcon, setSyncIcon] = useState({ icon: null, tooltip: '' });
	const [showEntitySizeHint, setShowEntitySizeHint] = useState(false);
	const [numOfPagesInfoIcons, setNumOfPagesInfoIcons] = useState([]);
	const [chinaExtensionModal, setChinaExtensionModal] = useState(false);

	const SYNC_ICONS = {
		RED: {
			icon: ICON_SYNC_RED,
			tooltip: 'The total number of words appears to be too low.',
		},
		GREEN: {
			icon: ICON_SYNC_GREEN,
			tooltip: 'The total number of words appears to be accurate.',
		},
	};

	const wipoImagesSizeDescriptionIcon = {
		icon: ICON_DESCRIPTION,
		tooltip: 'There may be large images in the Description. Please verify word count.',
	};

	const calculateAndDisplayIcons = (data) => {
		if (user.roleType === ROLE_TYPES.INTERNAL) {
			(data.number_of_pages - data.number_of_page_drawings) * 250 > data.number_of_words ? setSyncIcon(SYNC_ICONS.RED) : setSyncIcon(SYNC_ICONS.GREEN);
		}
	};

	const getWipoImagesDescriptionSize = (wipoData) => {
		if (wipoData.description_images_size > WIPO_IMAGES_SIZE_THRESHOLD) {
			return wipoImagesSizeDescriptionIcon;
		}
		return { icon: '', tooltip: '' };
	};

	const showField = (field_name) => {
		let checkedService = checkFieldForService(quote, props.services, field_name);
		let checkedRegion = checkFieldForRegion(quote, props.regions, field_name);
		return checkedService && checkedRegion;
	};

	const fieldChange = (e) => {
		dispatch(setQuoteData({ name: e.target.name, value: e.target.value }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
		if (e.target.name === 'entity_size') {
			if (e.target.value === QUOTES_ENTITY_SIZE_VALUES.small || e.target.value === QUOTES_ENTITY_SIZE_VALUES.individual) {
				setShowEntitySizeHint(true);
			} else {
				setShowEntitySizeHint(false);
			}
		}
	};

	const deadlineRelatedDateChange = (e) => {
		dispatch(setQuoteData({ name: e.target.name, value: e.target.value }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	const handleDateOnBlur = async (e) => {
		let service = props.services.find((s) => s.id === quote.service_id);
		if (service?.key === SERVICE_KEYS.PCT) {
			let date, d;
			if (e.target.name === 'earliest_priority_date' && e.target.value) {
				date = new Date(e.target.value);
				d = e.target.value;
			} else if (e.target.name === 'earliest_priority_date' && !e.target.value && quote.intl_filing_date) {
				date = new Date(quote.intl_filing_date);
				d = e.target.value;
			} else if (!quote.earliest_priority_date) {
				date = new Date(e.target.value);
				d = e.target.value;
			}
			if (date) {
				let china_extension_fee_24_day = calculateChinaExtensionSwitch(date);
				if (china_extension_fee_24_day) {
					dispatch(setQuoteData({ name: 'china_extension_fee', value: true }));
					if (!props.newQuote) {
						setChinaExtensionModal(true);
					}
				}
				if (user.roleType === ROLE_TYPES.EXTERNAL) {
					props.validateInstructionsDeadline(new Date(d), quote.service_id, props.services, quote.priority_type);
				}
			}
		} else {
			if (user.roleType === ROLE_TYPES.EXTERNAL) {
				props.validateInstructionsDeadline(new Date(e.target.value), quote.service_id, props.services, quote.priority_type);
			}
		}
	};

	const earliestPriorityDatelabel = (service_id) => {
		let service = props.services.find((s) => s.id === service_id);
		if (service) {
			if (service.key === SERVICE_KEYS.EP || service.key === SERVICE_KEYS.UNITARY) {
				return 'Grant Date';
			}
		}
		return 'Earliest Priority Date';
	};

	const numberOfWordsLabel = (service_id) => {
		let service = props.services.find((s) => s.id == service_id);
		if (service) {
			if (service.key === SERVICE_KEYS.EP || service.key === SERVICE_KEYS.UNITARY) {
				return 'Total Number of Words Including Claims';
			}
		}
		return 'Total Number of Words';
	};

	const checkForDescription = (field_name, service_id) => {
		let service = props.services.find((s) => s.id == service_id);
		if (service && (service.key === SERVICE_KEYS.EP || service.key === SERVICE_KEYS.UNITARY)) {
			switch (field_name) {
				case 'number_of_words':
					return 'Enter the total number of words in the B1 or Text intended for grant, including claims. If word count is unknown, leave as 0. A quote will not be automatically generated, but we will send it to you within our regular turnaround time.';
				case 'number_of_words_in_claims':
					return 'If word count is unknown, leave as 0. A quote will not be automatically generated, but we will send it to you within our regular turnaround time.';
				case 'number_of_pages':
					return 'Enter the number of pages in the Text intended for grant.';
				default:
					return '';
			}
		}
	};

	const quoteDetailsRadioButtonSwtichChange = (e) => {
		dispatch(setQuoteData({ name: e.target.name, value: !quote[e.target.name] }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	useEffect(() => {
		calculateAndDisplayIcons(quote);
	}, [quote.number_of_words, quote.number_of_page_drawings, quote.number_of_pages]);

	const getLanguageCode = (language_id) => {
		if (!language_id) return '';
		let language = props.languages.find((l) => l.value === language_id);
		if (language) {
			return language.code;
		} else {
			return '';
		}
	};

	const chinaExtensionModalClose = () => {
		setChinaExtensionModal(false);
	};

	const chinaExtensionModalActions = [{ primary: true, label: 'Cancel', action: chinaExtensionModalClose, theme: 'azami-ghost' }];

	const checkClaimsDescription = (claims_count_accurate, service_id, services) => {
		let message = '';
		let service = services.find((s) => s.id === service_id);
		if (claims_count_accurate === false && (service.key === SERVICE_KEYS.PCT || service.key === SERVICE_KEYS.UTILITY)) {
			message = 'Unable to automatically retrieve number of claims and/or independent claims. Please enter values before continuing.';
		}
		return message;
	};

	return (
		<div className="quote-details__section-container">
			{/* <h4 className={`quote-details__subtitle ${props.errors.quote_details_header_error ? 'section-title-error' : ''}`}>4 Details</h4> */}
			<div className="quote-details__content-section-details-container">
				<div className="quote-details__input-column">
					<Input
						name="application_title"
						label="Title of Application"
						value={quote.application_title}
						onChange={fieldChange}
						required={requiredQuoteDetailsField(quote.service_id, props.services, 'application_title', user.roleType === ROLE_TYPES.EXTERNAL)}
						error={props.errors.application_title}
					/>
					<Input name="applicant_name" label="Applicant Name" value={quote.applicant_name} onChange={fieldChange} />
					{showField('earliest_priority_date') ?
						<Input
							type="date"
							name="earliest_priority_date"
							label={earliestPriorityDatelabel(quote.service_id)}
							value={quote.earliest_priority_date}
							// error={errors.earliest_priority_date}
							onChange={deadlineRelatedDateChange}
							previewValue={!props.newQuote && wipo.wipo_data.earliest_priority_date}
							previewToolTip={'WIPO/EPO Data'}
							onBlur={handleDateOnBlur}
						/>
					:	null}
					{showField('number_of_priorities') ?
						<Input
							type="number"
							name="number_of_priorities"
							label="Number of Priorities"
							value={quote.number_of_priorities}
							onChange={fieldChange}
							previewValue={!props.newQuote && wipo.wipo_data.number_of_priorities ? wipo.wipo_data.number_of_priorities.toString() : ''}
							previewToolTip={'WIPO/EPO Data'}
						/>
					:	null}
					{showField('intl_filing_date') ?
						<Input
							type="date"
							name="intl_filing_date"
							label="International Filing Date"
							value={quote.intl_filing_date}
							// error={errors.intl_filing_date}
							onChange={deadlineRelatedDateChange}
							previewValue={!props.newQuote && wipo.wipo_data.intl_filing_date}
							previewToolTip={'WIPO/EPO Data'}
							onBlur={handleDateOnBlur}
						/>
					:	null}
					{showField('publication_number') ?
						<Input
							name="publication_number"
							label={publicationNumberLabel(quote.service_id, props.services, SERVICE_KEYS)}
							value={quote.publication_number}
							onChange={fieldChange}
							previewValue={!props.newQuote && wipo.wipo_data.publication_number}
							previewToolTip={'WIPO/EPO Data'}
						/>
					:	null}
					{showField('chapter_two_demand') ?
						<Switch
							label="Chapter II Demand"
							name="chapter_two_demand"
							onChange={quoteDetailsRadioButtonSwtichChange}
							value={quote.chapter_two_demand}
							checked={quote.chapter_two_demand}
							ariaLabel="Chapter II Demand Switch"
						/>
					:	null}
					{showField('claiming_priority') ?
						<Switch
							label="Claiming Priority"
							name="claiming_priority"
							onChange={quoteDetailsRadioButtonSwtichChange}
							value={quote.claiming_priority}
							checked={quote.claiming_priority}
							ariaLabel="Claiming Priority Switch"
						/>
					:	null}
					{showField('china_extension_fee') ?
						<Switch
							label="China Extension Fee"
							name="china_extension_fee"
							onChange={quoteDetailsRadioButtonSwtichChange}
							value={quote.china_extension_fee}
							checked={quote.china_extension_fee}
							ariaLabel="China Extension Fee Switch"
						/>
					:	null}
				</div>
				<div className="quote-details__input-column">
					{showField('number_of_words') ?
						<Input
							type="number"
							name="number_of_words"
							label={numberOfWordsLabel(quote.service_id)}
							value={quote.number_of_words}
							onChange={fieldChange}
							previewValue={!props.newQuote && wipo.wipo_data.number_of_words ? wipo.wipo_data.number_of_words.toString() : ''}
							previewToolTip={'WIPO/EPO Data'}
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'number_of_words', user.roleType === ROLE_TYPES.EXTERNAL)}
							description={checkForDescription('number_of_words', quote.service_id)}
							error={props.errors.number_of_words}
						/>
					:	null}
					{showField('number_of_pages') ?
						<Input
							type="number"
							name="number_of_pages"
							label="Total Number of Pages"
							value={quote.number_of_pages}
							onChange={fieldChange}
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'number_of_pages', user.roleType === ROLE_TYPES.EXTERNAL)}
							previewValue={!props.newQuote && wipo.number_of_pages ? wipo.number_of_pages.toString() : ''}
							previewToolTip={'WIPO/EPO Data'}
							icons={wipo.wipo_data.description_images_size ? [syncIcon, getWipoImagesDescriptionSize(wipo.wipo_data)] : [syncIcon]}
							description={checkForDescription('number_of_pages', quote.service_id)}
							error={props.errors.number_of_pages}
						/>
					:	null}
					{showField('number_of_claims') ?
						<Input
							customClassName="quote-details__input-number-of-claims"
							type="number"
							name="number_of_claims"
							label="Number of Claims"
							value={quote.number_of_claims}
							onChange={fieldChange}
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'number_of_claims', user.roleType === ROLE_TYPES.EXTERNAL)}
							previewValue={!props.newQuote && wipo.wipo_data.number_of_claims ? wipo.wipo_data.number_of_claims.toString() : ''}
							previewToolTip={'WIPO/EPO Data'}
							error={props.errors.number_of_claims}
							description={checkClaimsDescription(wipo.wipo_data.claims_count_accurate, quote.service_id, props.services)}
						/>
					:	null}
					{showField('number_of_independent_claims') ?
						<Input
							type="number"
							name="number_of_independent_claims"
							label="Number of Independent Claims"
							value={quote.number_of_independent_claims}
							onChange={fieldChange}
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'number_of_independent_claims', user.roleType === ROLE_TYPES.EXTERNAL)}
							previewValue={!props.newQuote && wipo.wipo_data.number_of_independent_claims ? wipo.wipo_data.number_of_independent_claims.toString() : ''}
							previewToolTip={'WIPO/EPO Data'}
							error={props.errors.number_of_independent_claims}
						/>
					:	null}
					{showField('number_of_page_drawings') ?
						<Input
							type="number"
							name="number_of_page_drawings"
							label="Number of Pages of Drawings"
							value={quote.number_of_page_drawings}
							onChange={fieldChange}
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'number_of_page_drawings', user.roleType === ROLE_TYPES.EXTERNAL)}
							previewValue={!props.newQuote && wipo.wipo_data.number_of_page_drawings ? wipo.wipo_data.number_of_page_drawings.toString() : ''}
							previewToolTip={'WIPO/EPO Data'}
							error={props.errors.number_of_page_drawings}
						/>
					:	null}
					{showField('number_of_page_sequences') ?
						<Input
							type="number"
							name="number_of_page_sequences"
							label="Number of Pages of Sequences"
							value={quote.number_of_page_sequences}
							onChange={fieldChange}
							previewValue={!props.newQuote && wipo.wipo_data.number_of_page_sequences ? wipo.wipo_data.number_of_page_sequences.toString() : ''}
							previewToolTip={'WIPO/EPO Data'}
						/>
					:	null}
					{showField('number_of_embodiments') ?
						<Input type="number" name="number_of_embodiments" label="Number of Embodiments" value={quote.number_of_embodiments} onChange={fieldChange} />
					:	null}
				</div>
				<div className="quote-details__input-column">
					{showField('number_of_words_in_claims') ?
						<Input
							type="number"
							name="number_of_words_in_claims"
							label="Number of Words in Claims"
							value={quote.number_of_words_in_claims}
							onChange={fieldChange}
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'number_of_words_in_claims', user.roleType === ROLE_TYPES.EXTERNAL)}
							description={checkForDescription('number_of_words_in_claims', quote.service_id)}
							error={props.errors.number_of_words_in_claims}
						/>
					:	null}
					{showField('number_of_classes') ?
						<Input
							type="number"
							name="number_of_classes"
							label="Number of Classes Filing Into"
							value={quote.number_of_classes}
							onChange={fieldChange}
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'number_of_classes', user.roleType === ROLE_TYPES.EXTERNAL)}
							error={props.errors.number_of_classes}
						/>
					:	null}
					{showField('entity_size') ?
						<Select
							name="entity_size"
							label="Entity Size"
							value={quote.entity_size}
							onChange={fieldChange}
							options={QUOTES_ENTITY_SIZE}
							showInfoHint={showEntitySizeHint}
							infoHint="Reduced fees for small or individual entities will be applied in India and the USA only and are contingent upon meeting the requisite qualifications. Kindly ensure the applicant meets the necessary conditions before making your selection."
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'entity_size', user.roleType === ROLE_TYPES.EXTERNAL)}
							error={props.errors.entity_size}
							ariaLabel="Entity Size Select"
						/>
					:	null}
					{showField('location_of_search') ?
						<Select
							name="location_of_search"
							label="Location of Search"
							value={quote.location_of_search}
							onChange={fieldChange}
							options={Lists.calculationValueFromListValueLocationOfSearch}
							previewValue={!props.newQuote && wipo.wipo_data?.location_of_search}
							previewToolTip={'WIPO/EPO Data'}
							ariaLabel="Location of Search Select"
						/>
					:	null}
					{showField('application_language') ?
						<Select
							name="application_language"
							label="Application Language"
							value={quote.application_language}
							onChange={fieldChange}
							options={props.languages}
							previewValue={!props.newQuote && getLanguageCode(wipo.wipo_data?.application_language)}
							previewToolTip={'WIPO/EPO Data'}
							required={requiredQuoteDetailsField(quote.service_id, props.services, 'application_language', user.roleType === ROLE_TYPES.EXTERNAL)}
							error={props.errors.application_language}
							ariaLabel="Application Language Select"
						/>
					:	null}
					{showField('classification_ipc') ?
						<div className="classifications-ipc">
							<div className="form-input__label">IPC</div>
							{!!quote.classifications &&
								quote.classifications.map((c) => {
									return c.type === 'IPC' ?
											<span className="code">{`${c.section}${('0' + c.class).slice(-2)}${c.subclass} ${c.main_group}/${('0' + c.subgroup).slice(-2)}`}</span>
										:	'';
								})}
						</div>
					:	null}
				</div>
			</div>
			{chinaExtensionModal ?
				<ChinaExtensionModal footerActions={chinaExtensionModalActions} />
			:	null}
		</div>
	);
};

export default Details;
