import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
// import { random } from '../../../src/services/strings';
import ICON_PAPERCLIP from '../../assets/images/icons/ico_paperclip.svg';
import ICON_SIDEBAR_DOCS_UPLOAD from '../../assets/images/icons/ico_sidebar_title_docs_upload.svg';
import ICON_SIDEBAR_DOCU_BTN_DELETE from '../../assets/images/icons/ico_btn_delete_white.svg';
import ICON_DOCU_UPLOAD_HINT from '../../assets/images/icons/docu_upload_hint.svg';
import './style.css';
const ACCEPTED_FILE_TYPES = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'image/jpeg',
    'image/png',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const DocumentUploader = ({ title = '', files = [], setFiles = () => { }, setDuplicateFiles = () => { }, setDuplicatesModalOpen = () => { }, uploadBoxIndicators = {
    message: '',
    noFilesSelected: false,
}, setUploadBoxIndicators = () => { }, documentForUpdate = {}, editMode = false, checkFileType = false, notificationHook = (mt, msg) => {
    console.log(mt, msg);
}, }) => {
    const random = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };
    const dropContainer = useRef(null);
    const fileChange = (e) => {
        const filesArr = Array.from(e.target.files);
        const duplicates = [];
        filesArr.forEach((f) => {
            if (files.find((d) => d.name === f.name)) {
                setDuplicateFiles(duplicates);
                setDuplicatesModalOpen(true);
                duplicates.push(f);
                f.id = null;
                f.is_uploaded = false;
            }
            else {
                if (checkFileType) {
                    if (ACCEPTED_FILE_TYPES.includes(f.type)) {
                        f.id = random(8);
                        f.is_uploaded = false;
                    }
                    else {
                        notificationHook('error', 'Wrong File Type');
                        f.id = null;
                    }
                }
                else {
                    f.id = random(8);
                    f.is_uploaded = false;
                }
            }
        });
        setFiles([...files, ...filesArr.filter((f) => f.id !== null)]);
        setUploadBoxIndicators((prevState) => {
            return { ...prevState, noFilesSelected: false };
        });
        e.target.value = '';
        dropContainer.current.classList.remove('drag-enter');
    };
    const removeFile = (e) => {
        setFiles([...files.filter((f) => f.id !== e.target.closest('li').dataset.fid)]);
    };
    const onDragEnter = () => {
        dropContainer.current.classList.add('drag-enter');
    };
    const onDragLeave = () => {
        dropContainer.current.classList.remove('drag-enter');
    };
    return (_jsxs("div", { className: "document-uploader", children: [_jsxs("div", { className: "title", children: [title && _jsx("img", { src: ICON_SIDEBAR_DOCS_UPLOAD, alt: "upload" }), title] }), !editMode ? (_jsxs("div", { className: "body", children: [_jsx("div", { className: `upload-box ${uploadBoxIndicators.noFilesSelected === true ? 'empty' : ''}`, ref: dropContainer, children: _jsxs("label", { className: "label", children: [_jsx("input", { type: "file", multiple: true, className: "input", onChange: fileChange, onDragEnter: onDragEnter, onDragLeave: onDragLeave }), _jsx("img", { className: "input-icon", src: ICON_DOCU_UPLOAD_HINT, alt: "upload hint message" }), _jsx("span", { className: "input-label", children: "Upload documents" })] }) }), _jsx("div", { className: "message-container", children: _jsx("span", { children: uploadBoxIndicators.message }) }), _jsx("ul", { className: "uploaded-documents-list", children: files.length > 0 &&
                            files.map((f) => {
                                if (f.is_uploaded === true) {
                                    return null;
                                }
                                return (_jsxs("li", { "data-fid": f.id, children: [_jsx("img", { src: ICON_PAPERCLIP, className: "icon-paperclip", alt: "paperclip" }), _jsx("a", { href: "#", children: f.name }), _jsx("button", { onClick: removeFile, children: _jsx("img", { src: ICON_SIDEBAR_DOCU_BTN_DELETE, alt: "delete" }) })] }, f.id));
                            }) })] })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "message-container", children: _jsx("span", { children: uploadBoxIndicators.message }) }), _jsx("div", { className: "edit-box", children: _jsxs("div", { className: "content", children: [_jsx("img", { src: ICON_PAPERCLIP, className: "icon-paperclip", alt: "paperclip" }), _jsx("a", { href: "#", children: documentForUpdate.name })] }) })] }))] }));
};
