import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// templates
import AuthBox from 'com/templates/AuthBox';
import AuthTemplate from 'com/templates/AuthTemplate';
// sazzui
import { BackButton } from 'sazzui/lib/main';
// util
import AppLink from 'com/util/AppLink';
// services
import Auth from 'services/rest/auth';
// data
import { ACCOUNT_CONFIMATION_MESSAGES } from 'data/constants';
// styles
import './style.css';

const ConfirmAccount = () => {
	const [message, setMessage] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		Auth.ConfirmAccount(id)
			.then(() => {
				setMessage('success');
			})
			.catch((err) => {
				console.log(err);
				setMessage('fail');
			});
	}, []);

	return (
		<AuthTemplate>
			<div className="confirm-account-container">
				<AuthBox title="Account confirmation" titleColor="azami-blurple" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back to login" />}>
					{message && ACCOUNT_CONFIMATION_MESSAGES[message]}
				</AuthBox>
			</div>
		</AuthTemplate>
	);
};

export default ConfirmAccount;
