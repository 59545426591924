import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { getDomain } from 'services/strings';
// import { Redirect, Route } from 'react-router-dom';

import { AppZones, Private, Public, Shared } from './com/util/AppZones';
import { AppRoute } from './com/util/AppRoute';
import Routes from './data/routes';
import { ROLE_TYPES, AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY, PREFERENCES_GROUP } from 'data/constants';
import Auth from 'services/rest/auth';
import FirmAgents from 'services/rest/firm_agents';
import JWT from 'services/jwt';
import Preferences from 'services/rest/preferences';
import { setLoggedIn, setUserData } from 'redux/ducks/auth';
import { setBrandingAzami, setBrandingIpeer } from 'redux/ducks/branding';
import { featuresVisibleAdd } from 'redux/ducks/feature-visible';

// import './assets/styles/global.css'; // THIS NEEDS TO STAY COMMENTED UNTIL WE ARE FINISHED WITH THE APPLIATION SPLIT

const App = () => {
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const location = useLocation();
	const domain = getDomain();

	const validateAndSetUser = async () => {
		const token = localStorage.getItem('token');
		if (token) {
			try {
				const res = await Auth.ValidateToken();
				if (res === token) {
					const user = JWT.parseJWT(res);
					if (user.roleType === ROLE_TYPES.EXTERNAL) {
						const refreshedToken = await Auth.RefreshToken();
						localStorage.setItem('token', refreshedToken);
					}
					dispatch(setUserData(user));
					dispatch(setLoggedIn(true));

					if (user.role.includes('saas_user')) {
						let agentAssociations = await FirmAgents.GetAssociations(user.fid);
						localStorage.setItem(AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY, JSON.stringify({ agentOfFirms: agentAssociations.firm_clients, clientOfFirms: agentAssociations.firm_agents }));
					} else {
						localStorage.setItem(AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY, JSON.stringify({ agentOfFirms: [], clientOfFirms: [] }));
					}
				}
				setShow(true);
			} catch (err) {
				console.log(err);
				setShow(true);
			}
		} else {
			setShow(true);
		}
	};

	useEffect(() => {
		(async () => {
			await validateAndSetUser();
		})();
	}, [setUserData, setLoggedIn]);

	useEffect(() => {
		(async () => {
			if (auth.loggedIn) {
				Userpilot.identify(auth.user.uid, {
					name: auth.user.name,
					email: auth.user.email,
					role: auth.user.role,
				});
				if (auth.user.role.includes('saas_user')) {
					dispatch(setBrandingIpeer());
				} else {
					dispatch(setBrandingAzami());
				}

				try {
					let res = await Preferences.GetSystemRecordsByGroup(PREFERENCES_GROUP.FEATURES_VISIBLE);
					res.forEach((row) => {
						let label = row.preference_key.replace(/_/g, ' ').toLowerCase();
						let value = row.preference_value_type == 'BOOLEAN' ? Number(row.preference_value) : row.preference_value;
						dispatch(featuresVisibleAdd({ label, value }));
					});
				} catch (err) {
					console.log(err);
				}
			} else if (!auth.loggedIn) {
				Userpilot.anonymous();
			}
		})();
	}, [auth.loggedIn]);

	useEffect(() => {
		Userpilot.reload();
	}, [location]);

	return (
		show && (
			<AppZones>
				<Public>
					<AppRoute route={Routes.ROOT} exact />
					<AppRoute route={Routes.REGISTER} exact />
					<AppRoute route={Routes.REGISTER_THANK_YOU} />
					<AppRoute route={Routes.FORGOT_PASSWORD} exact />
					<AppRoute route={Routes.FORGOT_PASSWORD_MESSAGE} />
					<AppRoute route={Routes.CHANGE_PASSWORD_MESSAGE} />
					<AppRoute route={Routes.CHANGE_PASSWORD} />
					<AppRoute route={Routes.CONFIRM_ACCOUNT} />
					<AppRoute route={Routes.LEGAL_INFO} />
					<AppRoute route={Routes.WILDCARD} />
				</Public>
				<Private>
					<AppRoute route={Routes.DASHBOARD} exact />
					<AppRoute route={Routes.REPORTS} exact />
					<AppRoute route={Routes.REPORTS_CLIENT} />
					<AppRoute route={Routes.REPORTS_REGIONAL} />
					<AppRoute route={Routes.REPORTS_CASE} />
					<AppRoute route={Routes.REPORTS_TOP_10} />
					<AppRoute route={Routes.REPORTS_OWED_ASSOCIATES} />
					<AppRoute route={Routes.ASSOCIATE_ASSIGNMENT} />
					<AppRoute route={Routes.USER_MANAGEMENT_LIST} exact />
					<AppRoute route={Routes.USER_ADD} />
					<AppRoute route={Routes.USER_DETAILS} />
					<AppRoute route={Routes.FIRM_MANAGEMENT_LIST} exact />
					<AppRoute route={Routes.FIRM_ADD} exact />
					<AppRoute route={Routes.FIRM_DETAILS} />
					<AppRoute route={Routes.AGENTS} />
					<AppRoute route={Routes.TOOLS} exact />
					<AppRoute route={Routes.ASSIGNMENT_OVERRIDE_REQUESTS} />
					<AppRoute route={Routes.TRANSLATION_REQUESTS} />
					<AppRoute route={Routes.DUPLICATE_MANAGEMENT} />
					{/* COMMENTING OUT BECAUSE IT IS NOT READY FOR PRODUCTION */}
					{/* <AppRoute route={Routes.CLIENT_ONBOARDING} /> */}
					<AppRoute route={Routes.SYSTEM_SETTINGS} />
					<AppRoute route={Routes.QUOTES_ADD} exact />
					<AppRoute route={Routes.QUOTES_FINISH_DRAFT_QUOTE} exact />
					<AppRoute route={Routes.QUOTES_FINISH_DRAFT_INSTRUCT_WITHOUT_PRE_EXISTING} exact />
					<AppRoute route={Routes.QUOTES_INSTRUCT_WITHOUT_PRE_EXISTING} exact />
					<AppRoute route={Routes.QUOTES_DETAILS} />
					<AppRoute route={Routes.QUOTES_LIST} />
					<AppRoute route={Routes.CASES} />
					<AppRoute route={Routes.CASE_DETAILS} />
					<AppRoute route={Routes.QUOTES_LIST} exact />
					<AppRoute route={Routes.RENEWALS_VALIDATION_RULES} />
					<AppRoute route={Routes.RENEWALS_TRANSFORMATION_RULES} />
					<AppRoute route={Routes.LEGAL_INFO} />
					<AppRoute route={Routes.PRIVATE_WILDCARD} />
				</Private>
			</AppZones>
		)
	);
};

export default App;
