import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Settings from 'services/rest/settings';
import Reciprocity from 'services/rest/reciprocity';
import FirmAccounts from 'services/rest/firm_accounts';

import { DataTable, Button, Modal, Input, Select, RadioButton, LoaderOverlay, AppDate } from 'sazzui/lib/main';

import AppLink from 'com/util/AppLink';

import { RoleBox } from 'com/util/RoleBox';

import { random } from 'services/strings';
import { RECIPROCITY_OVERRIDE_TYPE, RECIPROCITY_OVERRIDE_DIRECTION } from 'data/constants';

import ICON_AVATAR_SOLID from 'assets/images/icons/avatar_solid.svg';
import ICON_CALENDAR from 'assets/images/icons/ico_calendar.svg';
import ARROW_ANGLE_DOWN_DARKBLUE from 'assets/images/icons/ico_angle_arrow_down_darkblue.svg';
import ARROW_ANGLE_UP_WHITE from 'assets/images/icons/ico_angle_arrow_white.svg';
import ICON_AVATAR from 'assets/images/icons/avatar_placeholder_invert.svg';

const ReasonType = {
	ADJUSTMENT: 'ADJUSTMENT',
	FIRM_ACCOUNT: 'FIRM_ACCOUNT',
};

const POINTS_TRANSACTION_TYPE = {
	CASE: 'CASE',
	ACCOUNT: 'ACCOUNT',
};

const FirmDetailsAccounts = (props) => {
	const errorMessages = {
		required: 'Required',
		userAlreadyAssigned: '{USER_NAME} is Assigned to {ACCOUNT_NAME} as a Default Associate.',
		defaultAssocRequired: 'Default Associate User is Required.',
		selectedReason: 'Required',
		customReason: 'Required',
		points: 'Required',
	};
	const errorsInit = {
		accountName: '',
		accountUsers: '',
		accountDefaultAssoc: '',
		selectedReason: '',
		customReason: '',
		points: '',
	};

	const params = useParams();
	const user = useSelector((state) => state.auth.user);

	const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
	const [showEditAccountModal, setShowEditAccountModal] = useState(false);
	const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
	const [showAddPointsModal, setShowAddPointsModal] = useState(false);
	const [accountName, setAccountName] = useState('');
	const [accountUsers, setAccountUsers] = useState([]);
	const [accountDefaultAssoc, setAccountDefaultAssoc] = useState('');
	const [usersSelectOptions, setUsersSelectOptions] = useState([{ label: '', value: '' }]);
	const [selectedUser, setSelectedUser] = useState('');
	const [editingAccount, setEditingAccount] = useState(null);
	const [deletingAccount, setDeletingAccount] = useState(null);
	const [accountAddingPoints, setAccountAddingPoints] = useState(null);
	const [reasons, setReasons] = useState([]);
	const [selectedReason, setSelectedReason] = useState(null);
	const [customReason, setCustomReason] = useState(null);
	const [points, setPoints] = useState(null);
	const [errors, setErrors] = useState(errorsInit);
	const [showLoader, setShowLoader] = useState(false);
	const [accountsRatios, setAccountsRatios] = useState({});

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, []);

	useEffect(() => {
		fetchUsers();
	}, [props.data.members]);

	useEffect(() => {
		generateRatiosData();
	}, [props.data.accounts, props.data.regions]);

	const fetchData = async () => {
		try {
			setShowLoader(true);
			await Promise.all([Settings.GetPointsTransactionReasons({ type: ReasonType.FIRM_ACCOUNT })]).then(async (res) => {
				setReasons([{ value: '', label: 'Select Reason' }, ...res[0].map((reason) => ({ value: reason.id, label: reason.name })), { value: 'custom', label: 'Custom' }]);
				setShowLoader(false);
			});
		} catch (err) {
			setShowLoader(false);
			console.log(err);
		}
	};

	const fetchUsers = () => {
		try {
			if (!props.data.members.length) return;

			setUsersSelectOptions((prevState) => [
				...prevState,
				...props.data.members
					.map((u) => {
						return !!(u.first_name && u.last_name) ?
								{
									...u,
									label: `${u.first_name} ${u.last_name}`,
									name: `${u.first_name} ${u.last_name}`,
									value: u.id,
								}
							:	false;
					})
					.filter(Boolean),
			]);
		} catch (err) {
			console.log(err);
		}
	};

	const generateRatiosData = async () => {
		if (!props.data.accounts?.length || !props.data.regions?.length) return;
		try {
			setShowLoader(true);
			let accountsIds = props.data.accounts.map((ac) => ac.id);
			let accountsBalance = {};
			let [services, firmRules, systemRules, ...accounts] = await Promise.all([
				Settings.GetServices(),
				Reciprocity.GetAllReciprocityOverridesByFirm(params.id),
				Reciprocity.GetAllSystemReciprocityOverrides(),
				...accountsIds.map((acid) => FirmAccounts.GetOneByID(params.id, acid)),
			]);
			let rules = [...firmRules, ...systemRules];
			accounts.forEach((acc) => {
				if (acc.id) accountsBalance[acc.id] = acc?.points || 0;
			});
			// generate unique list of regions from firm's licensed regions and rules regions
			let regionsFilter = [...new Set([...props.data.regions.map((fr) => fr.region_id), ...rules.map((rr) => rr.region_id)])];
			// fetch system points and build easily accessible points map per region per service
			let systemPoints = await Settings.GetSystemPoints({ regions: regionsFilter.join(',') });
			let systemPointsMap = new Map();
			for (let sp of systemPoints) {
				systemPointsMap.set(`${sp.region_id}__${sp.service_id}`, { base_points: sp.base_points, reward_points: sp.reward_points });
			}
			let data = {};
			for (let ac of props.data.accounts) {
				data[ac.id] = { base_points: [], reward_points: [], balance: accountsBalance[ac.id] };
				// populate base points table
				for (let fr of props.data.regions) {
					let regionData = { region_id: fr.region_id, region_code: fr.region_code, region_name: fr.region_name, points: [] };
					services.forEach((svc) => {
						let spoints = systemPointsMap.get(`${regionData.region_id}__${svc.id}`);
						let override = checkForOverrides(
							rules.filter(
								(r) =>
									r.direction === RECIPROCITY_OVERRIDE_DIRECTION.INCOMING &&
									(r.object_type === RECIPROCITY_OVERRIDE_TYPE.SYSTEM ||
										r.object_type === RECIPROCITY_OVERRIDE_TYPE.FIRM ||
										(r.object_type === RECIPROCITY_OVERRIDE_TYPE.ACCOUNT && r.object_id === ac.id)) &&
									(r.region_id === regionData.region_id || r.region_id === '') &&
									r.service_id === svc.id,
							),
						);
						let finalPoints = spoints?.base_points ? Number(spoints.base_points) : 0;
						if (override) finalPoints = override.amount;
						let svcPoints = { service_name: svc.name, points: finalPoints, type: 'base', overriden: override ? true : false };
						regionData.points.push(svcPoints);
					});
					data[ac.id].base_points.push(regionData);
				}
				// populate reward points table
				let outgoingRules = rules.filter((r) => r.direction === RECIPROCITY_OVERRIDE_DIRECTION.OUTGOING);
				let reward_points = outgoingRules.map((or) => ({ region_id: or.region_id, region_code: or.region_code, region_name: or.region_name, points: [], has_overrides: false }));
				for (let rp of reward_points) {
					services.forEach((svc) => {
						let spoints = systemPointsMap.get(`${rp.region_id}__${svc.id}`);
						let override = checkForOverrides(
							rules.filter(
								(r) =>
									r.direction === RECIPROCITY_OVERRIDE_DIRECTION.OUTGOING &&
									(r.object_type === RECIPROCITY_OVERRIDE_TYPE.SYSTEM ||
										r.object_type === RECIPROCITY_OVERRIDE_TYPE.FIRM ||
										(r.object_type === RECIPROCITY_OVERRIDE_TYPE.ACCOUNT && r.object_id === ac.id)) &&
									(r.region_id === rp.region_id || r.region_id === '') &&
									r.service_id === svc.id,
							),
						);
						let finalPoints = spoints?.reward_points || 0;
						if (override) {
							finalPoints = override.amount;
							rp.has_overrides = true;
						}
						let svcPoints = { service_name: svc.name, points: finalPoints, type: 'reward', overriden: override ? true : false };
						rp.points.push(svcPoints);
					});
				}
				data[ac.id].reward_points = reward_points.filter((rp) => rp.has_overrides);
			}
			setAccountsRatios(data);
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	const checkForOverrides = (rules) => {
		let firmRules = rules.filter((r) => r.object_type === RECIPROCITY_OVERRIDE_TYPE.FIRM);
		let accountRules = rules.filter((r) => r.object_type === RECIPROCITY_OVERRIDE_TYPE.ACCOUNT);
		let systemRules = rules.filter((r) => r.object_type === RECIPROCITY_OVERRIDE_TYPE.SYSTEM);
		let today = new Date();
		for (let rules of [accountRules, firmRules, systemRules]) {
			if (!rules || !rules.length) continue;
			for (let r of rules) {
				let start = new Date(r.start_date);
				let end = r.end_date ? new Date(r.end_date) : null;
				if ((start <= today && end === null) || (start <= today && end >= today)) {
					return r;
				}
			}
		}
		return null;
	};

	const openCreateAccountModal = () => {
		setAccountName('');
		setAccountUsers([]);
		setSelectedUser('');
		setAccountDefaultAssoc('');
		setErrors(errorsInit);
		setShowCreateAccountModal(true);
	};

	const closeCreateAccountModal = () => {
		setShowCreateAccountModal(false);
	};

	const openEditAccountModal = (e) => {
		let { accountid } = e.target.dataset;
		let account = props.data.accounts.find((a) => a.id == accountid);
		setEditingAccount(account);
		setAccountName(account.name);
		setAccountUsers(account.users);
		setSelectedUser('');
		let defaultUser = account.users.find((u) => !!u.is_default);
		setAccountDefaultAssoc(defaultUser && defaultUser.id);
		setErrors(errorsInit);
		setShowEditAccountModal(true);
	};

	const closeEditAccountModal = () => {
		setShowEditAccountModal(false);
	};

	const openDeleteAccountModal = (e) => {
		let { accountid } = e.target.dataset;
		let account = props.data.accounts.find((a) => a.id == accountid);
		setDeletingAccount(account);
		setShowDeleteAccountModal(true);
	};

	const closeDeleteAccountModal = () => {
		setShowDeleteAccountModal(false);
	};

	const openAddPointsModal = (e) => {
		let { accountid } = e.target.dataset;
		let account = props.data.accounts.find((a) => a.id == accountid);
		setAccountAddingPoints(account);
		setSelectedReason('');
		setCustomReason('');
		setPoints('');
		setErrors(errorsInit);
		setShowAddPointsModal(true);
	};

	const closeAddPointsModal = () => {
		setShowAddPointsModal(false);
	};

	const handleAccountNameChange = (e) => {
		setErrors((prevState) => ({ ...prevState, accountName: errorsInit.accountName }));
		let { value } = e.target;
		setAccountName(value);
	};

	const handleAddUser = (e) => {
		setErrors((prevState) => ({ ...prevState, accountUsers: errorsInit.accountUsers, accountDefaultAssoc: errorsInit.accountDefaultAssoc }));
		let { value: selectedUserID } = e.target;
		if (accountUsers.find((u) => u.id == selectedUserID)) {
			return;
		}

		let user = usersSelectOptions.find((u) => u.id == selectedUserID);
		// check whether this user is already assigned to another account as a default associate and that account has more than one user
		let account = props.data.accounts.find((a) => a.users.length > 1 && a.users.find((u) => u.id === selectedUserID && !!u.is_default));
		if (!!account) {
			let errorMessage = errorMessages.userAlreadyAssigned.replace('{USER_NAME}', user.name).replace('{ACCOUNT_NAME}', account.name);
			setErrors((prevState) => ({ ...prevState, accountDefaultAssoc: errorMessage }));
			return;
		}
		setAccountUsers([...accountUsers, user]);
	};

	const handleDefaultAssoc = (e) => {
		setErrors((prevState) => ({ ...prevState, accountDefaultAssoc: errorsInit.accountDefaultAssoc }));
		let { value: selectedUserID } = e.target;
		setAccountUsers(
			accountUsers.map((u) => {
				let user = {
					...u,
					is_default: u.id == selectedUserID ? u.id : null,
				};
				return user;
			}),
		);
		setAccountDefaultAssoc(selectedUserID);
	};

	const handleReasonChange = (e) => {
		setErrors((prevState) => ({ ...prevState, selectedReason: errorsInit.selectedReason }));
		let { value } = e.target;
		setSelectedReason(value);
	};

	const handleCustomReasonChange = (e) => {
		setErrors((prevState) => ({ ...prevState, selectedReason: errorsInit.customReason }));
		let { value } = e.target;
		setCustomReason(value);
	};

	const handlePointsChange = (e) => {
		setErrors((prevState) => ({ ...prevState, selectedReason: errorsInit.points }));
		let { value } = e.target;
		setPoints(value);
	};

	const validate = (action) => {
		let isValid = true;
		setErrors(errorsInit);

		if (['create', 'edit'].includes(action)) {
			if (!accountName) {
				isValid = false;
				setErrors((prevState) => ({ ...prevState, accountName: errorMessages.required }));
			}
			if (!accountUsers.length) {
				isValid = false;
				setErrors((prevState) => ({ ...prevState, accountUsers: errorMessages.required }));
			}
			if (!accountDefaultAssoc) {
				isValid = false;
				setErrors((prevState) => ({ ...prevState, accountDefaultAssoc: errorMessages.defaultAssocRequired }));
			}
		} else if (action === 'add-points') {
			if (!selectedReason) {
				isValid = false;
				setErrors((prevState) => ({ ...prevState, selectedReason: errorMessages.selectedReason }));
			}
			if (selectedReason == 'custom' && !customReason) {
				isValid = false;
				setErrors((prevState) => ({ ...prevState, customReason: errorMessages.customReason }));
			}
			if (!points) {
				isValid = false;
				setErrors((prevState) => ({ ...prevState, points: errorMessages.points }));
			}
		}

		return isValid;
	};

	const handleCreateAccount = () => {
		if (!validate('create')) {
			return false;
		}

		let newAccount = {
			id: random(8),
			firm_id: props.data.id,
			name: accountName,
			users: accountUsers,
			default_associate_id: accountDefaultAssoc,
			action: 'created',
		};
		let accountUsersIDs = accountUsers.map((au) => au.id);
		let data = props.data.accounts.map((a) => {
			if (a.users.some((u) => accountUsersIDs.includes(u.id))) {
				let acData = {
					...a,
					users: a.users.filter((u) => !accountUsersIDs.includes(u.id)),
				};
				if (!a.action) {
					acData['action'] = 'edited';
				}
				return acData;
			}
			return a;
		});

		data = [...data, newAccount];
		props.onChangeAccounts(data);
		closeCreateAccountModal();
	};

	const handleEditAccount = () => {
		if (!validate('edit')) {
			return false;
		}
		let accountUsersIDs = accountUsers.map((au) => au.id);
		let data = props.data.accounts.map((a) => {
			if (a.id == editingAccount.id) {
				let acData = {
					...a,
					name: accountName,
					users: accountUsers,
					default_associate_id: accountDefaultAssoc,
				};
				if (!editingAccount.action || editingAccount.action != 'created') {
					acData['action'] = 'edited';
				}
				return acData;
			} else if (a.users.some((u) => accountUsersIDs.includes(u.id))) {
				let acData = {
					...a,
					users: a.users.filter((u) => !accountUsersIDs.includes(u.id)),
				};
				if (!a.action) {
					acData['action'] = 'edited';
				}
				return acData;
			}
			return a;
		});
		props.onChangeAccounts(data);
		closeEditAccountModal();
	};

	const handleDeleteAccount = () => {
		let data;

		if (!!deletingAccount.action && deletingAccount.action == 'created') {
			data = props.data.accounts.filter((a) => a.id != deletingAccount.id);
		} else {
			data = props.data.accounts.map((a) => {
				if (a.id == deletingAccount.id) {
					let account = {
						...a,
						action: 'deleted',
						users: [],
					};
					return account;
				}
				return a;
			});
		}

		// move users from the deleted account to the default account
		data = data.map((a) => {
			if (!!a.is_default_account) {
				let account = {
					...a,
					action: 'edited',
					users: [
						...a.users,
						...deletingAccount.users.map((u) => {
							// check if the default account has users
							// if yes, we will preserve the default associate from the default account
							// if no, we will keep the default associate from the account being deleted
							if (a.users.length) return { ...u, is_default: null };
							return u;
						}),
					],
				};
				return account;
			}
			return a;
		});
		props.onChangeAccounts(data);
		closeDeleteAccountModal();
	};

	const handleAddPoints = () => {
		if (!validate('add-points')) {
			return false;
		}

		let newPointsTransaction = {
			id: random(8),
			object_type: POINTS_TRANSACTION_TYPE.ACCOUNT,
			object_id: accountAddingPoints.id,
			amount: points,
			reason_id: selectedReason,
			custom_reason: customReason,
			internal_user_id: user.uid,
		};
		props.onAccountAddPoints(newPointsTransaction);
		closeAddPointsModal();
	};

	const createAccountModalFooterActions = [
		{ primary: false, label: 'Close', action: closeCreateAccountModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Create', action: handleCreateAccount, theme: 'azami-blue' },
	];

	const editAccountModalFooterActions = [
		{ primary: false, label: 'Close', action: closeEditAccountModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Apply', action: handleEditAccount, theme: 'azami-blue' },
	];

	const deleteAccountModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteAccountModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: handleDeleteAccount, theme: 'azami-blue' },
	];

	const addPointsModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeAddPointsModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Add', action: handleAddPoints, theme: 'azami-blue' },
	];

	return (
		<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager']}>
			<div className="firm-details-accounts">
				<LoaderOverlay showLoader={showLoader} />
				<div className="firm-details-accounts__section-header">
					<RoleBox roles={['admin', 'sales', 'sales-manager']}>
						<span className="firm-details-accounts__section-header__title">List of reciprocity accounts</span>
						<RoleBox roles={['admin', 'sales-manager']}>
							<Button onClick={openCreateAccountModal} ariaLabel="Create Account" theme="special">
								Create Account
							</Button>
						</RoleBox>
					</RoleBox>
				</div>
				<div className="firm-details-accounts__section-body">
					{props.data.accounts &&
						props.data.accounts.map((account) => (
							<FirmAccountCollapsible
								key={account.id}
								account={account}
								ratios={accountsRatios[account.id]}
								openAddPointsModal={openAddPointsModal}
								openEditAccountModal={openEditAccountModal}
								openDeleteAccountModal={openDeleteAccountModal}
								id={params.id}
							/>
						))}
					{showCreateAccountModal && (
						<Modal title="Create Account" closeHandler={closeCreateAccountModal} footerActions={createAccountModalFooterActions}>
							<CreateAccountModal
								users={usersSelectOptions}
								accountName={accountName}
								accountUsers={accountUsers}
								selectedUser={selectedUser}
								errors={errors}
								handleAccountNameChange={handleAccountNameChange}
								handleAddUser={handleAddUser}
								handleDefaultAssoc={handleDefaultAssoc}
							/>
						</Modal>
					)}
					{showEditAccountModal && (
						<Modal title="Edit Account" closeHandler={closeEditAccountModal} footerActions={editAccountModalFooterActions}>
							<CreateAccountModal
								users={usersSelectOptions}
								accountName={accountName}
								accountUsers={accountUsers}
								selectedUser={selectedUser}
								errors={errors}
								handleAccountNameChange={handleAccountNameChange}
								handleAddUser={handleAddUser}
								handleDefaultAssoc={handleDefaultAssoc}
							/>
						</Modal>
					)}
					{showDeleteAccountModal && (
						<Modal title="Delete Account" closeHandler={closeDeleteAccountModal} footerActions={deleteAccountModalFooterActions}>
							Are you sure you want to delete <strong>{deletingAccount.name}</strong>? All users of this account will be reassigned to the default reciprocity account.
						</Modal>
					)}
					{showAddPointsModal && (
						<Modal title="Add Points" closeHandler={closeAddPointsModal} footerActions={addPointsModalFooterActions}>
							<AddPointsModal
								reasons={reasons}
								selectedReason={selectedReason}
								customReason={customReason}
								points={points}
								errors={errors}
								handleReasonChange={handleReasonChange}
								handleCustomReasonChange={handleCustomReasonChange}
								handlePointsChange={handlePointsChange}
							/>
						</Modal>
					)}
				</div>
			</div>
		</RoleBox>
	);
};

const FirmAccountCollapsible = (props) => {
	const ratiosLicencedRegions = [
		{ title: 'Region', field: 'region', type: 'string', sortable: false },
		{ title: 'Base Points', field: 'points', type: 'string', sortable: false },
	];
	const ratiosOtherRegions = [
		{ title: 'Region', field: 'region', type: 'string', sortable: false },
		{ title: 'Reward Points', field: 'points', type: 'string', sortable: false },
	];
	const [active, setActive] = useState(false);
	const [arrow, setArrow] = useState(ARROW_ANGLE_DOWN_DARKBLUE);

	useEffect(() => {
		setArrow(active ? ARROW_ANGLE_UP_WHITE : ARROW_ANGLE_DOWN_DARKBLUE);
	}, [active]);

	const showHide = () => {
		setActive(!active);
	};

	return (
		<div className={`firm-account-collapsible ${active ? 'active' : ''}`}>
			<div className="firm-account-collapsible__header" onClick={showHide}>
				<div className="firm-account-collapsible__header__title">
					<img src={arrow} />
					<span className="firm-account-collapsible__header__name">{props.account.name}</span>
					<span className="firm-account-collapsible__header__users-count">{props.account?.users?.length}</span>
				</div>
				<RoleBox roles={['admin', 'sales-manager']}>
					<div className="firm-account-collapsible__header__actions">
						<FirmAccountActionButton class="firm-account-collapsible__action-points-btn" onClick={props.openAddPointsModal} accountId={props.account.id} title="Add Points" />
						<FirmAccountActionButton class="firm-account-collapsible__action-edit-btn" onClick={props.openEditAccountModal} accountId={props.account.id} title="Edit" />
						{!props.account.is_default_account && (
							<FirmAccountActionButton class="firm-account-collapsible__action-delete-btn" onClick={props.openDeleteAccountModal} accountId={props.account.id} title="Delete" />
						)}
					</div>
				</RoleBox>
			</div>
			{active ?
				<div className="firm-account-collapsible__body">
					<div className="account-users-section">
						<div className="account-users-section__header">
							<span className="title">Users</span>
							<div>
								<span>Created On: </span>
								<img src={ICON_CALENDAR} />
								<span>
									{props.account._created ?
										<AppDate>{props.account._created}</AppDate>
									:	<AppDate>{new Date()}</AppDate>}
								</span>
							</div>
						</div>
						<div className="account-users-section__list">
							{props.account.users.map((user, i) => {
								let theme = !!user.is_default ? 'dark' : 'light';
								return (
									<div key={i} className={`account-users-section__list-item ${theme}`}>
										<picture className="avatar">
											<source srcSet="" />
											<img src={ICON_AVATAR} alt="" />
										</picture>
										<span key={i}>{user.name}</span>
									</div>
								);
							})}
						</div>
						<div className="account-users-section__adjustments">
							<AppLink
								type="regular"
								to={`/firm-management/${props.id}/points?account_id=${props.account.id}&type=${POINTS_TRANSACTION_TYPE.ACCOUNT}`}
								className="form-button primary medium special"
								target="_blank"
							>
								See all Adjustments
							</AppLink>
						</div>
					</div>
					<div className="account-ratios-section">
						<div className="account-ratios-section__header">
							<span className="title">Ratios</span>
							<div>
								<img src={ICON_CALENDAR} />
								<span>
									<AppDate>{new Date()}</AppDate>
								</span>
							</div>
						</div>
						<div className="account-ratios-section__base-points">
							<div className="account-ratios-section__base-points__header">
								<span className="title">Regions where the firm is licensed to file</span>
								<span>Account Balance: {props.ratios?.balance}</span>
							</div>
							<DataTable fixedHeader={true} columns={ratiosLicencedRegions}>
								{props.ratios?.base_points.map((bp, key) => (
									<tr key={key}>
										<DataTable.CountryCell code={bp.region_code}>{bp.region_name}</DataTable.CountryCell>
										<DataTable.RatiosCell ratioData={bp.points} />
									</tr>
								))}
							</DataTable>
						</div>
						<div className="account-ratios-section__reward-points">
							<span>Other regions</span>
							<DataTable fixedHeader={true} columns={ratiosOtherRegions}>
								{props.ratios?.reward_points.map((rp, key) => (
									<tr key={key}>
										<DataTable.CountryCell code={rp.region_code || 'ALL'}>{rp.region_name || 'ALL'}</DataTable.CountryCell>
										<DataTable.RatiosCell ratioData={rp.points} />
									</tr>
								))}
							</DataTable>
						</div>
					</div>
				</div>
			:	null}
		</div>
	);
};

const FirmAccountActionButton = (props) => {
	const preventDefault = (e) => {
		e.preventDefault();
		e.stopPropagation();
		props.onClick(e);
	};

	return <button className={props.class} onClick={preventDefault} data-accountid={props.accountId} title={props.title} />;
};

const CreateAccountModal = (props) => {
	const tableHeaders = [
		{ title: 'Users', field: 'users', type: 'string', sortable: false },
		{ title: 'Default Assoc.', field: 'is_default_account', type: 'boolean', sortable: false },
	];

	return (
		<>
			<Input name="name" label="Account Name" onChange={props.handleAccountNameChange} value={props.accountName} error={props.errors.accountName} ariaLabel="Account name Input" />
			<Select name="users" options={props.users} label="Users" onChange={props.handleAddUser} value={props.selectedUser} error={props.errors.accountUsers} ariaLabel="Users Select" />

			<div className="account-users-table-errors">{!!props.errors.accountDefaultAssoc && props.errors.accountDefaultAssoc}</div>
			<div className="account-users">
				<DataTable fixedHeader={true} columns={tableHeaders}>
					{props.accountUsers &&
						props.accountUsers.map((u, key) => (
							<tr key={key}>
								<DataTable.PersonCell subtext={u.email} ico={ICON_AVATAR_SOLID} theme="solid">
									{u.label || u.name}
								</DataTable.PersonCell>
								<DataTable.Cell>
									<RadioButton
										customClassName="account-users__custom-radio-btn"
										value={u.id}
										checked={!!u.is_default}
										onChange={props.handleDefaultAssoc}
										name="default_assoc"
										ariaLabel="Default Associate Radio Button"
									/>
								</DataTable.Cell>
							</tr>
						))}
				</DataTable>
			</div>
		</>
	);
};

const AddPointsModal = (props) => {
	return (
		<div>
			<Select
				name="selected_reason"
				options={props.reasons}
				label="Reason"
				onChange={props.handleReasonChange}
				value={props.selectedReason}
				error={props.errors.selectedReason}
				ariaLabe="Reason Select"
			/>
			{props.selectedReason == 'custom' && (
				<Input
					name="custom_reason"
					label="Set Custom Reason"
					onChange={props.handleCustomReasonChange}
					value={props.customReason}
					error={props.errors.customReason}
					ariaLabel="Custom reason Input"
				/>
			)}
			<Input name="points" label="Value" type="number" onChange={props.handlePointsChange} value={props.points} error={props.errors.points} ariaLabel="Points Input" />
		</div>
	);
};

export default FirmDetailsAccounts;
