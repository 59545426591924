import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { DataTable, Tooltip, Modal, Input, Textarea } from 'sazzui/lib/main';

import AppLink from 'com/util/AppLink';

import ICON_STATUS_CHECKED from 'assets/images/icons/ico_status_checked.svg';
import ICON_STATUS_UNCHECKED from 'assets/images/icons/ico_status_unchecked.svg';
import ICON_STATUS_PENDING from 'assets/images/icons/ico_status_pending.svg';
import ICON_APPROVE_ACTION from 'assets/images/icons/ico_checked.svg';
import ICON_DECLINE_ACTION from 'assets/images/icons/ico_unchecked.svg';
import ICON_EDIT_ACTION from 'assets/images/icons/ico_btn_edit_lightgrey.svg';
import ICON_DELETE_ACTION from 'assets/images/icons/ico_btn_delete_lightgrey.svg';

const AssignmentOverrideRequestsMain = (props) => {
	const user = useSelector((state) => state.auth.user);

	const tableHeadersInit = [
		{ title: 'Priority', field: 'priority_id', type: 'string', sort: 'none', sortable: true },
		{ title: 'Subm. By.', field: 'requested_by', type: 'string', sort: 'none', sortable: true },
		{ title: 'Firm', field: 'user_id', type: 'string', sort: 'none', sortable: false },
		{ title: 'Cases', field: 'cases', type: 'string', sort: 'none', sortable: false },
		{ title: 'Region', field: 'region_id', type: 'string', sort: 'none', sortable: false },
		{ title: 'Req. Date', field: '_created', type: 'date', sort: 'none', sortable: true },
		{ title: 'Approval', field: 'replied_by', type: 'string', sort: 'none', sortable: true },
		{ title: 'Comments', field: 'reply_comment', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: 'action', type: 'string', sort: 'none', sortable: false },
	];

	const errorMessages = {
		required: 'Required',
	};

	const errorsInit = {
		approved_cases: '',
	};

	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [orderBy, setOrderBy] = useState([]);
	const [isApprovalMode, setIsApprovalMode] = useState(false);
	const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
	const [approvingRequest, setApprovingRequest] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deletingRequest, setDeletingRequest] = useState(null);
	const [errors, setErrors] = useState({});

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (orderBy.filter((r) => r.field === col).length === 0) {
						setOrderBy([{ field: col, direction: h.sort }, ...orderBy]);
					} else {
						setOrderBy([{ field: col, direction: h.sort }, ...orderBy.filter((c) => c.field !== col)]);
					}
				}
				return h;
			}),
		);

		props.onSortChange(orderBy);
	};

	const getCasesNumbers = (r) => {
		return [
			{ label: 'Requested', value: r.requested_cases, customClassName: 'requested_cases' },
			{ label: 'Approved', value: r.approved_cases, customClassName: 'approved_cases' },
			{ label: 'Assigned', value: r.assigned_cases, customClassName: 'assigned_cases' },
			{ label: 'Remaining', value: r.remaining_cases, customClassName: 'remaining_cases' },
		];
	};

	const getStatus = (status) => {
		const statuses = {
			APPROVED: {
				ico: ICON_STATUS_CHECKED,
				title: 'Approved',
			},
			DECLINED: {
				ico: ICON_STATUS_UNCHECKED,
				title: 'Declined',
			},
			PENDING: {
				ico: ICON_STATUS_PENDING,
				title: 'Awaiting for Approval',
			},
		};

		return statuses[status];
	};

	const openApproveModal = (e) => {
		setIsApprovalMode(true);
		openUpdateStatusModal(e);
	};

	const openDeclineModal = (e) => {
		setIsApprovalMode(false);
		openUpdateStatusModal(e);
	};

	const openUpdateStatusModal = (e) => {
		let { id } = e.currentTarget.parentNode.dataset;
		let request = props.requestsList.find((r) => r.id == id);
		setApprovingRequest(request);
		setShowUpdateStatusModal(true);
	};

	const closeUpdateStatusModal = () => {
		setShowUpdateStatusModal(false);
	};

	const handleUpdateStatusChange = (e) => {
		const { name, value } = e.target;

		setApprovingRequest({
			...approvingRequest,
			[name]: value,
		});
	};

	const validate = () => {
		let isValid = true;
		if (!isApprovalMode) {
			return isValid;
		}

		setErrors({ errorsInit });
		if (!approvingRequest.approved_cases) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, approved_cases: errorMessages.required }));
		}
		return isValid;
	};

	const applyUpdateStatus = () => {
		if (!validate()) {
			return false;
		}

		let status = isApprovalMode ? props.status.APPROVED : props.status.DECLINED;
		let approvedRequest = {
			...approvingRequest,
			status: status,
			replied_by: user.uid,
		};

		props.onChange(approvedRequest, 'update_status');
		closeUpdateStatusModal(false);
	};

	const handleEditRequest = (e) => {
		let { id } = e.currentTarget.parentNode.dataset;
		props.history.push(`/tools/assignment-override-requests/${id}`);
	};

	const openDeleteModal = (e) => {
		let { id } = e.currentTarget.parentNode.dataset;
		let request = props.requestsList.find((r) => r.id == id);
		setDeletingRequest(request);
		setShowDeleteModal(true);
	};

	const closeDeleteModal = () => {
		setShowDeleteModal(false);
	};

	const applyDeleteRequest = () => {
		props.onChange(deletingRequest, 'delete');
		closeDeleteModal();
	};

	const deleteModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: applyDeleteRequest, theme: 'azami-blue' },
	];

	const updateStatusModalFooterActions = [
		{ primary: false, label: 'Close', action: closeUpdateStatusModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Apply', action: applyUpdateStatus, theme: 'azami-blue' },
	];

	const getRequestActions = (id) => {
		let request = props.requestsList.find((r) => r.id === id);
		let isPending = request.status === props.status.PENDING;

		return [
			isPending && { label: 'Approve', action: openApproveModal, dataset: { id: id }, ico: ICON_APPROVE_ACTION, roles: ['sales', 'admin'] },
			isPending && { label: 'Decline', action: openDeclineModal, dataset: { id: id }, ico: ICON_DECLINE_ACTION, roles: ['sales', 'admin'] },
			isPending && { label: 'Edit', action: handleEditRequest, dataset: { id: id }, ico: ICON_EDIT_ACTION, roles: ['sales', 'admin'] },
			{ label: 'Delete', action: openDeleteModal, dataset: { id: id }, ico: ICON_DELETE_ACTION, roles: ['sales', 'admin'] },
		].filter(Boolean);
	};

	return (
		<div className="main-screen-section white full-height no-padding">
			<div className="assignment-override-requests-main white">
				<div className="assignment-override-requests__header">
					<span className="assignment-override-requests__header__title">Requests List</span>
					<AppLink type="nav" to={`/tools/assignment-override-requests/add`} exact className="form-button primary small special">
						New Request
					</AppLink>
				</div>
				<div className="assignment-override-requests__body">
					<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort}>
						{props.requestsList &&
							props.requestsList.map((r, i) => {
								return (
									<tr key={i}>
										<DataTable.PriorityCell customClassName={`priority-weight-${r.priority_weight}`}>{r.priority_name}</DataTable.PriorityCell>
										<DataTable.Cell>{r.requested_by_name}</DataTable.Cell>
										<DataTable.PersonCell subtext={r.firm_contact}>{r.firm_name}</DataTable.PersonCell>
										<DataTable.ListGroupCell list={getCasesNumbers(r)}></DataTable.ListGroupCell>
										<DataTable.CountryCell code={r.region_code || ''}>{r.region_name}</DataTable.CountryCell>
										<DataTable.DateCell date={r._created} />
										<DataTable.StatusCell status={getStatus(r.status)} date={r.replied_date}>
											{r.replied_by_name}
										</DataTable.StatusCell>
										<DataTable.Cell>
											<div className="assignment-override-requests__body-comments">
												{r.request_comment && <Tooltip content={r.request_comment}></Tooltip>}
												{r.reply_comment && <Tooltip content={r.reply_comment} customClassName={r.status.toLowerCase()}></Tooltip>}
											</div>
										</DataTable.Cell>
										<DataTable.ActionsMenuCell actions={getRequestActions(r.id)}></DataTable.ActionsMenuCell>
									</tr>
								);
							})}
					</DataTable>
				</div>
				{showUpdateStatusModal && (
					<Modal title={isApprovalMode ? 'Approval' : 'Decline Approval'} closeHandler={closeUpdateStatusModal} footerActions={updateStatusModalFooterActions}>
						<div>
							{isApprovalMode && (
								<Input
									type="number"
									name="approved_cases"
									label="Approved Cases"
									value={approvingRequest.approved_cases}
									onChange={handleUpdateStatusChange}
									error={errors.approved_cases}
								/>
							)}
							<Textarea name="reply_comment" label="Response Comment" value={approvingRequest.reply_comment} onChange={handleUpdateStatusChange} />
						</div>
					</Modal>
				)}
				{showDeleteModal && (
					<Modal title="Delete request" closeHandler={closeDeleteModal} footerActions={deleteModalFooterActions}>
						Are you sure that you want to delete request?
					</Modal>
				)}
			</div>
		</div>
	);
};

export default AssignmentOverrideRequestsMain;
