import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Select, Modal, LoaderOverlay } from 'sazzui/lib/main';

import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';
import { inputACLCheck } from 'services/acl';

import { getFlag } from 'data/flags';

import { RoleBox } from 'com/util/RoleBox';
import { OwnerContext } from 'util/ownerContext';

import ICON_ARCHIVE_GREY from 'assets/images/icons/ico_archive_grey.svg';
import ICON_UNARCHIVE_GREY from 'assets/images/icons/ico_unarchive_grey.svg';
import ICON_DELETE from 'assets/images/icons/ico_btn_delete_lightgrey.svg';

import './style.css';

const RegionsList = (props) => {
	const { id } = useParams();
	const ownerData = useContext(OwnerContext);
	const user = useSelector((state) => state.auth.user);

	const regionFilterInit = [
		{ label: 'All', value: 'all' },
		{ label: 'Archived', value: 'archived' },
	];

	const removingRegionInit = { label: '', value: '', is_archived: 0, id: '' };

	const [regions, setRegions] = useState([]);
	const [addMode, setAddMode] = useState(false);
	const [region, setRegion] = useState({ label: '', value: '' });
	const [countriesRaw, setCountriesRaw] = useState([]);
	const [countries, setCountries] = useState([]);
	const [showArchiveRegionModal, setShowArchiveRegionModal] = useState(false);
	const [showRemoveRegionModal, setShowRemoveRegionModal] = useState(false);
	const [archivingRegion, setArchivingRegion] = useState(null);
	const [removingRegion, setRemovingRegion] = useState(removingRegionInit);
	const [showLoader, setShowLoader] = useState(false);
	const [regionFilterOptions, setRegionFilterOptions] = useState(regionFilterInit);
	const [regionFilter, setRegionFilter] = useState('all');

	useEffect(async () => {
		await getDropdownData();
	}, []);

	useEffect(async () => {
		await getDropdownData();
	}, [props.firmID]);

	useEffect(() => {
		if (props.values) {
			setRegions(
				props.values.map((v) => {
					return { label: v.region_name, value: v.region_code, is_archived: v.is_archived, id: v.region_id };
				}),
			);
		}
	}, [props.values]);

	useEffect(() => {
		// check that archivingRegion state is updated before opening modal
		setShowArchiveRegionModal((prevState) => {
			return archivingRegion ? true : false;
		});
	}, [archivingRegion]);

	const getDropdownData = async () => {
		let data = null;
		try {
			if (props.regionsList === 'firm' && props.firmID) {
				setShowLoader(true);
				let out = await Firms.GetRegions(props.firmID);
				data = out.map((d) => {
					return { id: d.region_id, name: d.region_name, code: d.region_code, firm_archived: d.is_archived };
				});
			} else if (props.regionsList === 'all') {
				setShowLoader(true);
				data = await Settings.GetRegions();
			}
			if (data) {
				setCountriesRaw(data);
				setCountries(
					data
						.filter((c) => {
							if (props.regionsList === 'firm' && c.firm_archived) {
								return false;
							}
							return c;
						})
						.map((c) => {
							return { label: c.name, value: c.code };
						}),
				);

				setRegion({ label: data[0].name, value: data[0].code });
			}
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const showHideForm = () => {
		setAddMode(!addMode);
	};

	const addCountry = () => {
		if (regions.filter((r) => r.value === region.value).length === 0) {
			let country = countriesRaw.filter((c) => c.code === region.value)[0];
			props.onChange(country, 'add');
		}
	};

	const archiveCountryBtn = (e) => {
		setArchivingRegion((prevState) => {
			return regions.filter((r) => r.value === e.target.parentNode.dataset.regionCode)[0];
		});
	};

	const deleteCountryBtn = (e) => {
		setShowRemoveRegionModal(true);
		setRemovingRegion(regions.find((r) => r.value === e.target.parentNode.dataset.regionCode));
	};

	const regionChange = (e) => {
		setRegion(
			countries.filter((r) => {
				let match = r.value.toLowerCase() === e.target.value.toLowerCase();
				return match;
			})[0],
		);
	};

	const closePopupHandler = () => {
		setShowRemoveRegionModal(false);
		setShowArchiveRegionModal(false);
		setArchivingRegion(null);
	};

	const closeRemoveRegionModal = () => {
		setShowRemoveRegionModal(false);
		setRemovingRegion(removingRegionInit);
	};

	const archiveCountry = () => {
		let country = countriesRaw.filter((c) => c.code === archivingRegion.value)[0];
		props.onChange(country, 'archive');
		setShowArchiveRegionModal(false);
	};

	const removeRegion = async () => {
		try {
			setRegions(regions.filter((r) => r.id !== removingRegion.id));
			props.onChange(removingRegion, 'remove');
		} catch (error) {
			console.log('error', error);
		} finally {
			setShowRemoveRegionModal(false);
		}
	};

	const onFilterChange = (e) => {
		setRegionFilter(e.target.value);
	};

	const popupFooterActions = [
		{ primary: false, label: 'Cancel', action: closePopupHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Confirm', action: archiveCountry, theme: 'azami-blue' },
	];

	const removeRegionFooterActions = [
		{ primary: false, label: 'Cancel', action: closePopupHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Confirm', action: removeRegion, theme: 'azami-blue' },
	];

	return (
		<div className="regions-list">
			<LoaderOverlay showLoader={showLoader} />
			{props.title ?
				<span className="regions-list__title">{props.title}</span>
			:	null}
			<div className="regions-list__header">
				<span>Region</span>
				<div className="regions-list__header__filter">
					<span>Show:</span>
					<select value={regionFilter} onChange={onFilterChange} aria-label="Show All">
						{regionFilterOptions.map((r, i) => (
							<option value={r.value} key={i}>
								{r.label}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="regions-list__items">
				{regions.map((r) => {
					let flag = getFlag(r.value);
					if (regionFilter === 'archived' && !r.is_archived) {
						return null;
					}
					return (
						<div className="regions-list__item" key={r.value}>
							<div className={`${r.is_archived ? 'regions-list__item__archived' : ''}`}>
								<img src={flag} className="regions-list__item__flag" />
								<span className="regions-list__item__name">{r.label}</span>
							</div>
							{props.editable && (
								<>
									<RoleBox roles={['admin', 'sales', 'sales_manager']}>
										<button className="regions-list__item__button" data-region-code={r.value} onClick={archiveCountryBtn}>
											<img src={r.is_archived ? ICON_UNARCHIVE_GREY : ICON_ARCHIVE_GREY} title={r.is_archived ? 'Unarchive' : 'Archive'} />
										</button>
									</RoleBox>
									<RoleBox roles={['saas_user']}>
										<button className="regions-list__item__button" data-region-code={r.value} onClick={deleteCountryBtn}>
											<img src={ICON_DELETE} title="Remove" />
										</button>
									</RoleBox>
								</>
							)}
						</div>
					);
				})}
			</div>

			{props.editable &&
				(!addMode ?
					<Button
						disabled={inputACLCheck(user, ownerData, ['$firm', 'admin', 'sales', 'sales_manager', 'saas_user'])}
						theme="light-primary"
						customClassName="regions-list__btn-add"
						onClick={showHideForm}
						ariaLabel="Add region Button"
					>
						Add Region
					</Button>
				:	<div className="regions-list__form">
						<Select
							customClassName="regions-list__form__countries-select"
							options={countries}
							value={region.value ? region.value : ''}
							onChange={regionChange}
							ariaLabel="Countries Select"
						/>
						<Button theme="special" onClick={addCountry} ariaLabel="Add Region Button" size="medium">
							Add
						</Button>
						<Button theme="ghost-primary" onClick={showHideForm} ariaLabel="Cancel Button" size="medium">
							Cancel
						</Button>
					</div>)}
			<RoleBox roles={['admin', 'sales', 'sales_manager']}>
				{showArchiveRegionModal && (
					<Modal title={archivingRegion.is_archived ? 'Unarchive Region' : 'Archive Region'} closeHandler={closePopupHandler} footerActions={popupFooterActions}>
						{`Are you sure you want to ${showArchiveRegionModal && archivingRegion.is_archived ? 'unarchive' : 'archive'} ${archivingRegion?.label} for ${props.name}?`}
					</Modal>
				)}
			</RoleBox>
			<RoleBox roles={['saas_user']}>
				{showRemoveRegionModal && (
					<Modal title="Removing Region" closeHandler={closeRemoveRegionModal} footerActions={removeRegionFooterActions}>
						Are you sure you want to remove the {`${removingRegion?.label} for ${props.name}`} ?
					</Modal>
				)}
			</RoleBox>
		</div>
	);
};

export default RegionsList;
