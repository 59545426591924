import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
// sazzui
import { Input, ErrorMessage, Button, BackButton } from 'sazzui/lib/main';
// templates
import AuthBox from 'com/templates/AuthBox';
import AuthTemplate from 'com/templates/AuthTemplate';
// services
import { ForgotPasswordSchema } from 'services/validators/forgotPassword';
import Auth from 'services/rest/auth';
import useTitle from 'services/hooks/useTitle';
// data
import { PAGE_TITLE } from 'data/constants';
// util
import AppLink from 'com/util/AppLink';
import { getError } from 'util/errors';
// styles
import './style.css';

const ForgotPassword = () => {
	useTitle(PAGE_TITLE.FORGOT_PASSWORD);

	const [spinner, setSpinner] = useState(false);
	const history = useHistory();

	const initialState = {
		email: '',
	};

	const forgotPasswordSubmit = async (fields, { setStatus }) => {
		try {
			setSpinner(true);
			console.log(fields);
			await Auth.ForgotPassword(fields.email);
			setSpinner(false);
			history.push('/forgot-password/message');
		} catch (err) {
			setStatus(getError(err.code));
			console.log(err);
			setSpinner(false);
		}
	};

	return (
		<AuthTemplate>
			{/* <ForgotPasswordAzami initialState={initialState} forgotPasswordSubmit={forgotPasswordSubmit} spinner={spinner} /> */}
			<div className="forgot-password-container">
				<AuthBox title="Forgot Password" titleColor="azami-blurple" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back to login" />}>
					<Formik initialValues={initialState} validationSchema={ForgotPasswordSchema} onSubmit={forgotPasswordSubmit} validateOnChange={false}>
						{({ values, handleChange, validateField, errors, status }) => {
							const validate = (e) => validateField(e.target.name);

							return (
								<Form>
									<span className="forgot-password-container-azami__text">Please enter your email, and we will send a password reset link.</span>
									<Input
										name="email"
										value={values.email}
										onChange={handleChange}
										onBlur={validate}
										error={errors.email}
										label="Email"
										placeholder="email@domain.com"
										type="text"
										ariaLabel="Email Input"
									/>
									<div className="forgot-password-container-azami__actions">
										<Button type="submit" showSpinner={spinner} theme="special" ariaLabel="Submit Button">
											Submit
										</Button>
									</div>
									{status && <ErrorMessage error={status} />}
								</Form>
							);
						}}
					</Formik>
				</AuthBox>
			</div>
		</AuthTemplate>
	);
};

export default ForgotPassword;
