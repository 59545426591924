//vendor imports
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
//sazzui
import { DataTable, Modal } from 'sazzui/lib/main';
//services
import useNotification from 'services/hooks/use_notification';
//icons
import ICON_PRICING_LEVEL from 'assets/images/icons/ico_pricing_level.svg';
import ICON_BASE_PRICING_LEVEL from 'assets/images/icons/ico_base_pricing_level.svg';
import ICON_INFO from 'assets/images/icons/ico_info_magenta.svg';
//styles
import './style.css';

const SystemSettingsPricingLevelsMain = (props) => {
	const showArchivedValuesInit = [
		{ label: 'Active', value: '0' },
		{ label: 'Archived', value: '1' },
		{ label: 'All', value: '' },
	];
	const tableHeadersInit = [
		{ title: 'Name', field: 'name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Description', field: 'description', type: 'string', sort: 'none', sortable: true },
		{ title: 'Points Credited', field: 'points_credited', type: 'number', sort: 'none', sortable: true },
		{ title: 'PCT/Paris/Etc.', field: 'diff_from_base', type: 'number', sort: 'none', sortable: true },
		{ title: 'Validations', field: 'diff_from_base_ep_validation', type: 'number', sort: 'none', sortable: true },
		{ title: 'Trademarks', field: 'diff_from_base_trademarks', type: 'number', sort: 'none', sortable: true },
		// { title: 'Base pricing level', field: 'is_base', type: 'string', sort: 'none', sortable: true },
		{ title: 'Used', field: 'used_by_count', type: 'number', sort: 'none', sortable: true },
		{ title: 'Actions', field: 'action', type: 'string', sort: 'none', sortable: false },
	];

	const pricingLevelDataInit = {
		id: '',
		description: '',
		diff_from_base: 0,
		diff_from_base_ep_validation: 0,
		diff_from_base_trademarks: 0,
		is_base: false,
		name: '',
		points_credited: 0,
		used_by_count: 0,
		is_archived: 0,
		_created: '',
		_deleted: 0,
		_modified: '',
	};

	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [orderBy, setOrderBy] = useState([]);
	const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState(false);
	const [showUnarchiveConfirmationModal, setShowUanarchiveConfirmationModal] = useState(false);
	const [pricingLevelToArchive, setPricingLevelToArchive] = useState(pricingLevelDataInit);
	const [pricingLevelToUnarchive, setPricingLevelToUnarchive] = useState(pricingLevelDataInit);
	const sendNotification = useNotification();

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (orderBy.filter((r) => r.field === col).length === 0) {
						setOrderBy([{ field: col, direction: h.sort }, ...orderBy]);
					} else {
						setOrderBy([{ field: col, direction: h.sort }, ...orderBy.filter((c) => c.field !== col)]);
					}
				}
				return h;
			}),
		);

		props.onSortChange(orderBy);
	};

	const directionChange = (e) => {
		props.setShowArchived(e.target.dataset.value);
	};

	const archivePricingLevel = async (e) => {
		props.onChange(pricingLevelToArchive, 'edit');
		sendNotification({ type: 'success', title: 'Successfully archived pricing level' });
		closeArchiveModal();
	};

	const unarchivePricingLevel = async (e) => {
		props.onChange(pricingLevelToUnarchive, 'edit');
		sendNotification({ type: 'success', title: 'Successfully unarchived pricing level' });
		closeUnarchiveModal();
	};

	const openArchiveModal = (e) => {
		let { id } = e.target.closest('[data-id]').dataset;
		let pricingLevel = props.pricingLevels.find((r) => r.id == id);
		setPricingLevelToArchive({ ...pricingLevel, is_archived: 1 });
		setShowArchiveConfirmationModal(true);
	};

	const openUnarchiveModal = (e) => {
		let { id } = e.target.closest('[data-id]').dataset;
		let pricingLevel = props.pricingLevels.find((r) => r.id == id);
		setPricingLevelToUnarchive({ ...pricingLevel, is_archived: 0 });
		setShowUanarchiveConfirmationModal(true);
	};

	const closeArchiveModal = () => {
		setShowArchiveConfirmationModal(false);
	};

	const closeUnarchiveModal = () => {
		setShowUanarchiveConfirmationModal(false);
	};

	const archiveModalActions = [
		{ primary: false, label: 'Close', action: closeArchiveModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Archive', action: archivePricingLevel, theme: 'azami-blue' },
	];

	const unarchiveModalActions = [
		{ primary: false, label: 'Close', action: closeUnarchiveModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Unarchive', action: unarchivePricingLevel, theme: 'azami-blue' },
	];

	const checkBase = (base) => {
		let baseData = {
			icon: '',
			className: '',
		};
		if (base === false) {
			baseData = { icon: ICON_PRICING_LEVEL, className: 'regular' };
		} else {
			baseData = { icon: ICON_BASE_PRICING_LEVEL, className: 'base' };
		}
		return baseData;
	};

	const formatPLDiff = (value) => {
		let out = '';
		if (value === 0) {
			return (out = '-');
		}
		if (value < 0) {
			return (out = `${value}%`);
		}
		if (value > 0) {
			return (out = `+${value}%`);
		}
		return out;
	};

	const generateTooltipLabel = (pricingLevel) => {
		let title = '';
		if (pricingLevel.is_archived) {
			title = 'Unarchive';
		}
		if (!pricingLevel.is_archived && pricingLevel.used_by_count === 0) {
			title = 'Archive';
		}
		if (pricingLevel.used_by_count) {
			title = 'Unable to archive';
		}
		return title;
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content">
			<div className="system-settings-pricing-levels-main white">
				<div className="system-settings-pricing-levels__header">
					<span className="system-settings-pricing-levels__header__title">Pricing Levels</span>
					<ul className="system-settings-pricing-levels__section-header__directions">
						{showArchivedValuesInit.map((sd, i) => {
							return (
								<li key={i} onClick={directionChange} data-value={sd.value} className={`dir-filter ${props.showArchived === sd.value && 'active'}`}>
									{sd.label}
								</li>
							);
						})}
					</ul>
					<NavLink to={'/system-settings/pricing-levels/add'} className="form-button primary medium special" exact>
						Add Pricing Level
					</NavLink>
				</div>
				<div className="system-settings-pricing-level__highlight-info-wrapper">
					<img src={ICON_INFO} alt="" />
					<span>Highlighted columns show the price difference from the base pricing level.</span>
				</div>
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="system-settings-pricing-levels__table">
					{props.pricingLevels.map((p, i) => {
						let disableArchive = p.is_archived || p.used_by_count != 0 ? true : false;
						return (
							<tr key={p.id}>
								<DataTable.PersonCell
									showIco={true}
									theme="clear"
									customClassName={`system-settings-pricing-levels__name ${checkBase(p.is_base).className}`}
									ico={checkBase(p.is_base).icon}
								>
									{p.name}
								</DataTable.PersonCell>
								<DataTable.Cell>{p.description}</DataTable.Cell>
								<DataTable.Cell>{p.points_credited}%</DataTable.Cell>
								<DataTable.Cell customClassName="system-settings-pricing-levels__diff-from-base">{formatPLDiff(p.diff_from_base)}</DataTable.Cell>
								<DataTable.Cell customClassName="system-settings-pricing-levels__diff-from-base">{formatPLDiff(p.diff_from_base_ep_validation)}</DataTable.Cell>
								<DataTable.Cell customClassName="system-settings-pricing-levels__diff-from-base">{formatPLDiff(p.diff_from_base_trademarks)}</DataTable.Cell>
								{/* <DataTable.StatusCell status={getBaseLevel(p.is_base)}/> */}
								<DataTable.Cell>{p.used_by_count}</DataTable.Cell>
								<DataTable.Cell>
									<NavLink to={`/system-settings/pricing-levels/${p.id}/edit`} exact className="system-settings-pricing-levels__action-edit-btn" title="Edit" />
									{p.is_archived === false ?
										<button
											className={`system-settings-pricing-levels__action-archive-btn ${disableArchive ? 'disabled' : ''} ${p.is_archived && 'unarchive'}`}
											onClick={openArchiveModal}
											data-id={p.id}
											data-index={i}
											disabled={disableArchive}
											title={generateTooltipLabel(p)}
										/>
									:	<button
											className={`system-settings-pricing-levels__action-archive-btn ${disableArchive ? 'disabled' : ''} ${p.is_archived && 'unarchive'}`}
											onClick={openUnarchiveModal}
											data-id={p.id}
											data-index={i}
											title={generateTooltipLabel(p)}
										/>
									}
								</DataTable.Cell>
							</tr>
						);
					})}
				</DataTable>
				{showArchiveConfirmationModal && (
					<Modal title="Archive Pricing Level?" closeHandler={closeArchiveModal} footerActions={archiveModalActions}>
						Are you sure you want to archive <strong>{pricingLevelToArchive.name}</strong> pricing level?
					</Modal>
				)}
				{showUnarchiveConfirmationModal && (
					<Modal title="Unarchive Pricing Level?" closeHandler={closeUnarchiveModal} footerActions={unarchiveModalActions}>
						Are you sure you want to unarchive <strong>{pricingLevelToUnarchive.name}</strong> pricing level?
					</Modal>
				)}
			</div>
		</div>
	);
};

export default SystemSettingsPricingLevelsMain;
