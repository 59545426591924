import React from 'react';
// templates
import AuthBox from 'com/templates/AuthBox';
import AuthTemplate from 'com/templates/AuthTemplate';
// sazzui
import { BackButton } from 'sazzui/lib/ui/BackButton';
// util
import AppLink from 'com/util/AppLink';
// styles
import './style.css';

const ChangePasswordMessage = () => {
	return (
		<AuthTemplate>
			<div className="change-password-message-container">
				<AuthBox title="Password successfully changed" titleColor="azami-blurple" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back To login" />}>
					Your password has been successfully changed. Please proceed to the login page and enter the system.
				</AuthBox>
			</div>
		</AuthTemplate>
	);
};

export default ChangePasswordMessage;
