import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppTemplate from 'com/templates/ApplicationTemplate';
import { DataTable, Paging } from 'sazzui/lib/widgets/DataTable';
import AppLink from 'com/util/AppLink';

import { Input } from 'sazzui/lib/ui/Input';
import { Button } from 'sazzui/lib/ui/Button';
import { Select } from 'sazzui/lib/ui/Select';
import { LoaderOverlay } from 'sazzui/lib/ui/LoaderOverlay';

import { PAGE_TITLE } from 'data/constants';

import Firms from 'services/rest/firms';
import Settings from 'services/rest/settings';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';

const FirmManagementList = () => {
	useTitle(PAGE_TITLE.FIRM_MANAGEMENT);

	const [firms, setFirms] = useState([]);
	const [firmSizes, setFirmSizes] = useState([]);
	const [tableHeaders, setTableHeaders] = useState([]);
	const [filterData, setFilterData] = useState({ name: '', code: '', firm_size_id: '', orderBy: [] });
	const [paging, setPaging] = useState({ total_rows: 0, page: 1, limit: Paging.PAGE_SIZE[0].value });
	const [pageChange, setPageChange] = useState(1);
	const [showLoader, setShowLoader] = useState(false);
	const history = useHistory();
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		getFirms();
	}, [pageChange]);

	const fetchData = async () => {
		try {
			let firmSizes = await Settings.GetFirmSizes();
			setFirmSizes([
				{ label: 'All', value: '' },
				...firmSizes.map((fs) => {
					return { label: fs.label, value: fs.id };
				}),
			]);
			setTableHeaders([
				{ title: 'Name', field: 'name', type: 'string', sort: 'none', sortable: true },
				{ title: 'Firm Code', field: 'code', type: 'string', sort: 'none', sortable: false },
				{ title: 'Number of Attorneys', field: 'number_of_attorneys', type: 'string', sort: 'none', sortable: true },
				{ title: 'Firm Size', field: 'firm_size', type: 'string', sort: 'none', sortable: false },
			]);
		} catch (err) {}
	};

	const getFirms = async () => {
		try {
			let f = getFilterValue();
			if (f) setFilterData(f);
			setShowLoader(true);
			let filters = {
				...(f ? f : filterData),
				order_by: filterData.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
				page: paging.page,
				limit: paging.limit,
			};
			let firms = await Firms.GetAll(filters);
			setFirms(firms.data);
			setPaging({ ...paging, total_rows: firms.total_rows });
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
		}
	};

	const filterChange = (e) => {
		setFilterData({
			...filterData,
			[e.target.name]: e.target.value,
		});
	};

	const filterSubmit = async () => {
		setPaging({ ...paging, total_rows: 0, page: 1 });
		setPageChange(pageChange + 1);
		setFilterValue(filterData);
	};

	const clearForm = () => {
		setFilterData({ ...filterData, name: '', code: '', firm_size_id: '' });
		filterSubmit();
		setFilterValue(null);
	};

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.orderBy.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							orderBy: [{ field: col, direction: h.sort }, ...filterData.orderBy],
						});
					} else {
						setFilterData({
							...filterData,
							orderBy: [{ field: col, direction: h.sort }, ...filterData.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const pagingChange = async (p, l) => {
		setPaging({ ...paging, page: p, limit: l });
		setPageChange(pageChange + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const goToFirm = (e) => {
		console.log('closeset', e.target.closest('tr').dataset.fid);
		history.push(`/firm-management/${e.target.closest('tr').dataset.fid}`);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			filterSubmit();
		}
	};

	return (
		<AppTemplate title="Firm Management" headerIcon="firm_management">
			<AppTemplate.Sidebar>
				<div className="firm-management-sidebar">
					<h4 className="firm-management-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						filters
					</h4>
					<Input name="name" label="Name" theme="dark" onChange={filterChange} value={filterData.name} onKeyDown={handleKeyDown} />
					<Input name="code" label="Firm Code" theme="dark" onChange={filterChange} value={filterData.code} onKeyDown={handleKeyDown} />
					<Select name="firm_size_id" options={firmSizes} label="Firm Size" theme="dark" onChange={filterChange} value={filterData.firm_size_id} />
					<div className="firm-management-sidebar_actions sidebar_actions">
						<Button onClick={filterSubmit} theme="primary" size="small">
							Filter Firms
						</Button>
						<Button theme="ghost-primary" onClick={clearForm} size="small">
							Clear
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Header>
				<AppLink type="nav" to={`/firm-management/add`} activeClassName="active" className="form-button primary medium special" exact>
					Create Firm
				</AppLink>
			</AppTemplate.Header>
			<AppTemplate.Content>
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="firm-management__data-table">
						{firms.map((f, i) => {
							return (
								<tr key={f.id} onClick={goToFirm} data-fid={f.id}>
									<DataTable.PersonCell avatar="" showIco={true}>
										{f.name}
									</DataTable.PersonCell>
									<DataTable.MarkerCell>{f.code}</DataTable.MarkerCell>
									<DataTable.Cell>{f.number_of_attorneys}</DataTable.Cell>
									<DataTable.Cell>{f.firm_size}</DataTable.Cell>
								</tr>
							);
						})}
					</DataTable>
					<Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default FirmManagementList;
