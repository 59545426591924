import React from 'react';
// templates
import AuthBox from 'com/templates/AuthBox';
import AuthTemplate from 'com/templates/AuthTemplate';
// sazzui
import { BackButton } from 'sazzui/lib/ui/BackButton';
// util
import AppLink from 'com/util/AppLink';
// styles
import './style.css';

const ForgotPasswordMessage = () => {
	return (
		<AuthTemplate>
			<div className="thankyou-container">
				<AuthBox title="A password reset link is on it's way" titleColor="azami-blurple" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back To login" />}>
					An email with a password reset link has just been sent to you. Please check your email and follow the instructions in order to reset your password.
				</AuthBox>
			</div>
		</AuthTemplate>
	);
};

export default ForgotPasswordMessage;
