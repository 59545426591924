import React, { useState, useEffect } from 'react';

import { DocumentUploader } from 'sazzui/lib/main';
import RatesList from 'com/widgets/RatesList';
import { RoleBox } from 'com/util/RoleBox';
import { INTERNAL_ROLES } from 'data/constants';

import Settings from 'services/rest/settings';

const UserDetailsRates = (props) => {
	const [regionServiceMapping, setRegionServiceMapping] = useState(new Map());
	const [services, setServices] = useState([]);

	useEffect(() => {
		(async () => {
			await fetchRegionServiceMapping();
		})();
	}, [props.userData.regions]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, []);

	const fetchData = async () => {
		let dataServices = await Settings.GetServices();
		setServices(dataServices);
	};

	const fetchRegionServiceMapping = async () => {
		let rIDs = props.userData.regions.map((rid) => rid.region_id);
		let servicesToShow = await Settings.GetRegionServiceMapping({ region_ids: rIDs });
		const rsm = new Map();
		for (let serviceToShow of servicesToShow) {
			const regionID = serviceToShow.region_id;
			const regionValue = rsm.get(regionID);
			if (regionValue) {
				regionValue.push(serviceToShow.service_id);
			} else {
				rsm.set(regionID, [serviceToShow.service_id]);
			}
		}
		setRegionServiceMapping(rsm);
	};

	return (
		<div className="user-details-rates">
			<RatesList
				data={props.userData}
				onChange={props.onChangeRate}
				revertRate={true}
				firmCurrency={props.userData.firm_data.currency_id}
				regionServiceMapping={regionServiceMapping}
				services={services}
			/>
			<RoleBox roles={INTERNAL_ROLES}>
				<DocumentUploader title="Rates Confirmation" />
			</RoleBox>
		</div>
	);
};

export default UserDetailsRates;
