import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
// templates
import AuthTemplate from 'com/templates/AuthTemplate';
import AuthBox from 'com/templates/AuthBox';
// sazzui
import { ErrorMessage, Checkbox, Button, BackButton, Input } from 'sazzui/lib/main';
// services
import Auth from 'services/rest/auth';
import useQuery from 'services/hooks/useQuery';
import useTitle from 'services/hooks/useTitle';
import { ChangePasswordSchema } from 'services/validators/changePassword';
// util
import { getError } from 'util/errors';
import AppLink from 'com/util/AppLink';
// data
import Routes from 'data/routes';
import { PAGE_TITLE } from 'data/constants';
// styles
import './style.css';

const ChangePassword = () => {
	useTitle(PAGE_TITLE.RESET_PASSWORD);
	const [spinner, setSpinner] = useState(false);
	const history = useHistory();
	const { id } = useParams();
	const query = useQuery();
	const tc = query.get('tc') === 'true';

	const initialState = {
		password: '',
		password_repeat: '',
		tos_agreement: tc,
	};

	const changePasswordSubmit = async (fields, { setStatus }) => {
		let data = {
			password_reset_token: id,
			password: fields.password,
			password_repeat: fields.password_repeat,
		};
		if (!tc) {
			data = {
				...data,
				tos_agreement: fields.tos_agreement,
			};
		}
		try {
			setSpinner(true);
			await Auth.ResetPassword(data);
			setSpinner(false);
			history.push('/change-password/message');
		} catch (err) {
			setStatus(getError(err.code));
			console.log(err);
		} finally {
			setSpinner(false);
		}
	};

	return (
		<AuthTemplate>
			<div className="change-password-container">
				<AuthBox title="Change Password" titleColor="azami-blurple" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back to login" theme="primary" />}>
					<Formik initialValues={initialState} validationSchema={ChangePasswordSchema} onSubmit={changePasswordSubmit} validateOnChange={false}>
						{({ values, handleChange, validateField, errors, status }) => {
							const validate = (e) => validateField(e.target.name);

							return (
								<Form>
									<span className="change-password-container-azami__text">Please enter your new password.</span>
									<Input name="password" value={values.password} onChange={handleChange} error={errors.password} label="Password" type="password" onBlur={validate} theme="primary" />
									<Input
										name="password_repeat"
										value={values.password_repeat}
										onChange={handleChange}
										error={errors.password_repeat}
										label="Retype Password"
										type="password"
										onBlur={validate}
									/>
									{!tc && (
										<Checkbox
											customClassName="user-details-content__footer__checkbox-label change-password-container__checkbox"
											label={
												<>
													I Agree to the{' '}
													<AppLink type="nav" to={Routes.LEGAL_INFO_TERMS_AND_CONDITIONS.path} target="_blank">
														Terms &amp; Conditions
													</AppLink>{' '}
													and{' '}
													<AppLink type="nav" to={Routes.LEGAL_INFO_ENGAGEMENT_LETTER.path} target="_blank">
														Engagement Letter
													</AppLink>{' '}
													of Azami Global
												</>
											}
											name="tos_agreement"
											checked={values.tos_agreement}
											onChange={handleChange}
											error={errors.tos_agreement}
											onBlur={validate}
											title="Terms and Conditions agreement"
										/>
									)}
									<div className="change-password-container-azami__actions">
										<Button type="submit" showSpinner={spinner} theme="special">
											Submit
										</Button>
									</div>
									{status && <ErrorMessage error={status} />}
								</Form>
							);
						}}
					</Formik>
				</AuthBox>
			</div>
		</AuthTemplate>
	);
};

export default ChangePassword;
