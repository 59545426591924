import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'sazzui/lib/main';
import { Modal } from 'sazzui/lib/main';

import { Button } from 'sazzui/lib/main';
import { Input } from 'sazzui/lib/main';
import { LoaderOverlay } from 'sazzui/lib/main';

import FirmFamily from 'services/rest/firm_families';
import EventLoggerEvents from 'services/rest/event_logger';
import { random } from 'services/strings';

import './style.css';

const SystemSettingsFirmFamilies = (props) => {
	const [showSpinner, setShowSpinner] = useState(false);
	const [firmFamilies, setFirmFamilies] = useState([]);
	const [tableHeaders, setTableHeaders] = useState([]);
	const [showInsertRow, setShowInsertRow] = useState(false);
	const [firmFamily, setFirmFamily] = useState('');
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	const [firmFamilyToDelete, setFirmFamilyToDelete] = useState('');

	const user = useSelector((state) => state.auth.user);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			setShowSpinner(true);
			let firmFamiliesData = await FirmFamily.GetAll();
			setFirmFamilies(firmFamiliesData);

			setTableHeaders([
				{ title: 'Name', field: 'name', type: 'string', sortable: false },
				{ title: '', field: '', type: 'string', sortable: false },
			]);
		} catch (error) {
			console.log('err', error);
		} finally {
			setShowSpinner(false);
		}
	};

	const handleSaveFirmFamily = async () => {
		try {
			setShowSpinner(true);
			// ...
			for (let i = 0; i < firmFamilies.length; i++) {
				if (!firmFamilies[i].id) {
					let f = await FirmFamily.Create(firmFamilies[i]);
					setFirmFamilies(
						firmFamilies.map((ff) => {
							if (ff.name === f.name) {
								return f;
							}
							return ff;
						}),
					);
				}
			}
			// let firmFamiliesData = await FirmFamily.GetAll();
			// setFirmFamilies(firmFamiliesData);
			EventLoggerEvents.EventLogger({ event: `Firm Families Tab Updated`, data: { user: user } });
			setShowSpinner(false);
		} catch (err) {
			console.log(err);
		}
	};

	const createFirmFamily = () => {
		setShowInsertRow(true);
	};

	const cancelInsert = () => {
		setShowInsertRow(false);
		setFirmFamily('');
	};

	const addFirmFamily = () => {
		if (firmFamily.trim().length > 0) {
			setFirmFamilies([{ name: firmFamily }, ...firmFamilies]);
			setFirmFamily('');
			setShowInsertRow(false);
		}
	};

	const deleteFirmFamily = async (e) => {
		try {
			setShowSpinner(true);
			await FirmFamily.DeleteByID(firmFamilyToDelete);
			setFirmFamilies(firmFamilies.filter((ff) => ff.id !== firmFamilyToDelete));
			setShowDeleteConfirmationModal(false);
		} catch (err) {
			console.log(err);
		} finally {
			setShowSpinner(false);
		}
	};

	const firmFamilyInputHandler = (e) => {
		setFirmFamily(e.target.value);
	};

	const openDeleteModal = (e) => {
		if (e.target.dataset.id === undefined) {
			setFirmFamilies(firmFamilies.filter((f, i) => i != e.target.dataset.index));
			return;
		}
		setShowDeleteConfirmationModal(true);
		setFirmFamilyToDelete(e.target.dataset.id);
	};

	const closeDeleteModal = () => {
		setShowDeleteConfirmationModal(false);
	};

	const deleteModalActions = [
		{ primary: false, label: 'Close', action: closeDeleteModal, theme: 'primary' },
		{ primary: true, label: 'Delete', action: deleteFirmFamily, theme: 'primary' },
	];

	return (
		<div className="main-screen-section white full-height no-padding system-details-content">
			<LoaderOverlay showLoader={showSpinner} />
			<div className="system-settings-firm-families__header">
				<span className="system-settings-firm-families__header__title">Firm Families</span>
				<Button theme="special" onClick={createFirmFamily} size="small">
					Add Firm Family
				</Button>
			</div>
			<div className="system-details-content__body system-settings-table-wrapper">
				<DataTable fixedHeader={false} columns={tableHeaders}>
					{showInsertRow && (
						<>
							<tr className="firm-family-row-spacer">
								<td></td>
							</tr>{' '}
							{/* this extra row is used as a spacer between the table header and the insert-row, no other way around it */}
							<tr className="firm-family-insert-row">
								<td>
									<Input type="text" name="firm_family" value={firmFamily} onChange={firmFamilyInputHandler} ariaLabel="firm family" />
								</td>
								<td>
									<button className="firm-families__action-ok" onClick={addFirmFamily} aria-label="confirm" />
									<button className="firm-families__action-cancel" onClick={cancelInsert} aria-label="cancel" />
								</td>
							</tr>
						</>
					)}
					{firmFamilies.map((f, i) => {
						return (
							<tr key={random(4)}>
								<DataTable.Cell>{f.name}</DataTable.Cell>
								<DataTable.ActionsCell>
									<button className="groups-table__action-delete-btn" onClick={openDeleteModal} data-id={f.id} data-index={i} aria-label="delete" />
								</DataTable.ActionsCell>
							</tr>
						);
					})}
				</DataTable>
			</div>
			<footer className="system-details-content__footer">
				<Button theme="special" onClick={handleSaveFirmFamily} showSpinner={showSpinner}>
					Save
				</Button>
			</footer>
			{showDeleteConfirmationModal && (
				<Modal title="Delete Firm Family?" closeHandler={closeDeleteModal} footerActions={deleteModalActions}>
					<div>Are you sure you like to delete this firm family?</div>
				</Modal>
			)}
		</div>
	);
};
export default SystemSettingsFirmFamilies;
