import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import { Toast } from '../../widgets/Toast';
import './style.css';
export var ToastTypes;
(function (ToastTypes) {
    ToastTypes["INFO"] = "info";
    ToastTypes["ERROR"] = "error";
    ToastTypes["WARNING"] = "warning";
    ToastTypes["SUCCESS"] = "success";
})(ToastTypes || (ToastTypes = {}));
const isValid = (type) => {
    return Object.values(ToastTypes).includes(type);
};
export const NotificationLayer = (props) => {
    const toastRoot = document.getElementById('toast');
    if (!toastRoot) {
        return null;
    }
    return ReactDOM.createPortal(_jsxs("div", { className: "notification-layer", children: [props.notificationToasts &&
                props.notificationToasts.map((t) => {
                    return isValid(t.type) ? _jsx(Toast, { value: t.title, id: t.id, type: t.type, removeToast: props.removeAction, autoClose: t.autoClose }) : null;
                }), props.notificationToasts && props.notificationToasts.length >= 3 ? (_jsxs("button", { className: "remove-all", onClick: props.removeAllToasts, children: [_jsx("span", { className: "close", children: "\u00D7" }), "Dismiss all notifications"] })) : null] }), toastRoot);
};
