import React from 'react';
import { useDispatch } from 'react-redux';
import LogRocket from 'logrocket';
import { Formik, Form } from 'formik';
// templates
import AuthBox from 'com/templates/AuthBox';
import AuthTemplate from 'com/templates/AuthTemplate';
// redux
import { setLoggedIn, setUserData } from 'redux/ducks/auth';
// sazzui
import { Input, Button, ErrorMessage } from 'sazzui/lib/main';
// util
import AppLink from 'com/util/AppLink';
import { getError } from 'util/errors';
// services
import Auth from 'services/rest/auth';
import EventLogger from 'services/rest/event_logger';
import jwt from 'services/jwt';
import FirmAgents from 'services/rest/firm_agents';
import useTitle from 'services/hooks/useTitle';
import { LoginSchema } from 'services/validators/login';
// data
import { PAGE_TITLE, AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY, LOGIN_AZAMI } from 'data/constants';
// local
import LoginFooter from './LoginFooter';
// styles
import './style.css';

const Login = () => {
	const dispatch = useDispatch();
	useTitle(PAGE_TITLE.LOGIN);

	const initialState = {
		email: '',
		password: '',
	};

	const handleSubmit = async (fields, { setStatus }) => {
		try {
			let grctoken = await recaptchaToken();
			let res = await Auth.Login({ username: fields.email, password: fields.password, grct: grctoken });
			window.localStorage.setItem('token', res.token);
			const user = jwt.parseJWT(res.token);
			// set logrocket ID data for easy tracking of the user
			LogRocket.identify(user.uid, {
				name: user.name,
				email: user.email,
				role: user.role,
			});

			if (user.role.includes('saas_user')) {
				let agentAssociations = await FirmAgents.GetAssociations(user.fid);
				localStorage.setItem(AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY, JSON.stringify({ agentOfFirms: agentAssociations.firm_clients, clientOfFirms: agentAssociations.firm_agents }));
			} else {
				localStorage.setItem(AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY, JSON.stringify({ agentOfFirms: [], clientOfFirms: [] }));
			}
			// set user data in redux
			dispatch(setUserData(user));
			dispatch(setLoggedIn(true));
			EventLogger.EventLogger({ event: `Logged In`, data: { user: user } });
		} catch (err) {
			console.log(err);
			setStatus(getError(err.code));
		}
	};

	const recaptchaToken = async () => {
		return new Promise((success, fail) => {
			if (!window.grecaptcha) return success('');
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' }).then((token) => {
					success(token);
				});
			});
		});
	};
	return (
		<AuthTemplate>
			<div className="login-container">
				<div className="login-container-azami__header">
					<span className="login-container-azami__header-underline">Welcome</span>&nbsp;to&nbsp;
					<span className="login-container-azami__header-platform">Azami Global</span>
				</div>
				<span className="login-container-azami__description">{LOGIN_AZAMI}</span>
				<AuthBox footer={<LoginFooter />}>
					<Formik initialValues={initialState} validationSchema={LoginSchema} onSubmit={handleSubmit} validateOnChange={false}>
						{({ values, handleChange, validateField, errors, status }) => {
							const validate = (e) => validateField(e.target.name);

							return (
								<Form>
									<Input name="email" value={values.email} onChange={handleChange} onBlur={validate} error={errors.email} label="Email" placeholder="email@domain.com" type="text" />
									<Input
										name="password"
										value={values.password}
										onChange={handleChange}
										onBlur={validate}
										error={errors.password}
										label="Password"
										placeholder="your password"
										type="password"
									/>
									<div className="login-container__actions">
										<AppLink type="regular" to="/forgot-password">
											Forgot password?
										</AppLink>
										<Button type="submit" theme="special">
											Login
										</Button>
									</div>
									{status && <ErrorMessage error={status} />}
								</Form>
							);
						}}
					</Formik>
				</AuthBox>
			</div>
		</AuthTemplate>
	);
};

export default Login;
