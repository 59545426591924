import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DataTable, Button, Modal, LoaderOverlay } from 'sazzui/lib/main';

import useNotification from 'services/hooks/use_notification';

import Banner from 'services/rest/banner';

import './style.css';

const SystemSettingsBannerList = (props) => {
	const tableHeadersInit = [
		{ title: 'Name', field: 'name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Start', field: 'date_from', type: 'string', sort: 'none', sortable: true },
		{ title: 'End', field: 'date_to', type: 'string', sort: 'none', sortable: true },
		{ title: 'Text Area', field: 'text', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: 'actions', sort: 'none', sortable: false },
	];
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [bannerForDelete, setBannerForDelete] = useState({});
	const history = useHistory();
	const sendNotification = useNotification();

	const goToCreateBanner = () => {
		history.push('/system-settings/banners/create');
	};

	const handleDelete = async (id) => {
		props.setShowLoader(true);
		try {
			await Banner.deleteByID(id);
			await props.fetchData();
			props.setShowLoader(false);
			setDeleteModalOpen(false);
			sendNotification({ type: 'success', title: 'Successfully removed banner' });
		} catch (error) {
			if (error === 'Not Found') {
				props.setShowLoader(false);
				return sendNotification({ type: 'error', title: 'Banner not found' });
			}
			console.log(error);
			props.setShowLoader(false);
			sendNotification({ type: 'error', title: 'Banner delete fail' });
		}
	};

	const closeDeleteModal = () => {
		setBannerForDelete({});
		setDeleteModalOpen(false);
	};

	const handleOpenDeleteModal = (id) => {
		let banner = props.bannerData.find((b) => b.id === id);
		setBannerForDelete(banner);
		setDeleteModalOpen(true);
	};

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (props.filterData.orderBy.filter((r) => r.field === col).length === 0) {
						props.setFilterData({
							...props.filterData,
							orderBy: [{ field: col, direction: h.sort }, ...props.filterData.orderBy],
						});
					} else {
						props.setFilterData({
							...props.filterData,
							orderBy: [{ field: col, direction: h.sort }, ...props.filterData.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const goToEdit = (id) => {
		history.push(`/system-settings/banners/update/${id}`);
	};

	const deleteModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteModal },
		{ primary: true, label: 'Confirm', action: () => handleDelete(bannerForDelete.id) },
	];

	return (
		<>
			<div className="system-settings__banner-list__header">
				<span>List of Banners</span>
				<Button theme="special" onClick={goToCreateBanner} size="medium">
					Create Banner
				</Button>
			</div>
			<div className="system-settings__banner-list__body">
				<DataTable fixedHeader={true} columns={tableHeaders} customClassName="system-settings__banner-list__body__table" onColumnSort={columnSort}>
					{props.bannerData.length > 0 &&
						props.bannerData.map((d) => {
							return (
								<DataTable.Row key={d.id}>
									<DataTable.Cell>{d.name}</DataTable.Cell>
									<DataTable.DateTimeCell date={d.date_from} customClassName="system-settings__banner-list__body__table__time-date" />
									<DataTable.DateTimeCell date={d.date_to} customClassName="system-settings__banner-list__body__table__time-date" />
									<DataTable.Cell>{d.text}</DataTable.Cell>
									<DataTable.Cell>
										<button onClick={() => goToEdit(d.id)} className="system-settings__banner-list__body__edit-btn"></button>
										<button onClick={() => handleOpenDeleteModal(d.id)} className="system-settings__banner-list__body__delete-btn"></button>
									</DataTable.Cell>
								</DataTable.Row>
							);
						})}
				</DataTable>
			</div>
			{deleteModalOpen ?
				<Modal title="Delete Banner" closeHandler={closeDeleteModal} footerActions={deleteModalFooterActions}>
					<LoaderOverlay showLoader={props.showLoader} />
					<p className="confirmation-modal__body__confirm-delete">
						Delete <span>{`${bannerForDelete.name} ?`}</span>
					</p>
				</Modal>
			:	null}
		</>
	);
};

SystemSettingsBannerList.propTypes = {
	bannerData: PropTypes.array,
	setShowLoader: PropTypes.func,
	showLoader: PropTypes.bool,
	filterData: PropTypes.object,
	setFilterData: PropTypes.func,
	fetchData: PropTypes.func,
};

SystemSettingsBannerList.defaultProps = {
	bannerData: [],
	setShowLoader: () => {},
	showLoader: false,
	filterData: {},
	setFilterData: () => {},
	fetchData: () => {},
};

export default SystemSettingsBannerList;
