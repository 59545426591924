const calculationByAmountField = [
	{ value: '', label: 'Select Value' },
	{ value: 'NUMBER_OF_PRIORITIES', label: 'Number of Priorities' },
	{ value: 'NUMBER_OF_WORDS', label: 'Total Number of Words' },
	{ value: 'NUMBER_OF_WORDS_IN_CLAIMS', label: 'Number of Words in Claims' },
	{ value: 'NUMBER_OF_PAGES', label: 'Total Number of Pages' },
	{ value: 'NUMBER_OF_CLAIMS', label: 'Number of Claims' },
	{ value: 'NUMBER_OF_INDEPENDANT_CLAIMS', label: 'Number of Independent Claims' },
	{ value: 'NUMBER_OF_EMBODIMENTS', label: 'Number of Embodiments' },
	{ value: 'NUMBER_OF_PAGE_DRAWINGS', label: 'Number of Page Drawings' },
	{ value: 'NUMBER_OF_PAGE_SEQUENCES', label: 'Number of Page Sequences' },
	{ value: 'NUMBER_OF_CLASSES', label: 'Number of Classes Filing Into' },
	{ value: 'NUMBER_OF_PAGES_IN_SPEC_SEQ_LISTINGS', label: 'Num. of Pages in Specification + Sequence Listings' },
];

const calculationByAmountMode = [
	{ value: '', label: 'Select Value' },
	{ label: 'Value', value: 'VALUE' },
	{ label: 'Multiply', value: 'MULTIPLY' },
];

const calculationSelectUnselectedField = [
	{ value: '', label: 'Select Value' },
	{ value: 'AMENDMENTS_PUBLISHED_ON_WIPO', label: 'Amendments Published on WIPO' },
	{ value: 'AMENDMENTS_SENT_TO_BE_SUBMITTED', label: 'Amendments to be Submitted at the Time of Filing' },
	{ value: 'REQUEST_FOR_EXAMINATION', label: 'Request for Examination' },
	{ value: 'PPH', label: 'PPH - Patent Prosecution Highway' },
	{ value: 'CHAPTER_II_DEMAND', label: 'Chapter II Demand' },
	{ value: 'CLAIMING_PRIORITY', label: 'Claiming Priority?' },
	{ value: 'THIRD_YEAR_RENEWAL', label: 'Third Year Renewal' },
	{ value: 'TWO_MONTH_EXTENSION_CHINA', label: 'If the 30-month deadline is within the next 3 weeks, toggle switch on to use the 2-month extension for filing into China' },
	{ value: 'FORTY_TWO_MONTH_EXTENSION_CANADA', label: 'Will you be utilizing the 42-month extension in Canada?' },
];

const calculationValueFromListField = [
	{ value: '', label: 'Select Value' },
	{ value: 'ENTITY_SIZE', label: 'Entity Size' },
	{ value: 'LOCATION_OF_SEARCH', label: 'Location of Search' },
	{ value: 'APPLICATION_LANGUAGE', label: 'Application Language' },
	{ value: 'UTILITY_MODEL_PRIORITY', label: 'Utility Model: Priority Type' },
];

const calculationValueConditions = [
	{ value: 'IS', label: 'Is' },
	{ value: 'IS_NOT', label: 'Is Not' },
	{ value: 'IS_ONE_OF', label: 'Is One Of' },
	{ value: 'IS_NOT_ONE_OF', label: 'Is Not One Of' },
];

const calculationValueFromListValueEntitySize = [
	{ value: '', label: 'Select Value' },
	{ value: 'NO_VALUE', label: 'No Value' },
	{ value: 'LARGE', label: 'Large' },
	{ value: 'SMALL', label: 'Small' },
	{ value: 'INDIVIDUAL', label: 'Individual' },
];

const calculationValueFromListValueLocationOfSearch = [
	{ value: '', label: 'Select Location' },
	{ value: 'NO_REPORT', label: 'No search report' },
	{ value: 'AT', label: 'AT - Austria' },
	{ value: 'EP', label: 'EP - European Patent Office' },
	{ value: 'ES', label: 'ES - Spain' },
	{ value: 'FI', label: 'FI - Finland' },
	{ value: 'SE', label: 'SE - Sweden' },
	{ value: 'TR', label: 'TR - Turkey' },
	{ value: 'XV', label: 'XV - Visegrad Patent Institute' },
	{ value: 'XN', label: 'XN - Nordic Patent Institute' },
	{ value: 'US', label: 'US - United States' },
	{ value: 'OTHER', label: 'Other' },
];

const calculationValueFromListValueUtilityModelPriority = [
	{ value: '', label: 'Select Value' },
	{ value: 'PCT_NPE', label: 'PCT NPE' },
	{ value: 'PARIS_CONVENTION', label: 'Paris Convention/Direct' },
];

export default {
	calculationByAmountField,
	calculationByAmountMode,
	calculationSelectUnselectedField,
	calculationValueFromListField,
	calculationValueConditions,
	calculationValueFromListValueEntitySize,
	calculationValueFromListValueLocationOfSearch,
	calculationValueFromListValueUtilityModelPriority,
};
