//vendor import
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//templates
import AppTemplate from 'com/templates/ApplicationTemplate';
//ui
import { Button } from 'sazzui/lib/ui/Button';
//widgets
import ItemCard from 'com/widgets/ItemCard';
import DashboardMap from 'com/widgets/DashboardMap';
import StatTableCard from 'com/widgets/StatTableCard';
import { DataTable } from 'sazzui/lib/widgets/DataTable';
import InstructionDeadlineModal from 'com/widgets/InstructionDeadlineModal';
//util
import { RoleBox } from 'com/util/RoleBox';
//constants
import { PAGE_TITLE, EXTERNAL_ROLES, INTERNAL_ROLES, ROLE_TYPES, SERVICE_KEYS } from 'data/constants';
//icons
import ICON_REQUEST_QUOTE from 'assets/images/icons/ico_request_quote.svg';
import ICON_INSTRUCT_QUOTE from 'assets/images/icons/ico_instruct_quote.svg';
import ICON_RECIPROCITY_REPORT from 'assets/images/icons/ico_reciprocity_report.svg';
import ICON_QUOTES from 'assets/images/icons/ico_quotes_blurple.svg';
import ICON_CASES from 'assets/images/icons/ico_cases_blurple.svg';
import ICON_HEADER_CASES from 'assets/images/icons/ico_case_gray.svg';
import ICON_HEADER_QUOTES from 'assets/images/icons/ico_quote_gray.svg';
import ICON_INSTRUCT_WITHOUT_PRE_EXISTING_QUOTE from 'assets/images/icons/ico_instruct_without_pre_existing_quote.svg';
//services
import Cases from 'services/rest/cases';
import Quotes from 'services/rest/quotes';
import Settings from 'services/rest/settings';
import EventLoggerEvents from 'services/rest/event_logger';
//validations
import { validateInstructionDeadline } from 'services/validators/quotes';
//hooks
import useTitle from 'services/hooks/useTitle';
//data
import { CaseRegionStatus, STATUS_COLORS } from 'data/constants';
//styles
import './style.css';

const Dashboard = () => {
	useTitle(PAGE_TITLE.DASHBOARD);

	const initActiveCases = {
		latest_active: [],
		total_active: 0,
	};

	const initLatestQuotes = {
		data: [],
		total_rows: 0,
		latest: [],
		total_latest: 0,
	};

	const user = useSelector((state) => state.auth.user);
	const uName = user.name.split(' ')[0];
	const branding = useSelector((state) => state.branding);
	const [activeCases, setActiveCases] = useState(initActiveCases);
	const [latestQuotes, setLatestQuotes] = useState(initLatestQuotes);
	const [loading, setLoading] = useState(true);
	const [services, setServices] = useState([]);
	const [instructionDeadlineModal, setInstructionDeadlineModal] = useState(false);
	const [serviceForModal, setServiceForModal] = useState(false);

	const activeCasesHeaders = [
		{ title: 'Case No.', field: 'case_number', type: 'number', sort: 'none', sortable: true },
		{ title: 'Client Ref.No.', field: 'reference_number', type: 'string', sort: 'none', sortable: true },
		{ title: 'Region', field: 'region', type: 'string', sort: 'none', sortable: true },
		{ title: 'Status', field: 'status', type: 'string', sort: 'none', sortable: true },
	];
	const latestQuotesHeaders = [
		{ title: 'Case No.', field: 'case_number', type: 'number', sort: 'none', sortable: true },
		{ title: 'Client Ref.No.', field: 'reference_number', type: 'string', sort: 'none', sortable: true },
		{ title: 'App.No.', field: 'application_number', type: 'string', sort: 'none', sortable: true },
		user.roleType === ROLE_TYPES.INTERNAL ?
			{ title: 'Firm name', field: 'firm_name', type: 'string', sort: 'none', sortable: true }
		:	{ title: '', field: '', type: 'string', sort: 'none', sortable: false },
	];

	useEffect(() => {
		fetchData();
		EventLoggerEvents.EventLogger({ event: 'Dashboard Loaded', data: { user: user } });
	}, []);

	const fetchData = async () => {
		let filterCase = {
			orderBy: ['_created:desc'].join(','),
			state: 'CASE',
			limit: 5,
		};

		let filterQuote = {
			order_by: ['_created:desc'].join(','),
			state: 'QUOTE',
			limit: 5,
			hidden: '0',
		};

		try {
			const [cases, quotes, services] = await Promise.all([Cases.GetAllByUser(filterCase), Quotes.GetAllForUser(filterQuote), Settings.GetServices()]);
			setActiveCases(cases);
			setLatestQuotes(quotes);
			setServices(services);
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	const unInstructableQuoteAction = (e) => {
		e.stopPropagation();
		let service = services.find((s) => s.id === e.target.dataset.service);
		if (service?.key === SERVICE_KEYS.DESIGN) {
			setServiceForModal(true);
		} else {
			setServiceForModal(false);
		}
		setInstructionDeadlineModal(true);
	};

	const instructionDeadlineModalClose = () => {
		setInstructionDeadlineModal(false);
	};

	const instructionDeadlineModalCancel = [{ primary: true, label: 'Cancel', action: instructionDeadlineModalClose, theme: 'azami-ghost' }];

	return (
		<AppTemplate>
			<AppTemplate.Content>
				<div className="dashboard-container">
					<div className="dashboard-header">
						<div className="dashboard-header__main">
							<span className="dashboard-header__main-user">
								Hi, <span className="dashboard-header__main-user__name">{uName}</span>!
							</span>
							<span>
								Here's your <span className="dashboard-header__main-omni-patent">{branding.fullName}</span> dashboard
							</span>
						</div>
						<span className="dashboard-header__end">Get Started Quickly</span>
					</div>
					<div className="dashboard-main">
						<ItemCard
							icon={ICON_REQUEST_QUOTE}
							customClassName="dashboard-card"
							title="Create New Quote"
							content="Receive a price quote on a new application by starting here."
							link="/quotes/add"
						/>
						<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')]}>
							<ItemCard
								icon={ICON_INSTRUCT_QUOTE}
								customClassName="dashboard-card"
								title="Instruct From Pre-Existing Quote"
								amount={2}
								content="If you have received or requested a quote from us already, instruct from the Quotes list."
								link="/quotes"
							/>
							<ItemCard
								icon={ICON_INSTRUCT_WITHOUT_PRE_EXISTING_QUOTE}
								customClassName="dashboard-card"
								title="Instruct Without Pre-Existing Quote"
								content="If you are ready to instruct without an existing quote, instruct here."
								link="/quotes/instruct-without-pre-existing"
							/>
							<ItemCard icon={ICON_QUOTES} customClassName="dashboard-card" title="Quotes" content="View current quotes" link="/quotes" />
							<ItemCard icon={ICON_CASES} customClassName="dashboard-card" title="Cases" content="View status on active cases." link="/cases" />
						</RoleBox>
						<RoleBox roles={INTERNAL_ROLES}>
							<ItemCard
								icon={ICON_RECIPROCITY_REPORT}
								customClassName="dashboard-card"
								title="Reciprocity Report"
								content="View a summary of sent and received cases, with corresponding reciprocity points"
								link="/reports/member"
							/>
						</RoleBox>
					</div>
					<RoleBox roles={['saas_user']}>
						<DashboardMap />
					</RoleBox>
					<div className="dashboard-stats-cards">
						<StatTableCard title="Active Cases" icon={ICON_HEADER_CASES} amount={activeCases.total_rows} link="/cases">
							{loading ?
								<DataTable>
									<DataTable.LoaderCell cols={4} rows={5} />
								</DataTable>
							:	<CasesTableData data={activeCases.data} columns={activeCasesHeaders} />}
						</StatTableCard>
						<StatTableCard title="Latest Quotes" icon={ICON_HEADER_QUOTES} amount={latestQuotes.total_rows} link="/quotes">
							{loading ?
								<DataTable>
									<DataTable.LoaderCell cols={4} rows={5} />
								</DataTable>
							:	<QuotesTableData data={latestQuotes.data} columns={latestQuotesHeaders} unInstructableQuoteAction={unInstructableQuoteAction} services={services} user={user} />}
						</StatTableCard>
					</div>
				</div>
				{instructionDeadlineModal ?
					<InstructionDeadlineModal designService={serviceForModal} footerActions={instructionDeadlineModalCancel} closeHandler={instructionDeadlineModalClose} />
				:	null}
			</AppTemplate.Content>
		</AppTemplate>
	);
};

const CasesTableData = (props) => {
	const history = useHistory();
	const goToCaseDetails = (e) => {
		const cid = e.target.closest('tr').dataset.cid;
		history.push(`/case-details/${cid}`);
	};

	return props.data && props.data.length > 0 ?
			<DataTable fixedHeader={true} columns={props.columns}>
				{props.data.map((c, i) => {
					return (
						<tr key={i} className="dashboard-stats-cards__row pointer" onClick={goToCaseDetails} data-cid={c.case_id}>
							<DataTable.Cell>{c.case_number}</DataTable.Cell>
							<DataTable.Cell customClassName="ref_no">
								{c.client_ref_number ?
									c.client_ref_number
								: c.case_client_ref_number ?
									c.case_client_ref_number
								:	'N/A'}
							</DataTable.Cell>
							<DataTable.CountryCell code={c.region_code || ''}>{c.region_name}</DataTable.CountryCell>
							<DataTable.StatusColorCell name={c.status} statusColor={STATUS_COLORS[c.status]} showTooltip={true} customClassName="dashboard_cases_table__indicator" />
						</tr>
					);
				})}
			</DataTable>
		:	<StatTableCard.NoData colspan={3} noData_title="No activity yet" noData_subtitle="We can't wait to work with you!" />;
};

const QuotesTableData = (props) => {
	const history = useHistory();
	const handleClickRow = (e) => {
		const qid = e.target.closest('tr').dataset.qid;
		history.push(`/quotes/${qid}/revisions`);
	};

	const handleActionFinishDraftInstructions = (e) => {
		e.stopPropagation();
		const qid = e.target.closest('tr').dataset.qid;
		history.push(`/quotes/instruct-without-pre-existing/${qid}/draft`);
	};

	const handleActionFinishDraft = (e) => {
		e.stopPropagation();
		const qid = e.target.closest('tr').dataset.qid;
		history.push(`/quotes/add/${qid}/draft`);
	};

	const handleActionInstruct = (e) => {
		e.stopPropagation();
		const qid = e.target.closest('tr').dataset.qid;
		history.push(`/quotes/${qid}/revisions/instruct`);
	};

	const validateInstructionsDeadline = (date, service_id, services, priority_type) => {
		let invalid_deadline = false;
		if (date && props.user.roleType === ROLE_TYPES.EXTERNAL) {
			invalid_deadline = validateInstructionDeadline(new Date(date), service_id, services, priority_type);
		}
		return invalid_deadline;
	};

	return props.data.length > 0 ?
			<DataTable fixedHeader={true} columns={props.columns}>
				{props.data.map((q, i) => {
					let unInstructable = validateInstructionsDeadline(q.due_date, q.service_id, props.services, q.priority_type);
					return (
						<tr key={i} className="dashboard-stats-cards__row pointer" onClick={handleClickRow} data-qid={q.id}>
							<DataTable.Cell>{q.case_number}</DataTable.Cell>
							<DataTable.Cell>{q.client_ref_number}</DataTable.Cell>
							<DataTable.Cell>{q.application_number}</DataTable.Cell>
							<RoleBox roles={EXTERNAL_ROLES}>
								<DataTable.Cell>
									{q.draft && q.state === 'CASE' ?
										<Button customClassName="dashboard-stats-cards__button" theme="ghost-primary" onClick={handleActionFinishDraftInstructions}>
											Finish&nbsp;Instructing&nbsp;Case
										</Button>
									: q.draft && q.state === 'QUOTE' ?
										<Button customClassName="dashboard-stats-cards__button" theme="ghost-primary" onClick={handleActionFinishDraft}>
											Finish&nbsp;Draft&nbsp;Quote
										</Button>
									:	<Button customClassName="dashboard-stats-cards__button" theme="primary" onClick={unInstructable ? props.unInstructableQuoteAction : handleActionInstruct}>
											Instruct
										</Button>
									}
								</DataTable.Cell>
							</RoleBox>
							<RoleBox roles={INTERNAL_ROLES}>
								<DataTable.Cell>{q.firm_name}</DataTable.Cell>
							</RoleBox>
						</tr>
					);
				})}
			</DataTable>
		:	<StatTableCard.NoData colspan={3} noData_title="No activity yet" noData_subtitle="We can't wait to work with you!" />;
};

export default Dashboard;
