// vendor imports
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
// sazzui
import { Tooltip, Input, Select, MultiSelect, LoaderOverlay, DataTable, Modal, AppDate } from 'sazzui/lib/main';
// services
import Settings from 'services/rest/settings';
import Reciprocity from 'services/rest/reciprocity';
import Firms from 'services/rest/firms';
import FirmAccounts from 'services/rest/firm_accounts';
import useQuery from 'services/hooks/useQuery';
import useFilterStorage from 'services/hooks/useFilterStorage';
import useDebounce from 'services/hooks/useDebounce';
// util
import { RoleBox } from 'com/util/RoleBox';
import { AppRoute } from 'com/util/AppRoute';
import AppLink from 'com/util/AppLink';
// data
import Routes from 'data/routes';
import { POINTS_TRANSACTION_TYPE, DEBOUNCE_DELAY_MS, POINTS_TRANSACTIONS_STATUS } from 'data/constants';

const FirmDetailsPoints = (props) => {
	const params = useParams();
	const query = useQuery();
	const accountID = query.get('account_id');
	const transactionType = query.get('type');
	const id = params.id;
	const isMounted = useRef(false);

	const directions = [
		{ label: 'All', value: '' },
		{ label: 'Sent', value: 'CLIENT' },
		{ label: 'Received', value: 'ASSOCIATE' },
	];

	const filtersInit = {
		case_number: '',
		firm_account_id: accountID ? accountID : '',
		service_id: '',
		regions: [],
		direction: '',
		type: transactionType ? transactionType : '',
	};

	const [firmAccounts, setFirmAccounts] = useState([]);
	const [regionData, setRegionData] = useState([]);
	const [services, setServices] = useState([]);
	const [filters, setFilters] = useState(filtersInit);
	const [totalBalance, setTotalBalance] = useState(0);
	const [showDeleteAdjustmentModal, setShowDeleteAadjustmentModal] = useState(false);
	const [deletingAdjustment, setDeletingAdjustment] = useState('');
	const { getFilterValue, setFilterValue } = useFilterStorage();
	const debouncedFilters = useDebounce(filters, DEBOUNCE_DELAY_MS);

	const [showLoader, setShowLoader] = useState(false);
	const [pointsData, setPointsData] = useState({
		points: [],
		casesRegionsPoints: [],
	});

	const tableHeaders = [
		{ title: 'Date', field: 'case_created', type: 'date', sortable: false },
		{ title: 'Case No.', field: 'case_number', type: 'string', sortable: false },
		{ title: 'Recip Account', field: 'recip_account_id', type: 'string', sortable: false },
		{ title: 'Client ref.', field: 'client_ref', type: 'string', sortable: false },
		{ title: 'Service', field: 'service_name', type: 'string', sortable: false },
		{ title: 'Region', field: 'region_name', type: 'string', sortable: false },
		{ title: 'Points', field: 'points', type: 'number', sortable: false },
		{ title: 'Status', field: 'status', type: 'string', sortable: false },
		{ title: 'Adjusted By', field: 'adjusted_by', type: 'string', sortable: false },
		{ title: '', field: '', type: 'string', sortable: false },
		{ title: '', field: 'actions', type: 'string', sortable: false },
	];

	const generateCasesRegionsPoints = (points) => {
		let casesRegionsPoints = [];

		// generate rows with total balance, including all adjustments if any
		points.reduce((res, value) => {
			let key = !!value.case_id && !!value.region_id ? value.case_id + '-' + value.region_id : value.firm_account_id + '-' + value.id;

			if (!res[key]) {
				res[key] = { ...value, amount: 0 };
				casesRegionsPoints.push(res[key]);
			}
			if (!value.parent_id) {
				res[key].case_created = value.case_created;
			}
			res[key].amount += parseInt(value.amount);
			return res;
		}, {});

		// append adjustments rows data in the parent row data
		casesRegionsPoints = casesRegionsPoints.map((crp) => ({
			...crp,
			adjustments: points.filter((p) => !!p.case_region_id && p.case_region_id === crp.case_region_id).sort((a, b) => new Date(b._modified) - new Date(a._modified)),
		}));
		return casesRegionsPoints;
	};

	const fetchPointsData = async (filters = {}) => {
		try {
			setShowLoader(true);
			let points = await Firms.GetPoints(id, filters);
			let casesRegionsPoints = generateCasesRegionsPoints(points);
			setPointsData((prevState) => ({
				...prevState,
				points: points,
				casesRegionsPoints: casesRegionsPoints,
			}));
			setTotalDisplayed(casesRegionsPoints);
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
			console.log(err);
		}
	};

	const updateUIPointsTable = async () => {
		setShowLoader(true);
		try {
			let points = await Firms.GetPoints(id, {});
			let casesRegionsPoints = generateCasesRegionsPoints(points);
			setPointsData({
				...pointsData,
				points: points,
				casesRegionsPoints: casesRegionsPoints,
			});
			setTotalDisplayed(casesRegionsPoints);
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const onChangePointsFilters = async (filters) => {
		await fetchPointsData(filters);
	};

	const setTotalDisplayed = (casesRegionsPoints) => {
		let total_points = casesRegionsPoints.reduce((a, b) => {
			return Number(a) + Number(b.amount);
		}, 0);
		setTotalBalance(total_points);
	};

	useEffect(() => {
		(async () => {
			setShowLoader(true);
			try {
				let f = getFilterValue();
				if (f) setFilters(f);
				const [regions, services, points, firm_accounts] = await Promise.all([
					Settings.GetRegions(),
					Settings.GetServices(),
					Firms.GetPoints(id, f ? f : filters),
					FirmAccounts.GetAllByFirm(id),
				]);

				setRegionData(
					regions.map((r) => {
						return { value: r.code, label: r.name };
					}),
				);
				setServices([
					{ label: 'All', value: '' },
					...services.map((d) => {
						return { label: d.name, value: d.id };
					}),
				]);
				let casesRegionsPoints = generateCasesRegionsPoints(points);
				setPointsData({
					...pointsData,
					points: points,
					casesRegionsPoints: casesRegionsPoints,
				});
				setFirmAccounts([
					{ label: 'All Recip Accounts', value: '' },
					...firm_accounts.map((a) => {
						return {
							label: a.name,
							value: a.id,
						};
					}),
				]);

				setTotalDisplayed(casesRegionsPoints);
				setShowLoader(false);
			} catch (err) {
				console.log(err);
				setShowLoader(false);
			}
		})();
	}, []);

	useEffect(() => {
		if (!isMounted.current) {
			return (isMounted.current = true);
		}
		onChangePointsFilters(filters);
	}, [debouncedFilters]);

	const filterChange = (e) => {
		let { name, value } = e.target;

		setFilters({
			...filters,
			[name]: value,
		});

		setFilterValue({
			...filters,
			[name]: value,
		});
	};

	const closeDeleteAdjustmentModal = () => {
		setDeletingAdjustment('');
		setShowDeleteAadjustmentModal(false);
	};

	const openDeleteAdjustmentModal = (tid) => {
		setDeletingAdjustment(tid);
		setShowDeleteAadjustmentModal(true);
	};

	const handleDeleteAdjustment = async () => {
		await Reciprocity.DeleteTransaction(deletingAdjustment);
		closeDeleteAdjustmentModal();
		onChangePointsFilters(filters);
	};

	const deleteAadjustmentModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteAdjustmentModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: handleDeleteAdjustment, theme: 'azami-blue' },
	];

	return (
		<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager']}>
			<div className="firm-details-points">
				<LoaderOverlay showLoader={showLoader} />
				<div className="firm-details-points__section-filters">
					<Input name="case_number" label="Filter Case No." value={filters.case_number} onChange={filterChange} ariaLabel="Filter case number Input" />
					<Select
						name="firm_account_id"
						label="Filter Recip. Account"
						value={filters.firm_account_id}
						onChange={filterChange}
						options={firmAccounts}
						ariaLabel="Filter firm account Select"
					/>
					<Select name="service_id" label="Filter Service" value={filters.service_id} onChange={filterChange} options={services} ariaLabel="Filter service Select" />
					<MultiSelect name="regions" label="Filter Regions" options={regionData} onChange={filterChange} values={filters.regions} selectAllEnabled={true} />
					<Select name="direction" label="Filter Direction" value={filters.direction} onChange={filterChange} options={directions} ariaLabel="Filter direction Select" />
				</div>
				<div className="firm-details-points__section-header">
					<span className="firm-details-points__section-header__title">Points balance</span>
					<span className="firm-details-points__section-header__title">Total Displayed: {totalBalance}</span>
					<RoleBox roles={['admin']}>
						<AppLink type="regular" to={`/firm-management/${params.id}/points/adjustment`} className="form-button primary medium special">
							Adjust Points
						</AppLink>
					</RoleBox>
				</div>
				<div className="firm-details-points__section-body">
					<DataTable fixedHeader={true} columns={tableHeaders}>
						{pointsData.casesRegionsPoints.length > 0 &&
							pointsData.casesRegionsPoints.map((p, i) => (
								<ExpandableRow
									key={i}
									data={p}
									colSpan={tableHeaders.length}
									openDeleteAdjustmentModal={openDeleteAdjustmentModal}
									closeDeleteAdjustmentModal={closeDeleteAdjustmentModal}
								/>
							))}
					</DataTable>
					<div className="firm-details-points__section-footer">
						<a href="/reports/client" className="client-report">
							View Points History
						</a>
					</div>
					<AppRoute route={Routes.FIRM_DETAILS_POINTS_ADJUSTMENT} onChange={updateUIPointsTable} exact />
					{showDeleteAdjustmentModal && (
						<Modal title="Delete Adjustment Points" closeHandler={closeDeleteAdjustmentModal} footerActions={deleteAadjustmentModalFooterActions}>
							Are you sure you want to delete the adjustment points?
						</Modal>
					)}
				</div>
			</div>
		</RoleBox>
	);
};

const ExpandableRow = (props) => {
	const params = useParams();
	const [adjustmentsShow, setAdjustmentsShow] = useState(false);

	const tableHeaders = [
		{ title: 'Date', field: 'case_created', type: 'date', sortable: false },
		{ title: 'Case No.', field: 'case_number', type: 'string', sortable: false },
		{ title: 'Recip Account', field: 'recip_account_id', type: 'string', sortable: false },
		{ title: 'Client ref.', field: 'client_ref', type: 'string', sortable: false },
		{ title: 'Service', field: 'service_name', type: 'string', sortable: false },
		{ title: 'Region', field: 'region_name', type: 'string', sortable: false },
		{ title: 'Points', field: 'points', type: 'number', sortable: false },
		{ title: 'Adjusted By', field: 'points_adjusted_by', type: 'string', sortable: false },
		{ title: '', field: 'comment', type: 'string', sortable: false },
		{ title: '', field: 'actions', type: 'string', sortable: false },
	];

	const toggleAdjustments = () => {
		setAdjustmentsShow(!adjustmentsShow);
	};

	const toggleShow = () => {
		return adjustmentsShow && hasAdjustments();
	};

	const hasAdjustments = () => {
		return props.data.adjustments.length > 1;
	};

	const openDeleteAdjustmentModal = (e) => {
		props.openDeleteAdjustmentModal(e.target.dataset.tid);
	};

	return (
		<>
			<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager']}>
				<tr onClick={toggleAdjustments} className="pointer" style={{ backgroundColor: toggleShow() ? 'var(--clr-lightblue)' : '' }}>
					<DataTable.DateCell date={props.data.case_created} />
					<DataTable.Cell>{props.data.case_number}</DataTable.Cell>
					<DataTable.Cell>{props.data.firm_account_name}</DataTable.Cell>
					<DataTable.Cell>{props.data.client_name}</DataTable.Cell>
					<DataTable.Cell>{props.data.service_name}</DataTable.Cell>
					<DataTable.CountryCell code={props.data.region_code || ''}>{props.data.region_name}</DataTable.CountryCell>
					<DataTable.PointsCell adjusted={hasAdjustments()}>{props.data.amount}</DataTable.PointsCell>
					<DataTable.Cell customClassName={props.data.status === POINTS_TRANSACTIONS_STATUS.PENDING ? 'points-status-cell-pending' : 'points-status-cell-approved'}>
						{props.data.status?.toLowerCase()}
					</DataTable.Cell>
					<DataTable.Cell>
						<div className="adjusted-cell">
							{props.data.object_type === POINTS_TRANSACTION_TYPE.ACCOUNT ?
								<>
									{props.data._modified && (
										<span className="adjusted-date">
											<AppDate>{props.data._modified}</AppDate>
										</span>
									)}
									{props.data.points_adjusted_by}
								</>
							:	null}
						</div>
					</DataTable.Cell>
					<DataTable.Cell>
						{props.data.object_type === POINTS_TRANSACTION_TYPE.ACCOUNT ?
							<Tooltip content={props.data.reason || props.data.custom_reason} direction="bottom" />
						:	null}
					</DataTable.Cell>
					<RoleBox roles={['admin']}>
						<DataTable.Cell>
							{props.data.object_type === POINTS_TRANSACTION_TYPE.CASE ?
								<AppLink to={`/firm-management/${params.id}/points/adjustment?case_number=${props.data.case_number}`} title="Edit" className="action adjust" />
							:	<button className="action delete" data-tid={props.data.id} onClick={openDeleteAdjustmentModal} title="Delete" />}
						</DataTable.Cell>
					</RoleBox>
				</tr>
				{hasAdjustments() ?
					<tr style={{ display: toggleShow() ? 'table-row' : 'none', backgroundColor: '#FFCE00' }}>
						<td colSpan={props.colSpan} className="drawer">
							<div>
								<table cellSpacing="0" cellPadding="0" className="nested-table">
									<thead>
										<tr>
											{tableHeaders.map((c, i) => (
												<th key={i}>{c.title}</th>
											))}
										</tr>
									</thead>
									<tbody>
										{props.data.adjustments.map((d, i) => (
											<tr key={i} className={d.parent_id ? 'adjustment-row' : ''}>
												<DataTable.DateCell date={d.case_created} />
												<DataTable.Cell>{d.case_number}</DataTable.Cell>
												<DataTable.Cell>{d.firm_account_name}</DataTable.Cell>
												<DataTable.Cell>{d.client_name}</DataTable.Cell>
												<DataTable.Cell>{d.service_name}</DataTable.Cell>
												<DataTable.CountryCell code={props.data.region_code || ''}>{props.data.region_name}</DataTable.CountryCell>
												<DataTable.PointsCell isAdjustmentRow={d.parent_id}>{d.amount}</DataTable.PointsCell>
												<DataTable.Cell>
													{d.parent_id && (
														<div className="adjusted-cell">
															{d._modified && (
																<span className="adjusted-date">
																	<AppDate>{d._modified}</AppDate>
																</span>
															)}
															{d.points_adjusted_by}
														</div>
													)}
												</DataTable.Cell>
												<DataTable.Cell>{d.parent_id && <Tooltip content={d.reason || d.custom_reason} direction="bottom"></Tooltip>}</DataTable.Cell>
												<RoleBox roles={['admin']}>
													<DataTable.Cell>
														{d.parent_id && <button className="action delete" data-tid={d.id} onClick={openDeleteAdjustmentModal} title="Delete" />}
													</DataTable.Cell>
												</RoleBox>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</td>
					</tr>
				:	null}
			</RoleBox>
		</>
	);
};

export default FirmDetailsPoints;
