const pctNpe = [
	'earliest_priority_date',
	'number_of_priorities',
	'intl_filing_date',
	'publication_number',
	'number_of_words',
	'number_of_claims',
	'chapter_two_demand',
	'number_of_pages',
	'number_of_independent_claims',
	'number_of_page_drawings',
	'number_of_page_sequences',
	'entity_size',
	'location_of_search',
	'classification_ipc',
	'application_language',
	'china_extension_fee',
];

const parisConvention = [
	'earliest_priority_date',
	'number_of_priorities',
	'number_of_words',
	'number_of_claims',
	'number_of_pages',
	'number_of_independent_claims',
	'number_of_page_drawings',
	'number_of_page_sequences',
	'entity_size',
	'location_of_search',
	'classification_ipc',
	'application_language',
];

const design = [
	'earliest_priority_date',
	'number_of_words',
	'number_of_priorities',
	'number_of_embodiments',
	'number_of_claims',
	'number_of_page_drawings',
	'claiming_priority',
	'entity_size',
	'application_language',
];

const tradeMark = ['earliest_priority_date', 'number_of_words', 'number_of_priorities', 'number_of_classes', 'claiming_priority', 'application_language'];

const epValidation = [
	'earliest_priority_date',
	'number_of_words',
	'publication_number',
	'number_of_words_in_claims',
	'number_of_claims',
	'number_of_pages',
	'number_of_page_drawings',
	'number_of_page_sequences',
	'application_language',
];

const translation = ['application_language', 'number_of_words'];

const utilityModel = [
	'earliest_priority_date',
	'number_of_words',
	'number_of_priorities',
	'number_of_pages',
	'number_of_claims',
	'number_of_page_drawings',
	'number_of_page_sequences',
	'number_of_independent_claims',
	'entity_size',
	'location_of_search',
	'classification_ipc',
	'application_language',
	'priority_type',
];

const recordal = [];

const other = [
	'earliest_priority_date',
	'number_of_words',
	'number_of_priorities',
	'number_of_claims',
	'number_of_pages',
	'number_of_independent_claims',
	'number_of_page_drawings',
	'number_of_page_sequences',
	'entity_size',
	'location_of_search',
	'application_language',
];

const unitary = ['earliest_priority_date', 'number_of_words', 'publication_number', 'number_of_words_in_claims', 'application_language'];

const selectFieldsList = (service_id, services) => {
	let serviceName = '';
	if (service_id && services) {
		let service = services.find((s) => s.id === service_id);
		serviceName = service?.name;
	}
	switch (serviceName) {
		case 'Translations':
			return translation;
		case 'Other':
			return other;
		case 'Utility Model':
			return utilityModel;
		case 'PCT National Phase':
			return pctNpe;
		case 'Design Applications':
			return design;
		case 'European Validation':
			return epValidation;
		case 'Trademarks':
			return tradeMark;
		case 'Paris Convention':
			return parisConvention;
		case 'Recordal':
			return recordal;
		case 'Unitary Patent':
			return unitary;
		default:
			return parisConvention;
	}
};

export const checkFieldForService = (data, services, field_name) => {
	if (!data.service_id) {
		return false;
	} else {
		let fieldList = selectFieldsList(data.service_id, services);
		return fieldList.some((f) => f === field_name);
	}
};

export const checkFieldForRegion = (data, regions, field_name) => {
	if (field_name === 'china_extension_fee') {
		let chinaRegion = regions.find((r) => r.name === 'China');
		return data.regions.includes(chinaRegion?.id);
	}
	return true;
};

export const publicationNumberLabel = (service_id, services, serviceKeys) => {
	let service = services.find((s) => s.id == service_id);
	if (service) {
		if (service.key === serviceKeys.UNITARY || service.key === serviceKeys.EP) {
			return 'Application Number';
		}
	}
	return 'Publication Number';
};

export const applicationNumberLabel = (service_id, services, serviceKeys) => {
	let service = services.find((s) => s.id == service_id);
	if (service) {
		if (service.key === serviceKeys.UNITARY || service.key === serviceKeys.EP) {
			return 'Publication Number';
		}
	}
	return 'Application Number';
};
