// vendor imports
import { combineReducers } from 'redux';
// reducers
import auth from './auth';
import branding from './branding';
// import notificationToastsReducer from './ducks/notification_toasts';
import notification_toasts from './notification_toasts';
import notifications from './notifications';
import redirect from './redirect';
import banners from './banner';
import quote from './quote';
import wipo from './wipo';
import features_visible from './feature-visible';

export default combineReducers({
	auth,
	branding,
	notifications,
	notification_toasts,
	redirect,
	banners,
	quote,
	wipo,
	features_visible,
});
