import React from 'react';
// templates
import AuthTemplate from 'com/templates/AuthTemplate';
import AuthBox from 'com/templates/AuthBox';
// services
import useTitle from 'services/hooks/useTitle';
// util
import AppLink from 'com/util/AppLink';
// sazzui
import { BackButton } from 'sazzui/lib/ui/BackButton';
// data
import { PAGE_TITLE } from 'data/constants';
// styles
import './style.css';

const RegisterThankyou = () => {
	useTitle(PAGE_TITLE.REGISTER);

	return (
		<AuthTemplate>
			<div className="thankyou-container">
				<AuthBox title="Thank you for your registration" titleColor="azami-blurple" icon="create_account" footer={<BackButton to="/" AppLink={AppLink} label="Back to login" />}>
					An email with a verification link is on it's way. Please verify your account in order to proceed with using The Azami Global portal.
				</AuthBox>
			</div>
		</AuthTemplate>
	);
};

export default RegisterThankyou;
