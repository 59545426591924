import React, { useEffect, useState } from 'react';

import AppTemplate from 'com/templates/ApplicationTemplate';

import { GetTop10Report, DownloadTopTenReport } from 'services/rest/reports';
import Settings from 'services/rest/settings';
import { FIRM_TAGS, PAGE_TITLE } from 'data/constants';

import { Select, Button, LoaderOverlay, Switch, IconButton, TagSelect, DataTable } from 'sazzui/lib/main';

import AppLink from 'com/util/AppLink';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_PDF from 'assets/images/icons/ico_pdf.svg';

const Top10Report = () => {
	useTitle(PAGE_TITLE.TOP_10_REPORT);

	let initData = [];
	let initRegionsData = [{ label: '', value: '' }];
	const filterInit = {
		region: '',
		is_active: 1,
		firms_without_tags: false,
		tags: [],
	};

	const [data, setData] = useState(initData);
	const [regionsData, setRegionsData] = useState(initRegionsData);
	const [filter, setFilter] = useState(filterInit);
	const [region, setRegion] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		(async () => {
			try {
				const data = await Settings.GetRegions();
				setRegionsData([
					...regionsData,
					...data.map((r) => {
						return { label: r.name, value: r.id };
					}),
				]);
				setRegion(data[0].id);

				let f = getFilterValue();
				if (f) {
					setFilter(f);
					fetchData(f);
				}
			} catch (error) {
				console.log('error', error);
			}
		})();
	}, []);

	const firmStatus = [
		{ label: 'Active', value: '1' },
		{ label: 'Inactive', value: '0' },
	];

	const filterChange = (e) => {
		let { name, value } = e.target;
		switch (name) {
			case 'firms_without_tags':
				setFilter({
					...filter,
					[name]: !filter[name],
					tags: [],
				});
				break;
			default:
				setFilter({
					...filter,
					[name]: value,
				});
				break;
		}
	};

	const fetchData = (filter) => {
		try {
			setShowLoader(true);
			GetTop10Report(filter.region, filter)
				.then((data) => {
					setData(data);
					setShowLoader(false);
				})
				.catch((err) => {
					console.log(err);
					setShowLoader(false);
				});
		} catch (err) {
			setShowLoader(false);
		}
	};

	const filterSubmit = () => {
		fetchData(filter);
		setFilterValue(filter);
	};

	const clearFilter = () => {
		setFilter(filterInit);
		setData(initData);
		setFilterValue(null);
	};

	const reportTableHeadersInit = [
		{ title: 'Firm', field: 'client_firm_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Points', field: 'points', type: 'number', sort: 'none', sortable: true },
	];

	const [reportTableHeaders, setReportTableHeaders] = useState(reportTableHeadersInit);

	const columnSort = (f, t) => {
		let dir = 'up';

		setReportTableHeaders(
			reportTableHeaders.map((e) => {
				if (e.field === f) {
					e.sort = e.sort === 'up' ? 'down' : 'up';
					dir = e.sort === 'up' ? 'down' : 'up';
				}
				return e;
			}),
		);

		let sorted = data.sort((a, b) => {
			let x = null;
			let y = null;

			switch (t) {
				case 'date':
					x = new Date(a[f]).getTime();
					y = new Date(b[f]).getTime();
					break;
				case 'number':
					x = Number(a[f]);
					y = Number(b[f]);
					break;
				case 'string':
					x = a[f].toLowerCase();
					y = b[f].toLowerCase();
					break;
				case 'casenum':
					x = Number(a[f].replace('PN', ''));
					y = Number(b[f].replace('PN', ''));
					break;
				default:
			}

			if (dir === 'up') {
				if (x > y) {
					return -1;
				}
				if (x < y) {
					return 1;
				}
			} else if (dir === 'down') {
				if (y > x) {
					return -1;
				}
				if (y < x) {
					return 1;
				}
			}
			return 0;
		});

		setData(sorted);
	};

	const printReport = async () => {
		try {
			await DownloadTopTenReport(data);
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<AppTemplate title="Top 10 Report" headerIcon="reports">
			<AppTemplate.Sidebar>
				<div className="reports-sidebar">
					<h4 className="reports-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						filters
					</h4>
					<Select options={regionsData} label="Region" theme="dark" onChange={filterChange} name="region" value={filter.region} />
					<Select options={firmStatus} label="Status" theme="dark" onChange={filterChange} name="is_active" value={filter.is_active} />
					<div className="reports-sidebar__firms-without-tags-switch">
						<Switch onChange={filterChange} value={filter.firms_without_tags} name="firms_without_tags" label="Firms Without Tags" />
					</div>
					<TagSelect disabled={filter.firms_without_tags} label="Tags" options={FIRM_TAGS} theme="dark" values={filter.tags} onChange={filterChange} />
					<div className="reports-sidebar_actions sidebar_actions">
						<Button onClick={filterSubmit} size="small">
							Generate Report
						</Button>
						<Button theme="ghost-primary" onClick={clearFilter} size="small">
							Clear
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div className="main-screen-section subheader">
					<h2 className="main-screen-section__title">Report Summary</h2>
					<IconButton icon={ICON_PDF} onClick={printReport} />
				</div>
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					<DataTable fixedHeader={true} columns={reportTableHeaders} onColumnSort={columnSort}>
						{data.map((d, i) => (
							<tr key={i}>
								<DataTable.PersonCell subtext={d.client_firm_account_name} showIco={false}>
									<AppLink type="regular" to={`/firm-management/${d.client_firm_id}`}>
										{d.client_firm_name}
									</AppLink>
								</DataTable.PersonCell>
								<DataTable.Cell>{d.points}</DataTable.Cell>
							</tr>
						))}
					</DataTable>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default Top10Report;
