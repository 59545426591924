import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BackdropContainer } from '../../util/Modal';
import { Button } from '../../main';
import './style.css';
export const Modal = ({ theme = 'primary', ...props }) => {
    return (_jsx(BackdropContainer, { children: _jsxs("div", { className: "confirmation-modal", children: [_jsxs("div", { className: `header ${theme}`, children: [props.icon && _jsx("img", { src: props.icon, alt: "" }), _jsx("h4", { children: props.title }), props.closeHandler && _jsx("button", { onClick: props.closeHandler, children: "\u00D7" })] }), _jsx("div", { className: "body", children: props.children }), _jsxs("div", { className: "footer", children: [_jsx("div", { children: props.footerActionsLeft }), _jsx("div", { children: props.footerActions && (_jsx("div", { children: props.footerActions.map((b, i) => {
                                    let disabled = b.disabled ? b.disabled : false;
                                    let thm = b.primary ? theme : `ghost-${theme}`;
                                    return (_jsx(Button, { size: "small", className: "", onClick: b.action, disabled: disabled, theme: thm, showSpinner: b.showSpinner, children: b.label }, i));
                                }) })) })] })] }) }));
};
